const vi = {
  ngon_ngu_tieng_viet: "Tiếng Việt",
  ngon_ngu_tieng_anh: "Tiếng Anh",

  // validate
  validate_required: "${label} không được để trống",
  validate_type_email: "Sai định dạng ${label}",
  validate_type_number: "${label} sai định dạng số",
  validate_number_range: "${label} không thuộc khoảng từ ${min} đến ${max}",
  validate_type_pattern: "${label} không đúng định dạng",
  validate_number_max: "${label} tối đa ${max} ký tự",

  common_ngay: "Ngày",
  common_thang: "Tháng",
  common_nam: "Năm",
  common_tuan: "Tuần",
  common_xem: "Xem",
  common_them_moi: "Thêm mới",
  common_sua: "Sửa",

  menu_Bang_dieu_khien: "Bảng điều khiển",
  menu_Quan_ly_tiep_don: "Quản lý tiếp đón",
  menu_Quan_ly_he_thong: "Quản lý hệ thống",
  menu_Quan_ly_danh_muc: "Quản trị danh mục",
  menu_Quan_ly_nguoi_dung: "Quản lý người dùng",
  menu_Thong_tin_cskcb: "Thông tin CSKCB",
  menu_Quan_ly_vien_phi: "Quản lý viện phí",
  menu_Quan_ly_so_hoa_don: "Quản lý sổ tổng hợp",
  menu_Quan_ly_kham_benh: "Quản lý khám bệnh",
  menu_Quan_ly_chan_doan_hinh_anh_ris: "Quản lý CĐHA (RIS/PACS)",
  menu_Quan_ly_bhyt: "Quản lý BHYT",
  menu_Quan_ly_duoc_ngoai_tru: "Quản lý dược, vật tư",
  menu_Kho_thuoc: "Kho thuốc",
  menu_Kho_duoc_ngoai_tru: "Kho",
  menu_Quan_ly_xep_hang_qms: "Quản lý xếp hàng QMS",
  menu_Quan_ly_dich_vu_can_thanh_toan: "Dịch vụ cần thanh toán",
  menu_Quan_ly_xet_nghiem_lis: "LIS",
  menu_Lich_lam_viec: "Lịch làm việc",
  menu_Ban_thuoc: "Bán thuốc",
  menu_Phieu_xuat_nhap: "Phiếu xuất nhập",
  app_loi_ket_noi: "Lỗi kết nối!!!",
  menu_Quan_ly_benh_nhan: "Quản lý bệnh nhân",
  menu_Bao_cao: "Báo cáo",
  menu_Ho_tro_khach_hang: "Hỗ trợ khách hàng",
  nhom_nguoi_dung: "Nhóm người dùng",
  menu_quan_ly_phong_kham_ve_tinh: "Quản lý PK vệ tinh",
  // Danh sách danh mục (quản lý danh mục)
  cate_DM: "DM",
  cate_Danh_muc: "Danh mục",
  cate_benh_vien: "bệnh viện",
  cate_khoa: "khoa",
  cate_phong: "phòng",
  cate_dich_vu_ki_thuat: "dịch vụ ",
  cate_nhom_dv_ki_thuat: "nhóm dịch vụ ",
  cate_loai_dv_ki_thuat: "loại dịch vụ ",
  cate_nhom_hoa_don: "nhóm hóa đơn",
  cate_thuoc: "thuốc",
  cate_nha_thuoc: "nhà thuốc",
  cate_hang_san_xuat: "hãng sản xuất",
  cate_duong_dung: "đường dùng",
  cate_hoat_chat: "hoạt chất",
  cate_kho_tu_truc: "kho, tủ trực",
  cate_tinh: "tỉnh",
  cate_huyen: "huyện",
  cate_xa: "xã",
  cate_nghe_nghiep: "nghề nghiệp",
  cate_dan_toc: "dân tộc",
  cate_quoc_gia: "quốc gia",
  cate_icd10: "ICD-10",
  cate_nhom_icd10: "nhóm ICD-10",
  cate_don_vi_tinh: "đơn vị tính",
  cate_so_hoa_don: "Danh sách sổ tổng hợp",
  cate_giuong: "giường bệnh",
  cate_dv_giuong: "dịch vụ giường",
  cate_cachthuc_thuthuat: "Cách thức thủ thuật",
  cate_phuongphap_thuthuat: "Phương pháp thủ thuật",
  cate_pp_vo_cam: "phương pháp vô cảm",
  cate_xuat_khac: "xuất khác",
  cate_nguon: "nguồn",
  cate_kho_thuoc: "kho thuốc",
  cate_doi_tuong: "đối tượng",
  cate_nguon_khach: "nguồn khách",
  cate_cach_dung: "cách dùng",
  cate_ql_pk_ve_tinh: "quản lý phòng khám vệ tinh",
  title_ql_pk_ve_tinh: "Quản lý phòng khám vệ tinh",

  // Danh sách trường thông tin (bao gồm: tên cột của table, label của form)
  field_ma_doi_tuong: "Mã đối tượng",
  field_ma_nhom_doi_tuong: "Mã nhóm đối tượng",
  field_Ma_cskcb: "Mã CSKCB",
  field_Ten_benh_vien: "Tên bệnh viện",
  field_Tuyen: "Tuyến",
  field_Hang: "Hạng",
  field_Dia_chi: "Địa chỉ",
  field_Ma_khoa: "Mã khoa",
  field_Ten_khoa: "Tên khoa",
  field_Ma_khoa_byt: "Mã khoa BYT",
  field_Truong_khoa: "Trưởng khoa",
  field_Loai_khoa: "Loại khoa",
  field_Ma_phong: "Mã phòng",
  field_Ten_phong: "Tên phòng",
  field_Khoa: "Khoa",
  field_Loai_phong: "Loại phòng",
  field_Phong_chi_dinh: "Phòng chỉ định",
  field_Stt: "STT",
  field_Ma_dich_vu: "Mã DV",
  field_Loai_chi_so: 'Loại chỉ số',
  field_Ma_dich_vu_full: "Mã dịch vụ",
  field_Ten_dich_vu: "Tên dịch vụ",
  field_Ten_dich_vu_theo_byt: "Tên dịch vụ theo BYT",
  field_Don_vi_tinh: "Đơn vị tính",
  field_Thong_tin_co_so: "Thông tin cơ sở",
  field_Thong_tin_co_ban: "Thông tin cơ bản",
  field_Ten_cskcb: "Tên phòng khám",
  field_Tinh_thanh: "Tỉnh/Thành phố",
  field_Quan_huyen: "Quận/Huyện",
  field_Xa_phuong: "Phường/Xã",
  field_Dia_chi_chi_tiet: "Địa chỉ chi tiết",
  field_Ghi_chu: "Ghi chú",
  field_Hien_thi: "Khóa",
  field_Thao_tac: "Thao tác",
  field_Nhom_dich_vu: "Nhóm dịch vụ",
  field_Loai_dich_vu: "Loại dịch vụ",
  field_Nhom_bhyt: "Nhóm chi phí BHYT",
  field_nhom_hoa_don: "Nhóm hóa đơn",
  field_Gia: "Giá",
  field_Gia_thu_phi: "Giá thu phí",
  field_Gia_bhyt: "Giá BHYT",
  field_Gia_dich_vu: "Giá yêu cầu",
  field_Gia_nuoc_ngoai: "Giá nước ngoài",
  field_Thong_tin_khac: "Thông tin khác",
  field_Ti_le_dung_tuyen: "Tỉ lệ đúng tuyến",
  field_Ti_le_trai_tuyen: "Tỷ lệ trái tuyến",
  field_So_ngay_tai_chi_dinh: "Số ngày tái chỉ định",
  field_Phong_thuc_hien: "Phòng thực hiện",
  field_Phong_lay_mau: "Phòng lấy mẫu",
  field_chon_loai_thu_thuat: "Chọn loại thủ thuật",
  field_Chi_so_xet_nghiem: "Chỉ số xét nghiệm",
  field_Don_vi_chi_so: "Đơn vị chỉ số",
  field_Giai_ket_qua_chi_so: "Giải kết quả chỉ số",
  field_Cho_phep_mien_giam: "Cho phép miễn giảm",
  field_Cho_sua_gia_khi_chi_dinh: "Cho sửa giá khi chỉ định",
  field_Ma_nhom: "Mã nhóm",
  field_Ten_nhom: "Tên nhóm",
  field_Id_loai_dv_ki_thuat: "Mã loại dịch vụ",
  field_Nhom_dv_ki_thuat: "Nhóm dịch vụ",
  field_Ten_loai_dv_ki_thuat: "Tên loại dịch vụ",
  field_Id_nhom: "ID nhóm",
  field_Ma_hang_san_xuat: "Mã hãng sản xuất",
  field_Ten_hang_san_xuat: "Tên hãng sản xuất",
  field_Ma_duong_dung: "Mã đường dùng",
  field_Ma_duong_dung_theo_byt: "Mã đường dùng theo BYT",
  field_ten_duong_dung: "Tên đường dùng",
  field_Ma_kho: "Mã kho",
  field_Ten_kho: "Tên kho",
  field_Thu_kho: "Thủ kho",
  field_Loai_kho: "Loại kho",
  field_Kieu_kho: " Kiểu kho",
  field_Ma_tinh: "Mã tỉnh",
  field_Ma_tinh_byt: "Mã tỉnh theo BYT",
  field_Ten_tinh: "Tên tỉnh",
  field_Ten_Tinh_thanh: "Tỉnh/Thành phố",
  field_Id_huyen: "Mã huyện",
  field_Ma_huyen_byt: "Mã huyện theo BYT",
  field_Ten_huyen: "Tên huyện",
  field_Ten_Quan_huyen: "Quận/Huyện",
  field_Ma_xa: "Mã xã",
  field_Ma_xa_byt: "Mã xã theo BYT",
  field_Ten_xa: "Tên xã",
  field_Ten_Xa_phuong: "Xã/Phường",
  field_Ma_nghe_nghiep: "Mã nghề nghiệp",
  field_Ten_nghe_nghiep: "Tên nghề nghiệp",
  field_Ma_dan_toc: "Mã dân tộc",
  field_Ten_dan_toc: "Tên dân tộc",
  field_Ma_icd: "Mã ICD",
  field_Ten_icd: "Tên ICD",
  field_Nhom_icd: "Nhóm ICD",
  field_Loai_icd: "Loại ICD",
  field_Ma_nhom_icd: "Mã nhóm ICD",
  field_Ten_nhom_icd: "Tên nhóm ICD",
  field_Ma_quoc_gia: "Mã quốc gia",
  field_Ten_quoc_gia: "Tên quốc gia",
  field_Ten_goi_khac: "Tên gọi khác",
  field_Ten_viet_tat: "Tên viết tắt",
  field_Benh_nhan: "Bệnh nhân",
  field_Dich_vu_su_dung: "Dịch vụ sử dụng",
  field_Thanh_toan: "Thanh toán",
  field_Trang_thai: "Trạng thái",
  field_Thong_tin_hanh_chinh: "Thông tin hành chính",
  field_Tim_kiem_benh_nhan: "Tìm kiếm BN",
  field_Tim_kiem_thuoc: "Tìm kiếm thuốc",
  field_Doi_tuong_benh_nhan: "Đối tượng",
  field_Ten_doi_tuong_nhom_nguoi_dung: "Tên nhóm ĐT người dùng",
  field_Uu_tien: "Ưu tiên",
  field_Ma_benh_nhan: "Mã BN",
  field_Ten_benh_nhan: "Tên BN",
  field_So_dien_thoai: "Số điện thoại",
  field_Email: "Email",
  field_Gioi_tinh: "Giới tính",
  field_Ngay_sinh: "Ngày sinh",
  field_THX: "T-H-X",
  field_Nghe_nghiep: "Nghề nghiệp",
  field_Dan_toc: "Dân tộc",
  field_Quoc_gia: "Quốc gia",
  field_Thong_tin_bhyt: "Thông tin BHYT",
  field_TE_co_the_bhyt: "TE có thẻ BHYT",
  field_The_bhyt: "Thẻ BHYT",
  field_Han_the_tu: "Hạn thẻ từ",
  field_Han_the_den: "Hạn thẻ đến",
  field_Ngay_mien_cung_chi_tra: "Ngày miễn cùng chi trả",
  field_Ngay_dong_han_du_5_nam: "Ngày đóng hạn đủ 5 năm",
  field_Noi_song: "Nơi sống",
  field_Thong_tin_kham: "Thông tin khám",
  field_Yeu_cau_kham: "Yêu cầu khám",
  field_Chon_phong_kham: "Chọn phòng khám",
  field_Ma_cccd: "Mã CCCD",
  field_So_phong: "Số phòng",
  field_Benh_nhan_cho_kham: "BN chờ",
  field_In_phieu_kham: "In phiếu khám",
  field_ton_kho: "Tồn kho",
  field_gia_ban: "Giá bán",
  field_gia_nhap: "Giá nhập",
  field_tinh_nang: "Tính năng",
  field_ma_ly_do: "Mã lý do",
  field_ly_do: "Lý do",
  field_ma_nguon: "Mã nguồn",
  field_ma_nguon_khach: "Mã nguồn khách",
  field_ten_nguon_khach: "Tên nguồn khách",
  field_nhom_dt_nguoi_dung: "Nhóm ĐT người dùng",
  field_nhom_dt_nguoi_dung_full: "Nhóm đối tượng người dùng",
  field_ten_nguon: "Tên nguồn",
  field_so_dang_ky_thuoc: "Số đăng ký thuốc",
  field_gio_bat_dau: "Giờ bắt đầu",
  field_gio_ket_thuc: "Giờ kết thúc",
  field_thoi_gian_hoat_dong: "Thời gian làm việc của phòng",
  field_thoi_gian: "Thời gian",
  field_chua_cai_dat_thoi_gian: "Chưa cài đặt thời gian làm việc",
  field_gia_vien_phi: "Giá viện phí",
  field_Da_TT: "Đã TT",
  field_Da_TH: "Đã TH",
  field_Ma_CK: "Mã CK",
  field_Ma_BN: "Mã BN",
  field_SL: "SL",
  field_BS: "BS khám",
  field_loai_doi_tuong: "Loại đối tượng",
  field_nguon: "Nguồn",
  /// thuốc
  field_ma_thuoc: "Mã thuốc",
  field_ten_thuoc: "Tên thuốc",
  field_lo_thuoc: "Lô thuốc",
  field_Ten_thuoc_bhyt: "Tên thuốc BHYT",
  field_ma_thuoc_bhyt: "Mã thuốc BHYT",
  field_stt_bhyt: "STT BHYT",
  field_ten_hoat_chat: "Tên hoạt chất",
  field_don_vi_tinh: "Đơn vị tính",
  field_nong_do: "Nồng độ",
  field_the_tich: "Thể tích",
  field_ham_luong: "Hàm lượng",
  field_ma_hoat_chat: "Mã hoạt chất",
  field_nuoc_san_xuat: "Nước sản xuất",
  field_hang_san_xuat: "Hãng sản xuất",
  field_quy_cach_dong_goi: "Quy cách đóng gói",
  field_ATC_code: "ATC code",
  field_thuoc_khang_sinh: "Thuốc kháng sinh",
  field_che_pham_y_hoc_co_truyen: "Chế phẩm Y học cổ truyền",
  field_cho_phep_mien_giam: "Cho phép miễn giảm",
  field_thuoc_tan_duoc: "Thuốc tân dược",
  field_thuoc_ke_don: "Thuốc kê đơn",
  field_vi_thuoc_y_hoc: "Vị thuốc y học cổ truyền",
  field_thuc_pham_chuc_nang: "Thực phẩm chức năng",
  field_tam_dung_nhap_NCC: "Tạm dừng nhập NCC",
  field_ma_hoat_chat_bhyt: "Mã hoạt chất BYT",
  field_doi_tuong_BHYT: "BHYT",
  field_doi_tuong_yeu_cau: "Yêu cầu",
  field_doi_tuong_vien_phi: "Viện phí",
  field_doi_tuong_nuoc_ngoai: "Nước ngoài",
  field_doi_tuong_mien_giam: "Miễn giảm",
  field_ngay_het_han: "Ngày hết hạn",
  field_loai_thuoc: "Loại thuốc",
  field_nhom_thuoc: "Nhóm thuốc",
  field_tat_ca: "Tất cả",
  field_tat_ca_nhom: "Tất cả nhóm",
  field_da_het_han: "Đã hết hạn",
  field_chua_het_han: "Chưa hết hạn",
  field_ma_vach: "Mã vạch",
  field_nhom_chi_phi: "Nhóm chi phí BHYT",
  field_chung_tu: "Chứng từ",
  field_phuong_thuc: "Phương thức",
  field_don_gia: "Đơn giá",
  field_ton_cuoi: "Tồn cuối",
  field_doi_tac: "Đối tác",
  field_so_lo: "Số lô",
  field_han_su_dung: "Hạn sử dụng",
  field_con_hang: "Còn hàng",
  field_het_hang: "Hết hàng",
  field_ten_nhom_thuoc: "Tên nhóm thuốc",
  field_nhom_cha: "Nhóm cha",
  field_nhom_cls: "Nhóm CLS",
  ten_doi_tuong: "Tên đối tượng",
  thu_tu: "Thứ tự",

  field_ma_lo: "Mã lô",
  field_them_lo: "Thêm mới lô",
  field_chi_tiet_phieu_nhap: "Chi tiết phiếu nhập",
  field_chi_tiet_phieu_xuat: "Chi tiết phiếu xuất",
  field_chi_tiet_phieu_ban_thuoc: "Chi tiết phiếu bán thuốc",
  field_Ten_nhom_thuoc: "Tên nhóm thuốc",
  field_tao_phieu_nhap: "Tạo phiếu nhập",
  field_ten_khach_hang: "Tên khách hàng",
  field_nguoi_ban_thuoc: "Thu ngân",

  //sổ tổng hợp
  field_shd_loai_so: "Loại sổ",
  field_shd_ten_so: "Tên sổ",
  field_shd_ky_hieu: "Ký hiệu",
  field_shd_tong_so: "Tổng số phiếu",
  field_shd_ma_so: "Mã sổ",

  // LIS
  thiet_bi_may: "Thiết bị máy",
  thiet_bi_may_lower: "thiết bị máy",
  field_ma_thiet_bi: "Mã thiết bị",
  field_ten_thiet_bi: "Tên thiết bị",
  field_ghi_chu: "Ghi chú",

  du_lieu_dieu_khien_may: "Dữ liệu điều khiển máy",
  du_lieu_dieu_khien_may_lower: "thông tin thông số máy",
  field_ten_thong_so_may: "Tên thông số máy",
  field_KQ_XN_chuan: "Tên kết quả XN chuẩn",
  field_don_vi: "Đơn vị",
  field_so_lam_tron: "Số làm tròn",
  field_TB_nam: "TB Nam",
  field_TB_nu: "TB Nữ",

  // Từ khóa thông dụng (tên button, chức năng phổ biến, tiêu đề dùng nhiều, ...)
  common_trong: "trong số",
  common_Huy: "Hủy",
  common_Thoat: "Thoát",
  common_Luu: "Lưu",
  common_Luu_va_In: "Lưu và In",
  common_In: "In",
  common_Nhap: "Nhập",
  common_Chon: "Chọn",
  common_Bat: "Bật",
  common_Xoa: "Xóa",
  common_huy_don: "Hủy đơn",
  common_dong: "Đóng",
  common_Chinh_sua: "Chỉnh sửa",
  common_Them_moi: "Thêm mới",
  common_Tim_kiem: "Tìm kiếm",
  common_Tim_kiem_theo_loai: "Tìm kiếm theo loại",
  common_Nhap_csv: "Nhập bảng tính",
  common_Xuat_csv: "Xuất bảng tính",
  common_Tat: "Tắt",
  common_Xem_truoc: "Xem trước",
  common_Tai_mau: "Tải mẫu",
  common_Tiep_tuc: "Tiếp tục",
  common_Dang_kham: "Đang khám",
  common_Kham_xong: "Khám xong",
  common_Canh_bao: "Cảnh báo",
  common_Xuat_file: "Xuất file",
  common_ok: "Đồng ý",
  common_cai_dat_lai: "Cài đặt lại",
  common_bo_loc: "Bộ lọc",
  common_tai_lai: "Tải lại",
  // Giới tính
  gioi_tinh_Nam: "Nam",
  gioi_tinh_Nu: "Nữ",
  gioi_tinh_Khac: "Khác",
  common_Dang_xuat: "Đăng xuất",

  // Khác...
  noi_dung_keo_tha_upload_file: "Nhấp hoặc thả file vào đây!",

  // Loại khoa
  loai_khoa_kham_benh: "Khám bệnh",
  loai_khoa_noi_khoa: "Nội khoa",
  loai_khoa_duoc: "Dược",
  loai_khoa_tai_chinh: "Tài chính",
  loai_khoa_xet_nghiem: "Xét nghiệm",
  loai_khoa_cdha: "CDHA",
  loai_khoa_khac: "Khác",

  // label
  label_Da_tiep_nhan: "Đã tiếp nhận",
  label_quan_ly_lich_hen: "Quản lý lịch hẹn",
  label_Tiep_don: "Tiếp đón",
  label_Tiep_don_kham_chua_benh: "Tiếp đón khám chữa bệnh",
  label_So_hoa_don: "Sổ tổng hợp",
  label_them_so_hoa_don: "Thêm mới sổ tổng hợp",
  label_sua_so_hoa_don: "Chỉnh sửa sổ tổng hợp",
  label_thong_tin_chi_tiet: "Thông tin chi tiết",
  label_phieu_xuat_nhap: "Phiếu xuất/nhập",

  // thông tin cơ sở khám chữa bệnh
  luu_va_in: "Lưu và in",
  thong_tin_cskcb: "Thông tin cơ sở khám chữa bệnh",
  luu_thay_doi: "Lưu thay đổi",
  field_ten_CSKCB: "Tên CSKCB",
  field_don_vi_quan_ly: "Đơn vị quản lý",
  field_hang_benh_vien: "Hạng bệnh viện",
  field_giam_doc_benh_vien: "Giám đốc CSKCB",
  them_hinh_anh: "Thêm hình ảnh",
  field_ten_nguoi_dung: "Tên người dùng",
  field_phan_quyen: "Phân quyền",
  txt_da_chon: "Đã chọn",
  field_ma_cc_hanh_nghe: "Mã chứng chỉ hành nghề",
  field_thong_tin_cong_viec: "Thông tin công việc",
  field_thong_tin_cong_tac: "Thông tin công tác",
  field_hoc_ham: "Học hàm",
  field_chuc_danh: "Chức danh",
  field_ho: "Họ",
  field_ten_ten_dem: "Tên",
  field_mat_khau: "Mật khẩu",
  field_ten_danh_nhap: "Tên đăng nhập",
  title_them_moi_nguoi_dung: "Thêm mới người dùng",
  title_thong_tin_tai_khoan: "Thông tin tài khoản",
  title_chinh_sua_nguoi_dung: "Chỉnh sửa người dùng",
  title_tong_gia: "Tổng giá",
  button_nhap_BHYT: "Nhập thẻ BHYT",
  button_kiem_tra_BHYT: "Kiểm tra BHYT",
  field_dia_chi_the: "Địa chỉ thẻ",
  short_ma_cc_hanh_nghe: "Mã CCHN",
  // message errorr :
  nhap_dung_dinh_dang_email: "Hãy nhập đúng định dạng email",
  nhap_email: "Hãy nhập email",
  nhap_dung_dinh_dang_sdt: "Hãy nhập đúng định dạng số điện thoại",
  nhap_sdt: "Hãy nhập số điện thoại",
  vui_long_nhap: "Vui lòng nhập !",
  please_chon: "Vui lòng chọn !",
  vui_long_chon: "Vui lòng chọn phòng thực hiện !",
  vui_long_nhap_du_ky_tu: "Vui lòng nhập đủ ký tự!",
  vui_long_nhap_dung_dinh_dang: "Vui lòng nhập đúng định dạng",
  error_trung_so_dien_thoai: "Trùng số điện thoại",
  error_trung_so_user_name: "Trùng tên đăng nhập",
  error_loi_ton_tai: "Giá trị đã tồn tại!",
  error_loi_khong_nhap_gia_tri: "Không nhập đủ giá trị!",
  error_gia_tri_khong_hop_le: "Giá trị không hợp lệ!",
  error_khong_trung_khop: "Giá trị không trùng khớp!",
  error_dich_vu_da_thanh_toan: "Không thể xóa phiếu có dịch vụ đã thanh toán",
  error_vien_phi_thieu_phieu: "Tổng số phiếu thấp hơn số hóa đơn đã tạo ( tối thiểu ",
  error_thoi_gian_khong_hop_le: "Thời gian không hợp lệ",

  cap_nhat_thanh_cong: "Cập nhật thành công",
  cap_nhat_that_bai: "Cập nhật thất bại !",
  them_moi_thanh_cong: "Thêm mới thành công ",
  them_moi_that_bai: "Thêm mới thất bại !",
  thao_tac_thanh_cong: "Thao tác thành công",
  thao_tac_that_bai: "Thao tác thất bại",

  common_Hom_nay: "Hôm nay",
  common_Hom_qua: "Hôm qua",
  Cho_kham: "Chờ khám",
  CLS: "CLS",
  common_Hoan_thanh: "Hoàn thành",
  field_Tuoi: "Tuổi",
  common_Ap_dung: "Áp dụng",
  Thong_tin_benh_nhan: "Thông tin BN",
  Lich_su_kham_benh: "Lịch sử khám bệnh",
  Chi_dinh_CLS: "Chỉ định CLS",
  Ke_don_thuoc_tu_nguyen: "Kê đơn thuốc",
  Don_thuoc_bhyt: "Đơn thuốc bhyt",
  field_Ngay_kham: "Ngày khám",
  field_Ngay_bat_dau: "Ngày bắt đầu",
  field_Ngay_ket_thuc: "Ngày kết thúc",
  field_Chan_doan_benh_chinh: "Chẩn đoán bệnh chính",
  field_Chan_doan_benh_phu_kem_theo: "Chẩn đoán bệnh phụ kèm theo",
  field_Bac_si: "Bác sĩ",
  field_Bac_si_kham: "BS khám",
  field_Khoa_dieu_tri: "Khoa điều trị",
  Don_thuoc: "Đơn thuốc",
  Ket_qua_CLS: "Kết quả CLS",
  field_CMND_CCCD: "CMND/CCCD",
  limit_thoi_gian_1_nam: "Thời gian khả dụng tối đa là 1 năm",

  field_Chi_so_sinh_ton: "Chỉ số sinh tồn",
  field_Mach: "Mạch",
  field_Lan_phut: "(Lần/phút)",
  field_Do_C: "(Độ C)",
  field_Nhiet_do: "Nhiệt độ",
  field_Nhip_tho: "Nhịp thở",
  field_Nhip_phut: "(Nhịp/phút)",
  field_Huyet_ap: "Huyết áp",
  field_Ly_do_kham: "Lý do khám",
  field_Qua_trinh_benh_ly: "Quá trình bệnh lý",
  field_Tien_su_ban_than: "Tiền sử bản thân",
  field_Tien_su_gia_dinh: "Tiền sử gia đình",
  field_Kham_lam_sang: "Khám lâm sàng",
  field_Ma_benh_chinh: "Mã bệnh chính",
  field_Ma_benh_kem_theo: "Mã bệnh kèm theo",
  field_Chan_doan_lam_sang: "Chẩn đoán lâm sàng",
  field_Chan_doan_xac_dinh: "Chẩn đoán xác định",
  field_Cach_giai_quyet: "Cách giải quyết",
  field_Ket_qua_dieu_tri: "Kết quả điều trị",
  field_Xu_tri: "Xử trí",
  field_Tai_kham: "Tái khám",
  field_Tai_kham_sau: "Tái khám sau",
  Ket_thuc_cuoc_kham: "Kết thúc lượt khám",
  field_Chon_ngay_ap_dung: "Chọn ngày áp dụng",
  bs: "BS",
  txt_phong_tai_chinh_ke_toan: "Phòng tài chính kế toán",
  field_ngay_kham: "Ngày khám",

  Ke_don_thuoc: "Kê đơn thuốc",
  field_Thoi_gian_y_lenh: "Thời gian y lệnh",
  field_Them_moi_thuoc: "Thêm mới thuốc",
  field_Kho_thuoc: "Kho thuốc",
  field_Ten_thuoc: "Tên thuốc",
  field_So_luong: "SL",
  field_Moi_ngay: "Mỗi ngày",
  field_Chia_lam: "Chia làm",
  field_Cach_dung: "Cách dùng",
  field_Ma_thuoc: "Mã thuốc",
  field_Hoat_chat: "Hoạt chất",
  field_Don_vi: "Đơn vị",
  field_In_phieu: "In Phiếu",
  field_Lan: "Lần",
  field_Ma_don_thuoc: "Mã đơn thuốc",
  field_Kho_nhan_thuoc: "Kho nhận thuốc",
  Ds_dich_vu_cls: "Danh sách dịch vụ CLS",
  field_Dich_vu_chi_dinh: "Dịch vụ chỉ định",
  field_Ma_phieu_chi_dinh: "Mã phiếu chỉ định",
  field_Bac_si_chi_dinh: "Bác sĩ chỉ định",
  Chi_dinh_can_lam_sang: "Chỉ định CLS/KT",
  chi_tiet_phieu_chi_dinh_can_lam_sang: "Chi tiết phiếu chỉ định cận lâm sàng",
  chi_tiet_tiep_don_kham_chua_benh: "Chi tiết tiếp đón khám chữa bệnh",
  tao_phieu_chi_dinh_can_lam_sang: "Tạo phiếu chỉ định cận lâm sàng",
  danh_sach_dich_vu_da_chon: "Danh sách dịch vụ đã chọn",
  field_Them_moi_can_lam_sang: "Thêm mới cận lâm sàng",
  field_Chi_tiet_phieu_cls: "Danh sách dịch vụ cận lâm sàng đã chọn",
  field_Ket_qua: "Kết quả",
  luu_va_in_phieu_kham: "Lưu và In phiếu khám",
  luu_va_in_phieu_cls: "Lưu và In phiếu CLS",
  txt_So_luong: "Số lượng",
  txt_nhap_loi_dan: "Nhập lời dặn",

  field_KQKB_khoi_benh: "Khỏi bệnh",
  field_KQKB_do: "Đỡ",
  field_KQKB_khong_thay_doi: "Không thay đổi",
  field_KQKB_nang: "Nặng",
  field_KQKB_tu_vong: "Tử vong",

  field_cach_xu_tri_ra_vien: "Ra viện",
  field_cach_xu_tri_chuyen_vien: "Chuyển viện",
  field_cach_xu_tri_tron_vien: "Trốn viện",
  field_cach_xu_tri_xin_ra_vien: "Xin ra viện",

  currency_viet_nam: "đ",
  field_Tong_gia: "Tổng giá",
  field_Tong_gia_tien: "Tổng tiền",
  error_Dich_vu_da_ton_tai: "Dịch vụ đã tồn tại",
  fallback_Phong_thuc_hien_trong: "(Chưa chọn phòng)",
  status_Chua_thanh_toan: "Chưa TT",
  status_Da_thanh_toan: "Đã TT",
  status_Cho_ket_qua: "Chờ kết quả",
  status_Da_huy: "Đã hủy",
  status_Tat_ca: "Tất cả",
  status_Cho_xac_nhan: "Chờ xác nhận",
  field_Tong_thanh_toan: "Tổng TT",

  // Viện phí

  vien_phi_da_tam_ung: "Đã tạm ứng",
  vien_phi_Tam_ung: "Tạm ứng",
  vien_phi_dich_vu_can_thanh_toan: "Dịch vụ cần thanh toán",
  vien_phi_thanh_tien: "Thành tiền",
  vien_phi_mien_giam_chi_phi: "Miễn giảm chi phí",
  vien_phi_tong_chi_phi: "Tổng chi phí",
  vien_phi_mien_giam: "Miễn giảm",
  vien_phi_tong_thanh_toan: "Tổng TT",
  vien_phi_Hoan_tien_va_in: "Hoàn tiền và in",
  vien_phi_tong_tien_benh_nhan_dua: "Bệnh nhân đưa",
  vien_phi_tien_tam_ung: "Tiền tạm ứng",
  vien_phi_tien_thua: "Tiền thừa",
  vien_phi_so_hoa_don: "Sổ thu tiền",
  vien_phi_thanh_toan_va_in: "Thanh toán và in",
  vien_phi_thanh_toan: "Thanh toán ",
  vien_phi_Tao_tam_ung: "Tạo tạm ứng ",
  vien_phi_Hoan_ung: "Hoàn ứng",
  vien_phi_Tao: "Tạo",
  vien_phi_Don_gia: "Đơn giá",
  vien_phi_warning_khong_the_bo_chon_tat_ca: "Không thể bỏ chọn tất cả",
  status_Dang_kham: "Đang khám",
  status_Hoan_thanh: "Hoàn thành",
  vien_phi_BN_phai_tra: "BN phải trả",
  vien_phi_tien_hoan_ung: "Tiền hoàn ứng",
  vien_phi_Hoan_tien: "Hoàn tiền",
  noti_vien_phi_ly_do_hoan_tien: "Vui lòng nhập lý do hoàn tiền",
  noti_so_hoa_don: "Vui lòng chọn sổ hóa đơn",

  field_Yeu_cau_hoi_chan: "Yêu cầu hội chẩn",
  field_Thong_tin_nguoi_su_dung: "Thông tin người sử dụng",
  field_Chu_ky_so: "Chữ ký số",
  field_Anh_chu_ky_scan: "Ảnh chữ ký scan",
  field_Khoa_lam_viec: "Khoa làm việc",
  field_Phong_lam_viec: "Phòng làm việc",
  field_Ket_qua_tim_kiem: "Kết quả tìm kiếm",
  field_Kham_benh: "Khám bệnh",
  field_Dich_vu: "Dịch vụ",
  field_Lua_chon_dich_vu: "Lựa chọn dịch vụ",
  field_Nhom_chi_phi_bhyt: "Nhóm chi phí BHYT",
  field_Nhom_danh_muc: "Nhóm danh mục",

  Ban_chua_chon_benh_nhan_nao: "Bạn chưa chọn bệnh nhân nào. Hãy chọn bệnh nhân để bắt đầu khám",

  data_Tuyen_trung_uong: "Tuyến trung ương",
  data_Tuyen_tinh: "Tuyến tỉnh",
  data_Tuyen_huyen: "Tuyến huyện",
  data_Tuyen_xa: "Tuyến xã",
  data_Khong_phan_tuyen: "Không phân tuyến",
  data_Hang_dac_biet: "Hạng đặc biệt",
  data_Hang: "Hạng",
  data_Khong_xep_hang: "Không xếp hạng",
  data_Kham_benh: "Khám bệnh",
  data_Kham_cls: "Khám CLS",
  data_Noi_khoa: "Nội khoa",
  data_Duoc: "Dược",
  data_Tai_chinh: "Tài chính",
  data_Xet_nghiem: "Xét nghiệm",
  data_thu_thuat: "Thủ thuật",
  thu_thuat_dac_biet: "Thủ thuật đặc biệt",
  thu_thuat_loai_1: "Thủ thuật loại 1",
  thu_thuat_loai_2: "Thủ thuật loại 2",
  thu_thuat_loai_3: "Thủ thuật loại 3",
  data_CDHA: "CDHA",
  data_Khac: "Khác",
  data_Vien_phi: "Viện phí",
  data_Bhyt: "BHYT",
  data_Lay_mau: "Lấy mẫu",
  data_Nha_thuoc: "Nhà thuốc",
  data_Kho_bhyt: "Kho BHYT",
  data_Ngay: "Ngày",
  data_Tuan: "Tuần",
  data_Thang: "Tháng",
  data_Nam: "Năm",
  data_Kho_thuoc_tu_nguyen: "Kho thuốc tự nguyện",
  data_Kho_thuoc_bhyt: "Kho thuốc bhyt",
  data_Kho_chan: "Kho chẵn",
  data_Kho_le: "Kho lẻ",
  data_Tu_truc: "Tủ trực",
  data_Noi_tru: "Nội trú",
  data_Ngoai_tru: "Ngoại trú",
  data_Noi_tru_Ngoai_tru: "Nội trú và Ngoại trú",
  data_buong_dieu_tri: "Điều trị",
  data_Tiep_tan: "Tiếp tân",
  data_Nguoi_lay_mau: "Người lấy mẫu",

  field_Nguoi_tiep_don: "Người tiếp đón",
  data_Giao_su: "Giáo sư",
  data_Pho_giao_su: "Phó giáo sư",
  data_Tien_si: "Tiến sĩ",
  data_Thac_si: "Thạc sĩ",
  data_Bs_chuyen_khoa: "Bs Chuyên khoa",
  data_Truong_khoa: "Trưởng khoa",
  data_Pho_khoa: "Phó khoa",
  data_Pho_giam_doc: "Phó giám đốc",
  data_Giam_doc: "Giám đốc",
  data_Bac_si: "Bác sĩ",
  data_Quyen: "Quyền",

  field_Ma_dvt: "Mã đơn vị tính",
  field_Ten_dvt: "Tên đơn vị tính",

  Cai_dat_llv: "Cài đặt lịch làm việc",
  Tuan_nay: "Tuần này",
  data_Thu_2: "Thứ 2",
  data_Thu_3: "Thứ 3",
  data_Thu_4: "Thứ 4",
  data_Thu_5: "Thứ 5",
  data_Thu_6: "Thứ 6",
  data_Thu_7: "Thứ 7",
  data_Chu_nhat: "Chủ nhật",
  field_Ca: "Ca",
  field_Thoi_gian_lam_viec: "Thời gian làm việc",
  field_Tu: "Từ",
  field_Den: "Đến",
  field_Ngay_ap_dung: "Ngày áp dụng",
  field_Thoi_gian_lam_viec_mac_dinh: "Thời gian làm việc mặc định",
  field_Benh_nhan_tiep_theo: "Bệnh nhân tiếp theo",
  field_So_benh_nhan_kham_mac_dinh: "Số bệnh nhân khám mặc định trên một đơn vị thời gian",
  field_Tai_phong_kham: "Tại phòng khám",
  field_phut: "phút",

  field_Ngay_tao: "Ngày tạo",
  field_Nguoi_tao: "Người tạo",
  field_Tong_chi_phi: "Tổng chi phí",
  field_Mien_giam: "Miễn giảm",
  field_hinh_thuc_thanh_toan: "Hình thức thanh toán",
  In_hoa_don: "In hóa đơn",
  field_Ki_hieu: "Kí hiệu",
  field_Loai_so: "Loại sổ",
  status_Moi: "Mới",
  status_Gan_day: "Gần đầy",
  status_Da_day: "Đã đầy",
  field_So_phieu: "Số phiếu",
  field_Ma_so: "Mã sổ",
  field_Ten_so: "Tên sổ",
  field_Tong_so_phieu: "Tổng số phiếu",
  field_Nguoi_su_dung: "Người sử dụng",
  field_So_bat_dau: "Số bắt đầu",
  field_So_ket_thuc: "Số kết thúc",
  field_Phan_quyen_nguoi_su_dung_so: "Phân quyền người sử dụng sổ",

  noti_Chi_duoc_upload_anh: "Chỉ được upload ảnh",
  noti_Kich_thuoc_anh_be_hon_5mb: "Kích thước ảnh phải nhỏ hơn 5MB",
  noti_Kich_thuoc_anh_be_hon_1mb: "Kích thước ảnh phải nhỏ hơn 1MB",
  noti_Chi_duoc_upload_excel: "Bạn chỉ có thể upload định dạng .xlsx hoặc .xls",
  noti_Dang_nhap_thanh_cong: "Đăng nhập thành công",
  noti_Dang_nhap_that_bai: "Đăng nhập thất bại",
  noti_Xoa_thanh_cong: "Xoá thành công",
  noti_Xoa_that_bai: "Xoá thất bại",
  noti_Dang_xuat_danh_sach: "Đang xuất danh sách",
  noti_Xuat_danh_sach_thanh_cong: "Xuất danh sách thành công",
  noti_Xuat_danh_sach_that_bai: "Xuất danh sách thất bại",
  noti_Nhap_du_lieu_thanh_cong: "Nhập dữ liệu thành công",
  noti_Nhap_du_lieu_that_bai: "Nhập dữ liệu thất bại",
  noti_Xoa_danh_sach_thanh_cong: "Xoá danh sách thành công",
  noti_Xoa_danh_sach_that_bai: "Xoá danh sách thất bại",
  noti_Khoa_danh_sach_thanh_cong: "Cập nhật khoá danh sách thành công",
  noti_Khoa_danh_sach_that_bai: "Cập nhật khoá danh sách thất bại",
  noti_Thuoc_da_ton_tai: "Thuốc đã tồn tại",
  noti_Chua_chon_thoi_gian_y_lenh: "Chưa chọn thời gian y lệnh hoặc chưa thêm thuốc",
  noti_Chua_chon_ngay_tai_kham: "Chưa chọn ngày tái khám",
  noti_Chua_co_thong_tin_kham: "Chưa có thông tin khám",
  noti_Chua_chon_phong_thuc_hien: "Chưa chọn phòng thực hiện hoặc dịch vụ không có phòng thực hiện",
  noti_Luu_lich_kham_thanh_cong: "Lưu lịch khám thành công",
  noti_Luu_lich_kham_that_bai: "Lưu lịch khám thất bại",
  noti_Chua_chon_benh_nhan_dv_thanh_toan: "Chưa chọn bệnh nhân hoặc dịch vụ để thanh toán",
  noti_Khong_the_huy_dich_vu_da_thanh_toan: "Không thể hủy dịch vụ đã thanh toán",
  noti_Luu_cuoc_hen_thanh_cong: "Lưu cuộc hẹn thành công",
  noti_Luu_cuoc_hen_that_bai: "Lưu cuộc hẹn thất bại",
  noti_lay_danh_sach_lich_kham_that_bai: "Lấy danh sách lịch khám thất bại",
  noti_vui_long_nhap_du_ngay_thang_nam: "Vui lòng nhập đủ ngày tháng năm",
  noti_ten_dang_nhap_da_ton_tai: "Tên đăng nhập đã tồn tại trong hệ thống",
  noti_ma_so_da_ton_tai: "Mã sổ đã tồn tại",
  Dang_nhap: "Đăng nhập",
  Dang_nhap_subtitle: "Đăng nhập để tiếp tục sử dụng các dịch vụ \n của H247 Pro",
  field_Ten_dang_nhap: "Tên đăng nhập",
  field_Nang_suat_kham: "Năng suất khám",
  field_Danh_sach_thuoc: "Danh sách thuốc",

  field_lich_lam_viec: "lịch làm việc",
  error_Chon_toi_thieu_1_ngay: "Vui lòng chọn ít nhất 1 ngày",
  error_Chua_co_ca_lam_viec: "Vui lòng thêm ít nhất 1 ca làm việc",

  field_Don_thuoc_tu_nguyen: "Đơn thuốc tự nguyện",
  field_Bac_si_ke_don: "Bác sĩ kê đơn",
  button_In_don_thuoc: "In đơn thuốc",
  field_Loi_dan: "Lời dặn",
  cf_Ban_co_chac_chan_xoa: "Bạn có chắc chắn muốn xoá không?",
  cf_Ban_co_chac_chan_khoa_nguon_khach: "Bạn có chắc chắn muốn khoá nguồn khách không?",
  cf_xoa_nguon_khach: "Sau khi xoá, thông tin nguồn khách không thể phục hồi, bạn có chắc chắn muốn xoá không?",
  cf_Ban_co_chac_chan_khoa_nhom_doi_tuong_nguoi_dung:
    "Sau khi khoá nhóm đối tượng người dùng, nguồn khách thuộc nhóm đối tượng người dùng cũng bị khoá, bạn có chắc chắn muốn khoá nó không?",
  noti_Chua_chon_dich_vu_kham_benh: "Chưa chọn dịch vụ khám bệnh",
  label_Tao_cuoc_hen_moi: "Tạo lịch hẹn",
  error_view_Chua_co_don_thuoc: "Bạn chưa có đơn thuốc nào. Hãy tạo mới đơn thuốc ngay",
  error_view_Khong_co_don_thuoc: "Không có đơn thuốc",

  field_Doi_tuong: "Đối tượng",
  field_Gio_kham: "Giờ khám",
  title_cuoc_hen_sap_toi: "Cuộc hẹn sắp tới",
  title_Tao_cuoc_hen: "Tạo cuộc hẹn",
  title_Chinh_sua_cuoc_hen: "Chỉnh sửa lịch hẹn",
  title_Chinh_sua_thong_tin_tiep_don: "Chỉnh sửa thông tin tiếp đón",
  title_Xac_nhan: "Xác nhận",
  title_Gia_dich_vu: "Giá dịch vụ",
  title_phong_khám: "Phòng khám",
  noti_Tiep_tuc_khi_chua_ke_don_thuoc: "Bệnh nhân chưa được kê đơn thuốc.\n Bạn có chắc chắn muốn tiếp tục chứ?",

  noti_Dang_tai_du_lieu: "Đang khởi tạo dữ liệu",
  noti_Tai_du_lieu_thanh_cong: "Tải dữ liệu thành công",
  noti_Tai_du_lieu_that_bai: "Tải dữ liệu thất bại",
  noti_Dang_kiem_tra_trang_thai_don_thuoc: "Đang kiểm tra trạng thái đơn thuốc",
  noti_warning_thay_doi_thoi_gian_phong:
    "Hiện tại phòng đang có dịch vụ chưa hoàn thành, nếu thay đổi thời gian hoạt động của phòng cuộc hẹn vẫn sẽ được giữ nguyên",
  noti_warning_thuoc: "Thuốc ",
  noti_warning_thuoc_co_trong_don: " đã được kê trong đơn. Vui lòng kiểm tra lại",
  noti_warning_thuoc_khong_du: " hiện không đủ thuốc trong kho. Vui lòng kiểm tra lại",
  noti_Cuoc_hen_khong_phai_hom_nay:
    "Cuộc hẹn tiếp đón không diễn ra trong ngày hôm nay.\n Bạn có chắc chắn muốn xác nhận cuộc hẹn này chứ?",
  noti_Dang_Tai_template: "Đang tải template",
  noti_Tai_template_thanh_cong: "Tải template thành công",
  noti_Tai_template_that_bai: "Tải template thất bại",
  common_Doi_ngon_ngu: "Đổi ngôn ngữ",
  field_Ma_cuoc_kham: "Mã CK",
  chi_tiet_cuoc_hen: "Chi tiết cuộc hẹn tiếp đón",
  thong_tin_cuoc_kham: "Thông tin lượt khám",
  thong_tin_kham_benh: "Thông tin khám bệnh",
  thong_tin: "Thông tin",
  the_kho: "Thẻ kho",
  xac_nhan_lich_hen: "Xác nhận lịch hẹn",
  lo_han_su_dung: "Lô - hạn sử dụng",
  title_them_moi_nhom_thuoc: "Thêm mới nhóm thuốc",
  title_chinh_sua_nhom_thuoc: "Chỉnh sửa nhóm thuốc",
  error_Rang_buoc_ton_tai: "Không thể xóa/khóa đối tượng này vì có thể ảnh hưởng đến đối tượng khác",
  common_Xuat_hang_cho: "Xuất hàng chờ",
  xac_nhan_tiep_don: "Xác nhận tiếp đón",
  xac_nhan_va_in_phieu_kham: "Xác nhận và In phiếu khám",
  title_Hang_cho_kham_benh: "Hàng chờ khám bệnh",
  title_Hang_cho: "Hàng chờ",

  error_Chua_chon_doi_tuong_benh_nhan: "Chưa chọn đối tượng bệnh nhân",
  error_Ngay_sinh_ko_hop_le: "Ngày sinh không hợp lệ",
  error_Chua_chon_thong_tin_luot_dieu_tri: "Chưa chọn đủ thông tin lượt điều trị",
  error_Khong_chon_ngay_khac_voi_ngay_truoc_do: "Không chọn ngày khác với ngày trước đó",

  field_Trang_thai_thanh_toan: "Trạng thái thanh toán",
  field_Trang_thai_cuoc_hen: "Trạng thái cuộc hẹn",
  title_Phieu_thu_da_tao: "Phiếu thu đã tạo",
  error_Gia_tri_mien_giam_ko_hop_le: "Giá trị miễn giảm không hợp lệ",
  error_Khong_sua_duoc_lich_kham: "Không sửa được lịch khám do đã tồn tại cuộc hẹn",
  error_khong_the_khoa_phong_do_co_benh_nhan_cho_kham: "Không thể khóa phòng do có bệnh nhân đã đặt hẹn ",
  thong_tin_dich_vu_cls: "Thông tin dịch vụ cận lâm sàng",

  tab_Phieu_mua_thuoc: "Phiếu bán thuốc",
  tab_Lich_su_phieu: "Lịch sử phiếu",
  field_Ma_phieu: "Mã phiếu",
  placeholder_Ma_tu_dong_tao: "Mã tự động tạo",
  placeholder_Lua_chon_don_thuoc_mau: "Lựa chọn đơn thuốc mẫu",
  common_Thay_the: "Thay thế",
  txt_Them_lo: "Thêm lô",
  txt_Chon_lo: "Chọn lô",

  noti_confirm_Huy_dich_vu: "Bạn có chắc chắn muốn huỷ dịch vụ không?",
  field_Ly_do_hoan_tien: "Lý do hoàn tiền",
  field_Tong_tien: "Tổng tiền",
  warning_url_notfound_title: "Cảnh báo truy cập",
  warning_url_notfound: "Bạn đang truy cập trang không hợp lệ, vui lòng đăng nhập lại để tiếp tục sử dụng!",
  warning_change_password: "Mật khẩu của bạn đã thay đổi, vui lòng đăng nhập lại để tiếp tục sử dụng!",
  field_Ly_do_mien_giam: "Lý do miễn giảm",

  error_body__at_column: "ở cột",
  error_body__and_duplicate_value: "và giá trị trùng lặp",

  //nhà cung cấp
  da_chon: "Đã chọn",
  nha_cung_cap: "Nhà cung cấp",
  ma_nha_cung_cap: "Mã nhà cung cấp",
  ten_nha_cung_cap: "Tên nhà cung cấp",
  dien_thoai: "Điện thoại",
  tong_mua: "Tổng mua",
  no_can_tra: "Công nợ",
  nhom_ncc: "Nhóm nhà cung cấp",
  tat_ca_cac_nhom: "tat_ca_cac_nhom",
  tu: "Từ",
  toi: "Tới",
  no_hien_tai: "Nợ hiện tại",
  tat_ca: "Tất cả",
  dang_hoat_dong: "Đang hoạt động",
  khong_hoat_dong: "Ngưng hoạt động",
  trang_thai: "Trạng thái",
  them_nha_cung_cap: "Thêm nhà cung cấp",
  thong_tin_co_so: "Thông tin cơ sở",
  dia_chi: "Địa chỉ",
  cong_ty: "Công ty",
  ma_so_thue: "Mã số thuế",
  tao_moi: "Tạo mới",
  huy: "Thoát",
  thong_tin_chi_tiet: "Thông tin chi tiết",
  thong_tin_camel: "THÔNG TIN",
  lich_su_nhap_tra_hang_camel: "LỊCH SỬ NHẬP/TRẢ HÀNG",
  quan_ly_cong_no_camel: "QUẢN LÝ CÔNG NỢ ",
  xoa: "Xóa",
  luu: "Lưu",
  ma_phieu: "Mã phiếu",
  nguoi_tao: "Người tạo",
  loai_phieu: "Loại phiếu",
  tong_tien: "Tổng tiền",
  nhap_gia_tri: "Nhập giá trị",
  gia_tri: "Giá trị",
  cong_no: "Công nợ",
  dieu_chinh: "Điều chỉnh",
  xuat_file_cong_no: "Xuất file công nợ",
  in_file_cong_no: "In file công nợ",
  no_can_tra_hien_tai: "Nợ cần trả hiện tại",
  cai_dat_ngay_gio: "Cài đặt ngày giờ",
  ngay_dieu_chinh: "Ngày điều chỉnh",
  gia_tri_no_dieu_chinh: "Giá trị nợ điều chỉnh",
  mo_ta: "Mô tả",
  cap_nhat: "Cập nhật",
  tien_mat: "Tiền mặt",
  chuyen_khoan: "Chuyển khoản",
  no_sau: "Nợ sau",
  tao_phieu_chi: "Tạo phiếu chi",
  tao_phieu_chi_va_in: "Tạo phiếu chi & in",
  tra_cho_ncc: "Trả cho NCC",
  hay_chon_ngay: "Hãy chọn ngày",
  da_nhap_hang: "Đã nhập hàng",
  da_tra_hang: "Đã trả hàng",
  hay_chon_nhom: "Hãy chọn nhóm",
  hay_dien_ma: "Hãy điền mã",
  hay_dien_ten: "Hãy điền tên",
  hay_nhap_so_dien_thoai: "Hãy nhập số điện thoại",
  hay_dien_email: "Hãy điền email",
  hay_dien_dia_chi: "Hãy điền địa chỉ",
  hay_dien_ten_cong_ty: "Hãy điền tên công ty",
  nhap_ten: "Nhập tên",
  nhap_dia_chi: "Nhập địa chỉ",

  /// BẢNG ĐIỀU KHIỂN
  doanh_thu_trung_binh: "Doanh thu trung bình",
  doanh_thu_trung_binh_moi_cuoc_kham: "Doanh thu trung bình mỗi lượt khám",
  cuoc_kham: "Ca khám",
  trung_binh_cuoc_kham: "Trung bình lượt khám",
  so_voi_ky_truoc: "so với kỳ trước",
  tuoi: "tuổi",
  so_benh_nhan_trong_ky: "Số bệnh nhân trong kỳ",
  so_benh_nhan_ky_truoc: "Số bệnh nhân kỳ trước",
  so_luong_benh_nhan_theo_thoi_gian: "Số lượng bệnh nhân theo thời gian",
  benh_nhan: "Bệnh nhân",
  tong_quan_benh_nhan: "Tổng quan bệnh nhân",
  ti_le_benh_nhan_theo_gioi_tinh: "Tỉ lệ bệnh nhân theo giới tính",
  ti_le_benh_nhan_theo_do_tuoi: "Tỉ lệ bệnh nhân theo độ tuổi",
  don_vi_trieu_dong: "Triệu đồng",
  thoi_gian: "Thời gian",

  tong_quan_dich_vu: "Tổng doanh thu dịch vụ",
  tong_so_cuoc_kham: "Tổng số lượt khám",
  tong_so_benh_nhan: "Tổng số bệnh nhân",
  tong_quan_kinh_doanh: "Tổng quan kinh doanh",
  tong_quan_tiep_don_kham_chua_benh: "Tổng quan tiếp đón khám chữa bệnh",

  field_Gia_nhap: "Giá nhập",
  field_Gia_ban: "Giá bán",
  chon_phong: "Chọn phòng",
  loai: "Loại",
  phong: "Phòng",
  thuoc: "Thuốc",
  doanh_thu_theo_thoi_gian: "Doanh thu theo thời gian",
  tong_doanh_thu: "Tổng doanh thu",
  dich_vu_cls: "Dịch vụ CLS",
  truc_tiep: "Trực tiếp",
  qua_app: "Qua App",
  qua_website: "Qua Website",
  qua_zalo: "Qua Zalo",
  ti_le_cuoc_kham_ung_voi_nguon_tiep_nhan: "Tỉ lệ lượt khám ứng với nguồn tiếp nhận",
  so_cuoc_kham_va_so_dich_vu_thoi_gian_ung_voi_nguon_tiep_nhan:
    "Số lượt khám và số dịch vụ thời gian ứng với nguồn tiếp nhận",
  ky_nay: "Kỳ này",
  ky_truoc: "Kỳ trước",

  //PHIEU

  noti_tao_moi_benh_nhan_thanh_cong: "Tạo mới bệnh nhân thành công",
  noti_tao_moi_benh_nhan_that_bai: "Tạo mới bệnh nhân thất bại",
  noti_them_vao_hang_cho_ban_thuoc_thanh_cong: "Thêm vào hàng chờ bán thuốc thành công",
  noti_them_vao_hang_cho_ban_thuoc_that_bai: "Thêm vào hàng chờ bán thuốc thất bại",
  noti_Tao_phieu_thanh_cong: "Tạo phiếu thành công!",
  noti_Tao_phieu_that_bai: "Tạo phiếu thất bại!",
  field_chinh_sua_phieu: "Chỉnh sửa phiếu",
  field_chon_phieu_mau: "Lựa chọn phiếu nhập mẫu",
  field_chon_loai_phieu_mau: "Lựa chọn phiếu mẫu",
  noti_lay_chi_tiet_phieu_mau_that_bai: "Lấy chi tiết phiếu mẫu thất bại!",
  txt_can_tra_ncc: "Cần trả NCC",
  txt_luu_phieu_mau: "Lưu phiếu mẫu",
  txt_ten_phieu_mau: "Tên phiếu mẫu",
  txt_tien_tra_ncc: "Tiền trả NCC",
  txt_tinh_vao_cong_no: "Tính vào công nợ",
  title_phieu_xuat_ban: "Phiếu xuất bán thuốc",
  title_phieu_xuat_tra: "Phiếu xuất trả nhà cung cấp",
  title_phieu_xuat_huy: "Phiếu xuất hủy",
  txt_tong_gia_tri_huy: "Tổng giá trị hủy",
  txt_tien_ncc_tra: "Tiền NCC trả",
  txt_ncc_phai_tra: "NCC phải trả",
  noti_them_thuoc_that_bai: "Thêm thuốc thất bại",

  confirm_Chuyen_cuoc_kham_tu_hoan_thanh_sang_dang_kham:
    "Ca khám hiện tại đã HOÀN THÀNH. Bạn có chắc chắn muốn chuyển trạng thái lượt khám về ĐANG KHÁM?",
  confirm_Thay_doi_kho_thuoc:
    "Danh sách thuốc đã chọn sẽ được xóa hết nếu thay đổi kho thuốc.\nBạn chắc chắn muốn tiếp tục?",
  field_Ton: "Tồn",

  //NỘI TRÚ
  title_noi_tru: "Nội trú",
  dm_Giuong: "Danh mục giường",
  dm_giuong_ma_giuong: "Mã giường",
  dm_giuong_ma_giuong_bhyt: "Mã giường BHYT",
  dm_giuong_ten_giuong: "Tên giường",

  confirm_xoa_don_thuoc: "Bạn có chắc chắn xóa đơn thuốc này?",

  // Quản lý bệnh nhân
  cai_dat_hien_thi: "Cài đặt hiển thị",
  field_do_tuoi: "Độ tuổi",
  field_ngay_kham_gan_nhat: "Ngày khám gần nhất",
  field_nho_hon_1_tuoi: "< 1 tuổi",
  field_1_11_tuoi: "1 - 11 tuổi",
  field_12_17_tuoi: "12 - 17 tuổi",
  field_18_64_tuoi: "18 - 64 tuổi",
  field_lon_hon_64_tuoi: "> 64 tuổi",
  bao_hiem_y_te: "Bảo hiểm y tế",
  danh_sach_lich_su_kham_benh: "Danh sách lịch sử khám bệnh",
  field_chuan_doan_benh_chinh: "Chẩn đoán bệnh chính",
  field_cskcb: "CSKCB",
  field_khoa_theo_byt: "Khoa theo BYT",
  field_co_so_kham_chua_benh: "Cơ sở khám chữa bệnh",
  field_ngay_tai_kham: "Ngày tái khám",
  danh_sach_phieu_thu: "Danh sách phiếu thu",
  field_loai_phieu_thu: "Loại phiếu thu",
  field_phan_loai: "Phân loại",
  field_hoan_tien: "Hoàn tiền",

  title_Bao_cao_nhanh: "Báo cáo nhanh",
  title_Danh_sach_phieu_thu: "Danh sách phiếu thu",
  tab_Bao_cao_phieu_thu: "Báo cáo phiếu thu",
  tab_Bao_cao_vien_phi: "Báo cáo viện phí",
  tab_Bao_cao_tiep_don: "Báo cáo tiếp đón",
  title_Thu_phi: "Thu phí",
  tag_Ban_thuoc: "Bán thuốc",
  button_Cai_dat_lai: "Cài đặt lại",
  button_Xuat_excel: "Xuất excel",
  button_In_bao_cao: "In báo cáo",
  label_Thoi_gian: "Thời gian",
  label_Loai_phieu: "Loại phiếu",
  option_Tat_ca: "Tất cả",
  bao_cao_duoc: "Báo cáo dược",

  txt_ngay_gan_nhat: "Ngày gần nhất",
  txt_thang_gan_nhat: "Tháng gần nhất",
  txt_tuy_chon: "Tùy chọn",

  //Loại thanh toán
  loai_phieu_nhap: "Phiếu nhập kho",
  loai_phieu_xuat: "Phiếu xuất kho",
  loai_phieu_ban_le: "Phiếu bán lẻ",
  loai_phieu_dieu_chinh: "Phiếu điều chỉnh",
  loai_phieu_thanh_toan: "Phiếu thanh toán",
  loai_phieu_xuat_huy: "Phiếu xuất huỷ",
  loai_phieu_xuat_tra_ncc: "Phiếu xuất trả NCC",
  loai_phieu_xuat_ban: "Phiếu xuất bán",

  noti_chua_nhap_lo_thuoc: "Bạn chưa nhập lô thuốc!",
  noti_so_luong_thuoc_toi_da: "Số lượng thuốc đạt mức tối đa",
  noti_lo_khong_con_thuoc: "Lô không còn thuốc",
  noti_lo_da_ton_tai: "Lô đã tồn tại",

  noti_chua_co_phieu_ban_thuoc: "Bạn chưa có phiếu bán thuốc nào. Hãy tạo phiếu bán thuốc ngay",
  title_add: "Thêm",
  noti_chua_chon_benh_nhan_ban_thuoc: "Bạn chưa chọn đơn thuốc nào để bán!",
  error_Thoi_gian_khong_hop_le: "Thời gian không hợp lệ",

  //thông tin bệnh nhân, tạo mới bệnh nhân
  tao_moi_benh_nhan: "Tạo mới bệnh nhân",
  tab_Phieu_thanh_toan: "Phiếu thanh toán",
  tab_Phieu_hoan_tien: "Phiếu hoàn tiền",
  cf_Ban_co_chac_chan_khoa: "Bạn có chắc chắn muốn khoá không?",
  cf_Ban_co_chac_chan_mo_khoa: "Bạn có chắc chắn muốn mở khoá không?",

  // NỘI TRÚ
  ma_bn: "Mã BN",
  ma_lk: "Mã LK",
  chon_khoa: "Chọn khoa",
  loc_BN: "Lọc BN",
  in_bao_cao: "In báo cáo",
  dich_vu: "Dịch vụ",
  thuoc_vat_tu_mau: "Thuốc, vật tư, máu",
  dieu_duong: "Điều dưỡng",
  dieu_tri: "Điều trị",
  xet_nghiem: "Xét nghiệm",
  cdha: "CDHA",
  pttt: "PTTT",
  thuoc_vat_tu: "Thuốc, vật tư",
  dich_vu_khac: "Dịch vụ khác",
  xu_tri: "Xử trí",
  ngay_chi_dinh: "Ngày chỉ định",
  ngay_y_lenh: "Ngày y lệnh",
  noi_chi_dinh: "Nơi chỉ định",
  ngay_ket_qua: "Ngày kết quả",
  noi_thuc_hien: "Nơi thực hiện",
  ten_chi_so: "Tên chỉ số",
  tham_chieu: "Tham chiếu",
  ten_PTTT: "Tên PTTT",
  loai_PTTT: "Loại PTTT",
  ten_thuoc_vat_tu: "Tên thuốc, vật tư",
  sl: "SL",
  HDSD: "HDSD",
  ngay_vao_giuong: "Ngày vào giường",
  ngay_ra_giuong: "Ngày ra giường",
  chon_hinh_thuc: "Chọn hình thức",
  nhap_PTTT: "Nhập PTTT",
  bat_dau_luc: "Bắt đầu lúc",
  ket_thuc_luc: "Kết thúc lúc",
  chan_doan_truoc_PT: "Chẩn đoán trước PT",
  chan_doan_sau_PT: "Chẩn đoán sau PT",
  cach_thuc_PT: "Cách thức PT",
  phuong_phap_PT: "Phương pháp PT",
  phuong_phap_vo_cam: "Phương pháp vô cảm",
  mo_ta_chi_tiet: "Mô tả chi tiết",
  nhom_mau: "Nhóm máu",
  tai_bien_PTTT: "Tai biến PTTT",
  hinh_anh_PTTT: "Hình ảnh PTTT",
  in_tuong_trinh: "In tường trình",
  ho_va_ten: "Họ và tên",
  ket_luan: "Kết luận",
  chi_tiet: "Chi tiết",
  loai_giuong: "Loại giường",
  giuong: "Giường",
  benh_kem_theo: "Bệnh kèm theo",
  benh_chinh: "Bệnh chính",
  chan_doan: "Chẩn đoán",
  da_thuc_hien: "Đã TH",
  dang_thuc_hien: "Đang TH",
  chua_thuc_hien: "Chưa TH",
  dieu_tri_noi_tru: "Điều trị nội trú",
  chuyen_khoa: "Chuyển khoa",
  cach_thuc_pttt: "Cách thức PTTT",
  phuong_phap_pttt: "Phương pháp PTTT",
  ten_ket_qua: "Tên kết quả",
  ten_excel: "Tên excel",
  don_gia: "Đơn giá",
  don_vi: "Đơn vị",
  so_lam_tron: "Số làm tròn",
  trung_binh_nam: "TB nam",
  trung_binh_nu: "TB nữ",
  trung_binh_tre_em: "TB trẻ em",
  thu_tu_in: "Thứ tự in",
  stt_excel: "STT excel",
  ghi_chu: "Ghi chú",
  field_Gia_thang_du: "Giá thặng dư",
  rh_am: "Âm",
  rh_duong: "Dương",
  tren_ban_mo: "Trên bàn mổ",
  trong_24h: "Trong 24h",
  sau_24h: "Sau 24h",
  do_phau_thuat: "Do phẫu thuật",
  do_thu_thuat: "Do thủ thuật",
  do_gay_me: "Do gây mê",
  do_nhiem_khuan: "Do nhiễm khuẩn",

  //DM Giường
  loai_giuong_thuong: "Giường thường",
  loai_giuong_ghep_2: "Giường ghép 2",
  loai_giuong_ghep_3: "Giường ghép 3",

  // Dược ngoại trú update
  txt_them_moi_kho_thuoc: "Thêm mới kho",
  field_thoi_gian_tao: "Thời gian tạo",

  // kho thuốc mới
  con_ton: "Còn tồn",
  da_het: "Đã hết",

  data_Thu_ngan: "Thu ngân",
  data_Ky_thuat_vien: "Kỹ thuật viên",
  data_Dieu_duong: "Điều dưỡng",
  data_Thu_kho: "Thủ kho",
  data_Quan_tri: "Quản trị",

  field_Nhom_nhan_vien: "Nhóm nhân viên",
  txt_them_thuoc: "Thêm thuốc",
  txt_luu_thuoc: "Lưu thuốc",
  txt_chon_kho: "Chọn kho",
  txt_chon_thuoc: "Chọn thuốc",
  txt_ds_thuoc_chi_dinh: "Danh sách thuốc đã chọn",
  txt_thanh_toan: "Thanh toán",
  txt_tong_tien_truoc_vat: "Tổng tiền trước VAT",
  txt_tong_tien_vat: "Tổng tiền VAT",
  txt_tong_tien_sau_vat: "Tổng tiền sau VAT",
  txt_chua_ban: "Chưa bán",
  txt_da_ban: "Đã bán",
  txt_duyet_kho: "Duyệt kho",
  txt_ngay_ke_don: "Ngày kê đơn",

  txt_luu_mau_don_thuoc: "Lưu đơn thuốc mẫu",
  txt_ma_mau_don_thuoc: "Mã đơn",
  txt_ten_mau_don_thuoc: "Tên đơn",
  txt_placeholder_chon_don_thuoc_mau: "Chọn đơn thuốc mẫu",
  txt_luu_mau_thong_tin: "Lưu mẫu thông tin",

  menu_Nha_cung_cap: "nhà cung cấp",
  So_luong_ton_kho: "Số lượng tồn kho",
  ton_kho: "Tồn kho",
  Phieu_nhap_kho: "Phiếu nhập kho",
  Phieu_xuat_kho: "Phiếu xuất kho",
  Van_chuyen_kho: "Vận chuyển kho",
  The_kho: "Thẻ kho",
  Quyen_duyet_phieu: "Quyền duyệt phiếu",
  //alert
  khong_duoc_phep_xoa_lich_kham: "Bạn không thể xóa lịch khám đã thanh toán hoặc trạng thái khác chờ khám",
  khong_duoc_phep_xoa_phieu: "Không thể xóa phiếu đã hoàn thành !",
  //date
  thu_2: "Thứ 2",
  thu_3: "Thứ 3",
  thu_4: "Thứ 4",
  thu_5: "Thứ 5",
  thu_6: "Thứ 6",
  thu_7: "Thứ 7",
  chu_nhat: "Chủ nhật",

  error_Rang_buoc_khoa_ton_tai: "Bạn không được phép khóa danh mục này vì có thể ảnh huỏng tới danh mục khác",

  so_hoa_don_da_day: "Sổ thu tiền đã đầy",
  title_phieu_nhap_ncc: "Phiếu nhập NCC",
  title_them_phieu_nhap_ncc: "Thêm mới phiếu nhập từ NCC",
  fieldMa_hoa_don: "Mã hóa đơn",
  placeholder_Tao_tu_dong: "Tạo tự động",

  info_Chi_xem_va_khong_duoc_thay_doi: "Chỉ xem",
  ma_phieu_bi_trung: "Mã phiếu bị trùng",

  bac_si_chua_co_ma_cchn: "Vui lòng cập nhật chứng chỉ hành nghề",

  nhap_loi_dan: "Nhập lời dặn",
  ris_nhap_tay: "RIS",
  da_nghi: "Đã nghỉ",
  chua_co_phong_thuc_hien: "Chưa có phòng thực hiện",

  lich_su_nhap_thuoc: "Lịch sử nhập thuốc",
  gia_co_vat: "Giá sau VAT",
  loai_thuoc: "Loại thuốc",

  them_moi_kho: "Thêm mới kho",
  kho_chuyen: "Kho chuyển",
  duyet_khi_ke_thuoc: "Duyệt khi kê thuốc",
  duyet_khi_chuyen_cac_kho: "Duyệt khi chuyển kho",

  // loai kho
  kho_le: "Kho lẻ",
  kho_tong: "Kho tổng",
  nha_thuoc: "Nhà thuốc",
  benh_nhan_bhyt: "Bệnh nhân BHYT",
  benh_nhan_noi_tru: "Bệnh nhân nội trú",
  benh_nhan_ngoai_tru: "Bệnh nhân ngoại trú",

  nhap_tu_ncc: "Nhập từ NCC",
  nhap_tu_kho_khac: "Nhập từ kho khác",
  xuat_vo_hong: "Xuất vỡ, hỏng",
  xuat_het_han_su_dung: "Xuất hết hạn sử dụng",
  xuat_khac: "Xuất khác",
  ban_benh_nhan: "Bán cho bệnh nhân",
  tao_phieu: "Tạo phiếu",
  them: "Thêm",
  thong_tin_thuoc: "Thông tin thuốc",
  so_benh_nhan: "Số BN",
  thong_tin_kham_nhanh: "Thông tin khám nhanh",

  field_Nam_sinh: "Năm sinh",
  danh_sach_kho_thuoc: "Danh mục kho thuốc",
  chi_tiet_kho: "Chi tiết kho",

  tip_xoa_nhieu: "Xóa nhiều",
  tip_xoa_ban_ghi: "Xóa bản ghi",
  tip_sua_ban_ghi: "Sửa bản ghi",
  tip_in_ban_ghi: "In bản ghi",
  tip_tai_len: "Tải lên bản ghi",
  tip_tai_xuong: "Tải xuống bản ghi",
  tip_tai_xuong_ban_mau: "Tải xuống bản ghi mẫu",
  tip_chinh_sua_cot: "Chỉnh sửa cột",
  tip_trang_ke_tiep: "Trang kế tiếp",
  tip_trang_sau: "Trang trước",
  tip_tai_len_hinh_anh: "Tải lên hình ảnh",
  phan_quyen_nguoi_ban: "Phân quyền người bán",
  cho_phep_ban_gia_thang_du: "Bán giá thặng dư",
  huy_duyet_phieu: "Hủy duyệt phiếu",
  huy_phieu: "Hủy phiếu",
  confirm_cancel_ticket: "Bạn có chắc chắn muốn hủy phiếu không?",
  luu_va_duyet: "Lưu và duyệt",
  tong_tt: "Tổng TT",
  duyet: "Duyệt",
  da_HT: "Đã HT",
  phieu_nhap: "Phiếu nhập",
  phieu_chuyen_kho: "Phiếu chuyển kho",
  field_Ma_may: "Mã máy",

  mau_mo_ta_chan_doan_hinh_anh: "Diagnostic imaging description form",
  phan_loai_xuat_tra_ncc: "Xuất trả NCC",
  phan_loai_xuat_vo_hong: "Xuất vỡ, hỏng",
  phan_loai_xuat_huy: "Xuất hủy",
  phan_loai_ban_benh_nhan: "Bán cho bệnh nhân",
  phan_loai_xuat_khac: "Xuất khác",
  phan_loai_xuat_het_han: "Xuất hết HSD",
  common_Them: "Thêm",
  khoa: "Khoa",
  cho_duyet: "Chờ duyệt",
  danh_sach_thuoc_chi_dinh: "Danh sách thuốc chỉ định",
  dm_may: "máy thực hiện",

  // Trả kết quả CDHA
  title_page_tra_kq_cdhd: "Trả kết quả CĐHA",
  cho_TH: "Chờ TH",
  dang_TH: "Đang TH",
  da_TH: "Đã TH",
  chup_anh: "Chụp ảnh",
  ngay_tra_kq: "Ngày trả KQ",
  nguoi_thuc_hien: "Người thực hiện",
  nguoi_tra_kq: "Người trả KQ",
  thoi_gian_tra: "Thời gian trả",
  in_kq: "In KQ",
  huy_tra: "Hủy trả",
  tra_ket_qua: "Trả KQ",

  may_thuc_hien: "máy thực hiện",
  thuc_hien: "Thực hiện",
  da_tra_kq: "Đã trả KQ",
  da_KQ: "Đã KQ",
  xem_KQ: "Xem KQ",
  content_huy_tra_phieu: "Bạn có chắc chắn huỷ trả kết quả phiếu này không?",

  tra_ket_qua_va_in: "Trả KQ và In",
  chon_mau_mo_ta: "Chọn mẫu mô tả",
  chon_may_thuc_hien: "Chọn máy thực hiện",
  noti_vui_long_chon_may_thuc_hien: "Vui lòng chọn máy thực hiện",
  noti_vui_long_chon_phong_thuc_hien: "Vui lòng chọn phòng thực hiện",
  noti_so_luong_anh_toi_da: "Số lượng ảnh tối đa",
  noti_tra_kq_thanh_cong: "Trả kết quả thành công",
  noti_tra_kq_that_bai: "Trả kết quả thất bại",
  title_form_nhap_ket_qua: "Form nhập kết quả",

  // Support
  gui_cau_hoi: "Gửi câu hỏi",
  nhap_noi_dung: "Nhập nội dung",
  gui: "Gửi",
  cau_hoi_cua_toi: "Câu hỏi của tôi",
  danh_sach_cau_hoi_thuong_gap: "Danh sách câu hỏi thường gặp",

  phan_quyen_tao_mau_mo_ta: "Tạo mẫu mô tả",
  phan_quyen_sua_mau_mo_ta: "Sửa mẫu mô tả",
  phan_quyen_xoa_mau_mo_ta: "Xoá mẫu mô tả",
  phan_quyen_khoa_mau_mo_ta: "Khoá mẫu mô tả",
  phan_quyen_tra_ket_qua: "Trả kết quả",
  phan_quyen_huy_phieu: "Huỷ phiếu",

  chen_anh: "Chèn ảnh",

  so_hoan_tien: "Sổ hoàn tiền",
  noi_dung_phan_hoi: "Nội dung phản hồi",
  nhap_noi_dung_phan_hoi: "Điền thêm thông tin chi tiết về thắc mắc / vấn đề của bạn",
  cham_soc_khach_hang_context:
    "Chúng tôi muốn lắng nghe câu hỏi và ý kiến đóng góp từ bạn. Hãy phản hồi cho chúng tôi biết vấn đề của bạn nhé!",
  cham_soc_khach_hang: "Chăm sóc khách hàng",
  huong_dan_su_dung_he_thong: "Hướng dẫn sử dụng",

  tai_pdf: "Tải PDF",
  in_pdf: "In PDF",
  hoi_dap: "Hỏi đáp",
  cong_cu_ho_tro: "Công cụ hỗ trợ",
  bc_da_thuc_hien: "Đã thực hiện",
  bc_chua_thuc_hien: "Chưa thực hiện",
  trang_thai_thuc_hien: "Trạng thái thực hiện",

  field_Ten_may: "Tên máy",
  field_Ma_BHYT: "Mã BHYT",
  field_Loai_may: "Loại máy",
  title_Danh_sach_nguoi_dung: "Danh sách người dùng",
  menu_mau_mo_ta_cdha: "Mẫu mô tả",
  menu_tra_kq_cdha: "Trả kết quả",
  bao_cao_cdha: "Báo cáo",
  huy_tra_phieu: "Hủy trả phiếu",
  title_Danh_sach_mau_mo_ta: "Danh sách mẫu mô tả",
  title_Tao_mau_mo_ta: "Tạo mẫu mô tả chẩn đoán hình ảnh",
  title_them_moi_thong_tin_ket_qua_xn_chuan: "Thêm mới thông tin kết quả xét nghiệm chuẩn",
  title_ket_qua_xet_nghiem_chuan: "Kết quả xét nghiệm chuẩn",
  dm_ket_qua_xn_chuan: "DM Kết quả XN chuẩn",
  mau_danh_muc: "Mẫu danh mục",
  field_ma_kq_dv: "Mã kết quả DV",
  field_ten_kq_dv: "Tên kết quả DV",

  chon_nhom_dv: "Chọn nhóm DV",
  chon_loai_dv: "Chọn loại DV",
  chon_dv: "Chọn DV",
  btn_mo_file_mo_ta: "Mở file mô tả",
  placeholder_nhap_mo_ta: "Nhập mô tả...",
  field_ket_luan: "Kết luận",
  tu_dong: "Tự động",
  field_mo_ta: "Mô tả",
  field_may_thuc_hien: "Máy thực hiện",
  warning_chon_nhom_dv: "Vui lòng chọn nhóm dịch vụ",
  warning_chon_loai_dv: "Vui lòng chọn loại dịch vụ",

  btn_xem_them: "Xem thêm",
  btn_chen_anh: "Chèn ảnh",
  btn_chup_anh: "Chụp ảnh",
  common_Ngay_mai: "Ngày mai",
  next_7_days: "7 ngày tiếp theo",
  next_30_days: "30 ngày tiếp theo",
  last_7_days: "7 ngày gần nhất",
  last_30_days: "30 ngày gần nhất",
  tong_tra: "Tổng trả",
  title_Danh_sach_thuoc_nhap: "Danh sách thuốc nhập",

  //key phân hệ khám bệnh
  khambenh_7ngay_gan_nhat: "7 ngày gần nhất",
  khambenh_30ngay_gan_nhat: "30 ngày gần nhất",
  khambenh_xemthem: "Xem thêm",
  khambenh_phieu_so: "Phiếu số",
  khambenh_err_xoa_dich_vu: "Không xóa được dịch vụ đã thanh toán hoặc đã thực hiện",
  khambenh_du_kq: "Đủ KQ",
  khambenh_cho_kq: "Chờ KQ",
  khambenh_xoa_ban_ghi: "Xoá bản ghi",
  khambenh_confirm_xoa: "Bạn có chắc chắn xóa dịch vụ này ?",
  khambenh_moi_ngay: "Mỗi ngày",
  khambenh_chia_lam: "Chia làm",
  khambenh_lan: "Lần",
  khambenh_so_luong_thuoc_lon_hon_0: "Số lượng thuốc cần lớn hơn 0",
  khambenh_so_luong_thuoc_khong_du: "Số lượng thuốc trong kho không đủ",
  khambenh_toi_da: "Tối đa",
  khambenh_thuoc: "Thuốc",
  khambenh_chua_dien_cach_dung: "chưa điền số lượng hoặc cách dùng",
  khambenh_thuoc_da_het: "Thuốc đã hết trong kho",
  khambenh_sl_toida: "Số lượng tối đa",
  khambenh_empty: "Trống",
  khambenh_khong_huy_don_da_ban: "Không thể hủy đơn thuốc đã được duyệt bán",
  khambenh_khong_sua_don_da_ban: "Không thể chỉnh sửa đơn thuốc đã được duyệt bán",
  khambenh_cho_thanh_toan: "Chờ thanh toán",
  khambenh_da_thanh_toan: "Đã thanh toán hết",
  khambenh_homqua: "Hôm qua",

  //key phân hệ viện phí
  vienphi_dv_khong_thanh_toan: "Dịch vụ không thể thanh toán do đã thực hiện",
  vienphi_hoan_tien_dich_vu: "Hoàn tiền dịch vụ",
  vienphi_ds_dv_can_hoan_tien: "Danh sách các dịch vụ cần hoàn tiền",
  vienphi_tong_mien_giam: "Tổng miễn giảm",
  vienphi_da_thanh_toan: "Đã TT",
  vienphi_da_thuc_hien: "Đã TH",
  vienphi_chua_thuc_hien: "Chưa TH",
  vienphi_hoan_tien: "Hoàn Tiền",
  vienphi_xoa_dich_vu: "Xóa dịch vụ",
  vienphi_so_hd_day: "Sổ hóa đơn đã đầy",
  vienphi_tong_tt: "Tổng TT",
  vienphi_vui_long_chon_so_tt: "Vui lòng chọn sổ thu tiền!",
  vienphi_no_service: "Không có dịch vụ",
  vienphi_dv_da_tt: "Dịch vụ đã thanh toán",
  vienphi_chon_so_hoan_tien: "Vui lòng chọn sổ hoàn tiền!",
  vienphi_da_hoan_tien: "Đã hoàn tiền",
  vienphi_ds_cac_dichvu: "Danh sách các dịch vụ",
  vienphi_in_phieu_thu: "In phiếu thu",
  vienphi_chua_thanh_toan: "Chưa thanh toán",
  vienphi_da_tt: "Đã thanh toán",
  vienphi_ngay: "Ngày",
  vienphi_ghi_chu: "Ghi chú",

  //key phân hệ tiếp đón
  tiepdon_nguoi_sua: "Người sửa",
  tiepdon_ngay_sua: "Ngày sửa",
  tiepdon_nhap_lien_tuc: "Nhập liên tục",
  tiepdon_khong_xoa_dv_da_tt: "Không thể xóa dịch vụ đã thanh toán !",
  tiepdon_chi_chon_1_ngay: "Chỉ được chọn 1 ngày khám trong mỗi cuộc hẹn!",
  tiepdon_xoa_cuoc_hen: "Xóa cuộc hẹn",
  tiepdon_chinh_sua_cuoc_hen: "Chỉnh sửa cuộc hẹn",
  tiepdon_cho_xac_nhan: "CHỜ XÁC NHẬN",
  tiepdon_da_xac_nhan: "ĐÃ XÁC NHẬN",
  tiepdon_da_huy: "ĐÃ HỦY",
  tiepdon_xac_nhan_xoa_cuoc_kham: "Bạn có chắc chắn muốn xóa lượt khám này không?",
  tiepdon_xac_nhan_xoa_ck_da_chon: "Bạn có chắc chắn muốn xóa lượt khám đã chọn?",
  tiepdon_chua_chon_phong: "Chưa chọn phòng!",
  tiepdon_doi_cuoc_hen_thanh_cong: "Thay đổi cuộc hẹn thành công !",
  tiepdon_doi_cuoc_hen_that_bai: "Thay đổi cuộc hẹn thất bại !",
  tiepdon_in_dich_vu: "In dịch vụ",
  tiepdon_da_nghi: "Đã nghỉ",
  tiepdon_khong_hoat_dong: "đang không hoạt động",
  tiepdon_da_kham: "Đã khám",
  tiepdon_xoa_cuoc_kham: "Xóa lượt khám",
  tiepdon_xac_nhan_huy_ck: "Bạn có chắc chắn muốn hủy các lượt khám này không?",
  tiepdon_in_cuoc_kham: "In lượt khám",
  tiepdon_sua_cuoc_kham: "Chỉnh sửa lượt khám",
  tiepdon_xac_nhan_huy_cuoc_kham: "Bạn có chắc chắn muốn hủy lượt khám này không?",

  // dược vật tư fixx

  duoc_message_chua_chon_loai_kho: "Chưa chọn loại kho",
  duoc_chon_nguoi_ban: "Chọn người bán",
  duoc_ton_kha_dung: "Tồn khả dụng",
  duoc_nguoi_huy: "Người huỷ",
  duoc_ngay_huy: "Ngày huỷ",
  duoc_thuoc: "Thuốc",
  duoc_message_lay_ds_kho_thuoc_loi: "Lấy danh sách kho thuốc lỗi",
  duoc_xoa_nha_cung_cap: "Xoá nhà cung cấp",
  sua_nha_cung_cap: "Sửa nhà cung cấp",
  confirm_xoa_nha_cung_cap: "Bạn có chắc chắn xoá nhà cung cấp này?",
  duoc_hay_nhap_gia_tri_lon_hon_0: "Hãy nhập giá trị lớn hơn 0",
  duoc_phieu_dieu_chinh_chi_tiet: "Phiếu điều chỉnh chi tiết",
  duoc_phieu_thanh_toan_chi_tiet: "Phiếu thanh toán chi tiết",
  duoc_ma_ncc_trung: "Mã nhà cung cấp trùng",
  duoc_ma_so_thue_trung: "Mã số thuế trùng",
  duoc_them_moi_nhom_ncc: "Thêm mới nhóm ncc",
  duoc_ten_nhom_ncc: "Tên nhóm ncc",
  duoc_nhap_gia_tri_nho_toi_lon: "Hãy nhập giá trị từ nhỏ tới lớn",
  da_xac_nhan: "Đã xác nhận",
  duoc_chua_chon_kho_thuoc: "Chưa chọn kho thuốc",
  duoc_so_luong_toi_da: "Số lượng tối đa",
  duoc_so_luong_lon_hon_ton_kha_dung: "Số lượng lớn hơn tồn khả dụng",
  duoc_thuoc_da_ton_tai: "Thuốc đã tồn tại",
  duoc_so_luong_thuoc_khong_kha_dung: "Số lượng thuốc không khả dụng",
  duoc_chi_tiet_phieu_chuyen_kho: "Chi tiết phiếu chuyển kho",
  duoc_them_phieu_chuyen_kho: "Thêm phiếu chuyển kho",
  duoc_kho_xuat_thuoc: "Kho xuất thuốc",
  duoc_kho_nhap_thuoc: "Kho nhập thuốc",
  duoc_chua_chon_kho_xuat: "Chưa chọn kho xuất",
  duoc_xoa_thuoc: "Xoá thuốc",
  duoc_gia_nhap_lon_hon_gia_ban: "Giá nhập lớn hơn giá bán",
  duoc_chua_nhap_thuoc: "Chưa nhập thuốc",
  duoc_gia_sau_vat: "Giá sau VAT",
  duoc_chua_chon_thuoc: "Chưa chọn thuốc",
  duoc_phieu_xuat_tra_ncc: "phiếu xuất trả NCC",
  duoc_phieu_xuat_vo_hong: "phiếu xuất vỡ, hỏng",
  duoc_phieu_xuat_huy: "phiếu xuất huỷ",
  duoc_phieu_xuat_het_han: "phiếu xuất hết hạn sử dụng",
  duoc_phieu_xuat_khac: "phiếu xuất khác",
  duoc_phieu_ban_benh_nhan: "phiếu bán cho bệnh nhân",
  duoc_ly_do_xuat: "Lý do xuất",
  duoc_chon_kho_thuoc: "Chọn kho thuốc",
  duoc_chon_ncc: "Chọn nhà cung cấp",
  duoc_vui_long_ke_don_thuoc: "Vui lòng kê đơn thuốc",
  duoc_khong_du_so_luong: "không đủ số lượng",
  duoc_nhap_day_du_thong_tin_benh_nhan: "Nhập đầy đủ thông tin bệnh nhân",
  duoc_chua_ban: "Chưa bán",
  duoc_da_ban: "Đã bán",
  duoc_nam_sinh_hop_le: "Nhập năm sinh hợp lệ",
  duoc_khach_vang_lai: "Khách vãng lai",
  duoc_them_benh_nhan: "Thêm bệnh nhân",
  duoc_in_phieu_thanh_toan: "In phiếu thanh toán",

  duoc_huy_don: "Huỷ đơn",
  duoc_huy_duyet: "Huỷ duyệt",
  duoc_duyet_don: "Duyệt đơn",
  duoc_huy_thanh_toan: "Huỷ thanh toán",
  duoc_xuat_kho_in_phieu: "Xuất kho + In phiếu",
  duoc_huy_xuat_kho: "Huỷ xuất kho",
  duoc_confirm_xoa_don_thuoc: "Bạn chắc chắn muốn xoá đơn thuốc này?",

  //title báo cáo
  bao_cao_theo_doi_thu_ngan: "Báo cáo theo dõi thu ngân",
  bao_cao_thu_ngan_benh_nhan: "Báo cáo thu ngân theo bệnh nhân và dịch vụ",
  bao_cao_bac_si_chi_dinh: "Báo cáo bác sĩ chỉ định",
  bao_cao_phong_chuc_nang: "Báo cáo phòng chức năng",
  bao_cao_doanh_thu: "Báo cáo doanh thu",
  bao_cao_the_kho: "Báo cáo thẻ kho",
  bao_cao_su_dung_thuoc_khang_sinh: "Báo cáo sử dụng thuốc kháng sinh",
  bao_cao_bien_ban_kiem_ke_thuoc: "Biên bản kiểm kê thuốc",
  bao_cao_xuat_nhap_ton: "Báo cáo xuất nhập tồn",
  bao_cao_phuong_thuc_thanh_toan: "Báo cáo phương thức thanh toán",
  bao_cao_doanh_thu_ban_thuoc: "Báo cáo doanh thu bán thuốc",
  // bao_cao_bac_si_chi_dinh : "",
  // bao_cao_bac_si_chi_dinh : ""
  // fixx ngôn ngữ báo cáo
  bao_cao_tien_kham: "Tiền khám",
  bao_cao_tien_dvkt: "Tiền DVKT",
  bao_cao_tien_thuoc: "Tiền thuốc",
  bao_cao_tien_huy: "Tiền huỷ",
  bao_cao_tien_xet_nghiem: "Tiền XN",
  bao_cao_tien_khac: "Tiền khác",
  bao_cao_tien_cdha: "Tiền CDHA",
  bao_cao_tong_cong: "Tổng cộng",
  bao_cao_tien_mien_giam: "Tiền miễn giảm",
  phong_chuc_nang: "Phòng chức năng",
  bao_cao_xuat_tat_ca_thuoc: "Xuất tất cả thuốc",
  bao_cao_xuat_tat_ca_thuoc_co_lich_su_nhap_xuat: "Xuất tất cả thuốc có lịch sử nhập thuốc",
  bao_cao_sl_so_sach: "SL sổ sách",
  bao_cao_ngoai_tru: "Ngoại trú",
  bao_cao_tien: "Tiền",
  bao_cao_ten_thuoc_ham_luong_nong_do: "Tên thuốc, nồng độ, hàm lượng",
  bao_cao_chon_kho_thuoc: "Chọn kho thuốc",
  bao_cao_ton_dau_ky: "Tồn đầu kỳ",
  bao_cao_ton_cuoi_ky: "Tồn cuối kỳ",
  bao_cao_sl_nhap: "SL nhập",
  bao_cao_sl_xuat: "SL xuất",
  bao_cao_khac: "Khác",
  bao_cao_sct_nhap: "SCT nhập",
  bao_cao_sct_xuat: "SCT xuất",
  bao_cao_lo_sx: "Lô SX",
  bao_cao_han_dung: "Hạn dùng",
  bao_cao_dien_giai: "Diễn giải",
  bao_cao_sl_ton_dau: "SL tồn đầu",
  bao_cao_sl_ton_cuoi: "SL tồn cuối",
  bao_cao_hl: "HL",
  bao_cao_thoi_gian_365_ngay: "Thời gian tìm kiếm không hợp lệ hoặc quá 365 ngày",
  bao_cao_khong_co_thuoc: "Không có thuốc",
  bao_cao_chon_nhan_su: "Chọn nhân sự",
  bao_cao_nhan_su_da_ton_tai: "Nhân sự đã tồn tại",
  bao_cao_thanh_phan_tham_gia: "Thành phần tham gia",
  bao_cao_them_thanh_phan_tham_gia: "Thêm thành phần tham gia",
  bao_cao_chon_kho_xuat_bao_cao: "Chọn kho xuất báo cáo",
  bao_cao_vui_long_nhap_thong_tin_thanh_phan_tham_du: "Vui lòng nhập thông tin thành phần tham dự",
  bao_cao_chuc_vu: "Chức vụ",
  bao_cao_sdkt: "SDK",
  bao_cao_hinh_thuc_thanh_toan: "Báo cáo hình thức thanh toán",

  common_lua_chon_khac: "Lựa chọn khác",
  common_xuat_file_thanh_cong: "Xuất file thành công",
  common_co_loi_xay_ra: "Có lỗi xảy ra !",
  commom_khong_co_du_lieu: "Không có dữ liệu",
  phan_mem_ho_tro: "Phần mềm hỗ trợ truy cập thiết bị từ xa",
  mau_dm_khoa: "Mẫu DM khoa",
  mau_dm_phong: "Mẫu DM phòng",
  mau_dm_don_vi_tinh: "Mẫu DM đơn vị tính",
  mau_dm_nhom_dich_vu: "Mẫu DM nhóm dịch vụ",
  mau_dm_loai_dich_vu: "Mẫu DM loại dịch vụ",
  mau_dm_dich_vu: "Mẫu DM dịch vụ",
  mau_dm_thuoc: "Mẫu DM thuốc",
  mau_dm_hang_san_xuat: "Mẫu DM hãng sản xuất",
  mau_dm_hoat_chat: "Mẫu DM hoạt chất",
  mau_dm_duong_dung: "Mẫu DM đường dùng",
  tong_hop_template_mau_danh_muc: "Tổng hợp template mẫu danh mục",
  tai_tat_ca_cac_mau: "Tải tất cả các mẫu",
  nguoi_lap_bao_cao: "Người lập báo cáo",
  so_dang_ky: "Số đăng ký",
  so_chung_tu: "Số chứng từ",
  so_luong_dau_ky: "Số lượng đầu kỳ",
  stt: "STT",
  ten_bs_chi_dinh: "Tên BS chỉ định",
  bac_si: "bác sĩ",
  cong_khoan: "Cộng khoản",
  truong_phong_tckt: "Trưởng phòng TCKT",
  ky_ten_va_dong_dau: "(Ký tên và đóng dấu)",
  ky_ten_va_ghi_ro_ho_ten: "Ký tên và ghi rõ họ tên",
  ten_bn: "Tên BN",
  ma: "Mã",
  truong_khoa_duoc: "Trưởng khoa dược",
  to_kiem_ke_gom_co: "Tổ kiểm kê gồm có",
  thoi_gian_kiem_ke_thuoc: "Thời gian kiểm kê thuốc",
  dia_chi_kiem_ke: "Địa chỉ kiểm kê",
  hong_vo: "Hỏng vỡ",
  han_dung: "Hạn dùng",
  ghi_nho: "Ghi nhớ",
  so_sach: "Sổ sách",
  thuc_te: "Thực tế",
  y_kien_de_xuat: "Ý kiến đề xuất",
  thanh_vien: "Thành viên",
  sl_nhap: "SL nhập",
  sl_xuat: "SL xuất",
  thuoc_kho: "Thuộc kho",
  ten_nhan_vien: "Tên nhân viên",
  so_nhan_vien: "nhân viên",
  nhan_vien: "Nhân viên",
  thong_ke_phong_chuc_nang: "Thống kê phòng chức năng",
  xuat: "Xuất",
  nguoi_lap_bang: "Người lập bảng",
  hoa_don_ban_hang: "Hóa đơn bán hàng",
  khach_hang: "Khách hàng",
  khach_mua_theo_don: "Khách mua theo đơn",
  khach_vang_lai: "Khách vãng lai",
  ten_san_pham: "Tên sản phẩm",
  tong_tien_hang: "Tổng tiền hàng",
  tong_thanh_toan: "Tổng thanh toán",
  chiet_khau_hoa_don: "Chiết khấu hóa đơn",
  chiet_khau: "Chiết khấu",
  cam_on_va_hen_gap_lai: "Cám ơn và hẹn gặp lại",
  phieu_nhap_hang: "Phiếu nhập hàng",
  nguoi_lap: "Người lập",
  chi_nhanh: "Chi nhánh",
  ma_hang: "Mã hàng",
  ten_hang: "Tên hàng",
  giam_gia: "Giảm giá",
  tong_thanh_tien: "Tổng thành tiền",
  mien_giam_hoa_don: "Miễn giảm hóa dơn",
  tien_ncc_can_tra: "Tiền NCC cần trả",
  ky_va_ghi_ro_ho_ten: "Ký và ghi rõ họ tên",
  gia_tri_huy: "Giá trị hủy",
  sl_huy: "SL hủy",
  ma_so: "Mã số",
  so: "Số",
  ten_thuoc_hoa_chat: "Tên thuốc, hóa chất, vật tư y tế tiêu hao",
  ham_luong_nong_do: "Hàm lượng, nồng độ, quy cách đóng",
  ki_thuat_chi_dinh: "Kỹ thuật chỉ định",
  loi_dan_cua_bac_si_khoa: "Lời dặn của bác sĩ",
  bac_si_phu_trach_kham: "Bác sĩ phụ trách khám",
  do_tuoi: "Độ tuổi",
  kham_lai_theo_don_thuoc_nay: "Khám lại theo đơn thuốc này",
  bang_chu: "Bằng chữ",
  thong_tin_bo_loc: "Thông tin bộ lọc",
  ten_benh_nhan: "Tên bệnh nhân",
  ma_don_thuoc_mau_da_ton_tai: "Mã đơn thuốc mẫu đã tồn tại, bạn có muốn thay thế mẫu đơn đã lưu?",
  ma_khach_hang: "Mã khách hàng",
  kham_ngay: "Khám ngày",
  Phieu_co_gia_tri_trong_ngay: "Phiếu có giá trị trong ngày",
  bac_si_fulltime: "Bác sĩ fulltime",
  bac_si_parttime: "Bác sĩ parttime",
  chuyen_gia_hop_tac: "Chuyên gia hợp tác",
  co_so_kcb: "Cơ sở KCB",
  sales: "Sales",
  cong_tac_vien: "Công tác viên",

  // thu_ngan_giu:"Thu ngân giữ",
  // khach_hang_giu:"Khách hànPg giữ",
  // thong_tin_khach_hang:"Thông tin khách hàng",
  // ten_khach_hang:"Tên khách hàng",
  // tong_so_tien:"Tổng số tiền",

  title_Phieu_thu_phi_dich_vu: "Phiếu thu phí dịch vụ",
  title_Phieu_tom_tat_kham: "Phiếu tóm tắt khám",

  nguoi_tao_bao_cao: "Người tạo báo cáo",
  sdt: "SĐT",
  field_doi_tuong_bn: "Đối tượng bệnh nhân",
  cong_hoa_xa_hoi_chu_nghia: "Cộng hòa xã hội chủ nghĩa Việt Nam",
  doc_lay_tu_do_hanh_phuc: "Độc lập - Tự do - Hạnh phúc",

  txt_ngay_nhap: "Ngày nhập",
  txt_hoa_don_so: "Hóa đơn số",
  txt_nhap_tai_kho: "Nhập tại kho",
  common_da: "đã",
  txt_nguoi_giao: "Người giao",
  txt_ke_toan: "Kế toán",
  txt_Thu_truong_don_vi: "Thủ trưởng đơn vị",
  txt_tu_choi: "Từ chối",
  noti_so_tien_am: "Số tiền âm",
  noti_khong_dong: "Không đồng",

  title_Chi_tiet_cong_no_ncc: "Chi tiết công nợ nhà cung cấp",

  no_dau_ky: "Nợ đầu kỳ",
  phat_sinh_trong_ky: "Phát sinh trong kỳ",
  tong_hoa_don: "Tổng hóa đơn",
  txt_cong_khoan: "Cộng khoản",
  txt_nguoi_tao_phieu: "Người tạo phiếu",

  title_phieu_thanh_toan: "Phiếu thanh toán",
  txt_tm_cong_ty: "TM công ty",
  common_Tong: "Tổng",
  txt_phieu_chi: "Phiếu chi",
  txt_nguoi_nhan_tien: "Người nhận tiền",
  txt_ly_do_nhan: "Lý do nhận",
  txt_So_tien: "Số tiền",
  txt_thu_quy: "Thủ quỹ",
  txt_nguoi_lap_phieu: "Người lập phiếu",
  txt_nguoi_nhan: "Người nhận",
  txt_phong_tai_chinh: "Phòng tài chính kế toán",
  phieu_co_gia_tri_trong_ngay: "Phiếu có giá trị trong ngày",
  phieu_thu_phi_dich_vu: "Phiếu thu phí dịch vụ",
  lien_0_dau: "Liên",
  thu_ngan_giu: "Thu ngân giữ",
  khach_hang_giu: "Khách hàng giữ",
  thong_tin_khach_hang: "Thông tin khách hàng",
  ten_khach_hang: "Tên khách hàng",
  thanh_tien: "Thành tiền",
  tong_cong: "Tổng cộng",
  tong_so_tien: "Tổng số tiền",
  phieu_tom_tat_kham: "Phiếu tóm tắt khám",
  loi_nhap_form: "Lỗi nhập",
  status_Da_hoan_thanh: "Đã HT",

  text_khoi_tao_du_lieu: "Hệ thống đang khởi tạo dữ liệu, vui lòng chờ đợi...",
  noti_dang_chuyen_doi_file: "Đang chuyển đổi file...",
  noti_chuyen_doi_file_thanh_cong: "Chuyển đổi file thành công",
  noti_chuyen_doi_file_that_bai: "Chuyển đổi file thất bại",
  noti_dinh_dang_file_word: "Vui lòng tải lên file định dạng doc, docx",
  noti_vui_long_nhap_mo_ta: "Vui lòng nhập mô tả",
  thuoc_thuong: "Thuốc thường",
  thuoc_nha_thuoc: "Thuốc nhà thuốc",
  title_thong_bao_chuyen_kho: "Thông báo chuyển kho",
  prefix_so_luong_thuoc_trong_kho_hien_khong_du: "Số lượng thuốc trong",
  suffix_so_luong_thuoc_trong_kho_hien_khong_du: "hiện tại đang không đủ. Bạn có muốn thay đổi?",

  sl_chuyen_kho: "SL chuyển kho",
  noti_so_luong_duyet_khong_lon_hon_ton_kha_dung: "Số lượng duyệt không lớn hơn tổn khả dụng!",

  xuat_ban_dau_sac: "Xuất bán",
  duoc_them_moi_nhom: "Thêm mới nhóm",
  tao_moi_mau_mo_ta: "Tạo mới mẫu",
  noti_chua_nhap_mo_ta: "Chưa nhập mô tả",
  noti_chua_nhap_ket_luan: "Chưa nhập kết luận",
  error_khoa_da_duoc_chon: "Khoa đã được chọn!",
  prefix_xoa_lich: "Xóa lịch",
  noti_chua_hoan_thanh_dich_vu: "Chưa hoàn thành dịch vụ, bạn có muốn rời đi ?",
  file_hdsd: "File HDSD",
  cai_dat: "Cài đặt",
  chi_tiet_cuoc_kham: "Chi tiết lượt khám",
  duoc_sua_nha_cung_cap: "Sửa nhà cung cấp",
  tim_kiem_lich_lam_viec: "Tìm kiếm lịch làm việc",
  tim_kiem_dich_vu: "Tìm kiếm dịch vụ",
  dat_cau_hoi_thanh_cong: "Đặt câu hỏi thành công",
  dong_y: "Đồng ý",
  content_sau_khi_dat_cau_hoi_thanh_cong:
    "Cảm ơn bạn đã đặt câu hỏi cho chúng tôi, câu hỏi của bạn đã được chúng tôi ghi nhận, thời gian phản hồi dự kiến trong 24h tới.",

  /// fix language Exel
  common_benh_vien: "Bệnh viện",
  common_bo_y_te: "Bộ Y tế (Sở Y tế)",
  common_tt: "TT",
  common_ngay_thang_nam: "Ngày...tháng...năm",
  common_tu_ngay: "Từ ngày",
  common_den: "đến",
  common_gio: "Giờ",
  ten_thuoc_hoa_chat_vat_tu_y_te_tieu_hao: "Tên thuốc, hóa chất, vật tư ý tế tiêu hao",
  ham_luong_nong_do_quy_cach_dong_goi: "Hàm lượng, nồng độ, quy cách đóng gói",
  ky_ghi_ro_ho_ten: "(Ký và ghi rõ họ tên)",
  nguoi_tao_phieu: "Người tạo phiếu",
  tm_cong_ty: "TM công ty",
  chi_tiet_cong_no: "Chi tiết công nợ",
  so_kiem_soat: "Số kiểm soát",
  bien_ban_kiem_nhap_thuoc: "Biên bản kiểm nhập thuốc",
  bs_chi_dinh: "BS chỉ định",
  phong_tai_chinh_ke_toan: "PHÒNG TÀI CHÍNH - KẾ TOÁN",
  mau_bao_cao_ve_khang_sinh: "Mẫu báo cáo về kháng sinh",
  so_giuong_benh_ke_hoach_thuc_ke: "Số giường bệnh kế hoạch/thực kê",
  cong_suat_su_dung_giuong_benh: "Công suất sử dụng giường bệnh:...(%)",
  tt_hoat_chat: "TT hoạt chất",
  tt_biet_duoc: "TT biệt dược",
  nong_do_ham_luong: "Nồng độ/hàm lượng",
  ma_atc: "Mã ATC",
  ten_biet_duoc: "Tên biệt dược",
  don_vi_don_goi: "Đơn vị đóng gói",
  duong_dung: "Đường dùng",
  bao_cao_su_dung_thuoc: "Báo cáo sử dụng thuốc",
  thoi_gian_kiem_ke_thuoc_tinh_den: "Thời gian kiểm kê thuốc tính đến",
  lich_su_nhap_tra_ncc: "Lịch sử nhập trả NCC",
  nguoi_thu_tien: "Người thu tiền",
  no_cuoi_ky: "Nợ cuối kỳ",
  txt_can_tra: "Cần trả",
  txt_thuc_tra: "Thực trả",
  txt_ghi_no: "Ghi nợ",
  txt_ghi_co: "Ghi có",
  da_kiem_ke_tai: "Đã kiểm kê tại",
  ho_ten: "Họ tên",

  //update
  ten_nguoi_than: "Tên người thân",
  quan_he_gia_dinh: "Quan hệ gia đình",
  tong_chi_phi_KCB: "Tổng chi phí KCB",
  bh_thanh_toan: "BH thanh toán",
  benh_nhan_da_tt: "Bệnh nhân đã TT",
  benh_nhan_chua_tt: "Bệnh nhân chưa TT",
  can_nang: "Cân nặng",
  chieu_cao: "Chiều cao",

  tien_su_benh: "Tiền sử bệnh",
  tien_sua_benh_ban_than: "Tiền sử bệnh bản thân",
  benh_su: "Bệnh sử",
  kham_benh: "Khám bệnh",
  tien_su_san_khoa: "Tiền sử sản khoa",
  tien_su_benh_gia_dinh: "Tiền sử bệnh gia đình",
  tien_su_di_ung: "Tiền sử dị ứng",
  trieu_chung_lam_sang: "Triệu chứng lâm sàng",
  tai_nan_thuong_tich: "Tai nạn thương tích",

  toan_than: "Toàn thân",
  cac_bo_phan: "Các bộ phận",
  tuan_hoan: "Tuần hoàn",
  ho_hap: "Hô hấp",
  tieu_hoa: "Tiêu hoá",
  than_tiet_nieu: "Thận, tiết niệu",
  noi_tiet: "Nội tiết",
  co_xuong_khop: "Cơ xương khớp",
  than_kinh: "Thần kinh",
  bs_kham: "BS khám",

  chan_doan_so_bo: "Chẩn đoán sơ bộ",
  icd_benh_chinh: "ICD bệnh chính",
  icd_yhct_chinh: "ICD YHCT bệnh chính",
  icd_benh_kem_theo: "ICD bệnh kèm theo",
  idc_yhct_kem: "ICD YHCT bệnh kèm theo",
  chan_doan_xac_dinh: "Chẩn đoán xác định",
  huong_xu_tri: "Hướng xử trí",
  loi_dan: "Lời dặn",
  ngay_tai_kham: "Ngày tái khám",

  hoi_benh: "Hỏi bệnh",
  kham_tong_quan: "Khám tổng quát",
  chan_doan_xu_tri: "Chẩn đoán xử trí",
  confirm_ket_thuc_cuoc_kham: "Bạn có chắc chắn muốn kết thúc lượt khám?",
  ke_don: "Kê đơn",
  vui_long_nhap_chan_doan_so_bo: "Vui lòng nhập chẩn đoán sơ bộ",
  mau_thong_tin: "Mẫu thông tin",

  confirm_huy_xuat_kho: "Bạn có chắc chắn huỷ xuất kho đơn thuốc không?",
  confirm_huy_thanh_toan_don_thuoc: "Bạn có chắc chắn huỷ thanh toán đơn thuốc không?",
  confirm_huy_duyet_don_thuoc: "Bạn có chắc chắn huỷ duyệt đơn thuốc không?",
  nguon_khach: "Nguồn khách",
  sdt_nguoi_than: "SĐT người thân",
  dia_chi_nguoi_than: "Địa chỉ người thân",

  khong_duoc_de_trong: "${label} không được để trống",
  toi_da_ky_tu: "${label} tối đã ${max} ký tự",
  thong_tin_doi_tuong: "Thông tin đối tượng",
  noti_lay_danh_sach_that_bai: "Lấy danh sách thất bại",
  //CÁCH DÙNG
  them_moi_cach_dung: "Thêm mới cách dùng",
  ten_cach_dung: "Tên cách dùng",
  vui_long_nhap_ten_cach_dung: "Vui lòng nhập tên cách dùng",

  lich_su_kham_benh: "Lịch sử khám bệnh",
  thong_tin_ck: "Thông tin CK",
  don_thuoc_cu: "Đơn thuốc cũ",
  ket_qua_cls: "Kết quả CLS",
  xem_nhanh: "Xem nhanh",
  ngay_gio_y_lenh: "Ngày giờ y lệnh",
  so_ngay_sd: "Số ngày SD",
  huong_dan_su_dung: "Hướng dẫn sử dụng",
  xem_nhanh_lich_su_kham_benh: "Xem nhanh lịch sử khám bệnh",
  tien_su: "Tiền sử",
  new_so_ngay_sd: "Số ngày sử dụng",
  new_moi_ngay: "Mỗi ngày sử dụng",
  tan_suat_sd: "Tần suất sử dụng",
  txt_ngay: "ngày",

  chan_doan_hinh_anh: "Chẩn đoán hình ảnh",
  phieu_kham_benh: "Phiếu khám bệnh",
  don_thuoc_dich_vu: "Đơn thuốc dịch vụ",
  ma_benh_nhan: "Mã bệnh nhân",
  chan_doan_chinh: "Chẩn đoán chính",
  chan_doan_phu: "Chẩn đoán phụ",
  ngay_dung: "Ngày dùng",
  chi_dan_dung_thuoc: "Chỉ dẫn dùng thuốc",
  loi_dan_bac_sy: "Lời dặn của bác sĩ",
  kham_lai_xin_mang_theo_don: "Khám lại xin mang theo đơn này",
  ten_bo_hoac_me_cua_tre: "Tên bố hoặc mẹ của trẻ hoặc người đưa trẻ đến khám bệnh chữa bệnh",
  title_phieu_chi_dinh_cho_bn_ngoai_bh: "PHIẾU CHỈ ĐỊNH DÀNH CHO BỆNH NHÂN NGOÀI BẢO HIỂM",
  khoa_phong: "Khoa phòng",
  tong_tien_nguoi_benh_phai_tra: "Tổng số tiền người bệnh trả",
  da_duoc_tu_van_day_du:
    "Đã được tư vấn đầy đủ và đồng ý thực hiện các dịch vụ tại phòng khám với mức giá đã niêm yết tại bảng giá DV y tế.",
  error_loi_ket_noi: "Lỗi kết nối. Vui lòng đăng nhập lại!",
  cho_thuc_hien: "Chờ thực hiện",
  Dang_thuc_hien: "Đang thực hiện",

  ban_than: "Bản thân",
  gia_dinh: "Gia đình",

  vui_long_chon_thuoc: "Vui lòng chọn thuốc",
  don_thuoc_mau: "Đơn thuốc mẫu",
  vui_long_chon_kho: "Vui lòng chọn kho",
  ma_don: "Mã đơn",
  ten_don: "Tên đơn",
  danh_sach_thuoc_chi_dinh_trong_don: "Danh sách thuốc chỉ định trong đơn",
  in_don_tu_van: "In đơn tư vấn",
  phong_tiep_don: "Phòng tiếp đón",
  noti_chua_luu_thay_doi_don_thuoc_khi_bam_in:
    "Bạn chưa lưu thay đổi đơn thuốc. Vui lòng nhấn lưu đơn thuốc để tiếp tục in.",

  phieu_tu_van: "Đơn thuốc tư vấn",
  noti_don_thuoc_khong_co_thuoc_byt: "Đơn thuốc không có thuốc BYT",
  noti_don_thuoc_khong_co_thuoc_tpcn: "Đơn thuốc không có thuốc TPCN",
  noti_vui_long_nhap_day_du_cac_truong_bat_buoc: "Vui lòng nhập đầy đủ các trường bắt buộc",
  noti_vui_long_nhap_day_du_cac_truong_bat_buoc_cua_thuoc_trong_don:
    "Vui lòng nhập đầy đủ các trường bắt buộc của thuốc trong đơn",
  noti_vui_long_nhap_dung_dinh_dang: "Vui lòng nhập đúng định dạng",
  nhap_gia_tri_lon_hon: "Nhập giá trị lớn hơn",
  va_nho_hon: "và nhỏ hơn",
  chua_co_lich_su: "Chưa có lịch sử",
  chua_chon_benh_nhan_vao_kham: "Chưa chọn bệnh nhân vào khám",

  //popup thông tin khám
  ttk_tieu_de: "Danh sách mẫu",
  ttk_tao_moi: "Thêm mới mẫu",
  nhap_ten_mau: "Tên mẫu",
  luu_mau: "Lưu mẫu",
  sua_mau: "Sửa mẫu",
  msg_ten_mau: "Vui lòng nhập vào tên mẫu",
  msg_xoa_mau_thanh_cong: "Xoá mẫu thông tin khám thành công",
  tooltip_sua_mau: "Sửa mẫu thông tin khám",
  txt_xac_nhan_xoa_mau: "Bạn có chắc chắn muốn xoá mẫu này không?",
  msg_luu_mau_thanh_cong: "Lưu mẫu thành công!",
  msg_sua_mau_thanh_cong: "Sửa mẫu thành công!",

  ////
  ket_qua_xet_nghiem: "Kết quả xét nghiệm",
  thong_tin_ket_qua_xn: "Thông tin kết quả XN",
  file_dinh_kem: "File đính kèm",
  ten_xet_nghiem: "Tên xét nghiệm",
  gia_tri_tham_chieu: "Giá trị tham chiếu",
  ngay_nhan_kq: "Ngày nhận KQ",
  thiet_bi: "Thiết bị",
  so_chay: "Số chạy",
  ngay_chay: "Ngày chạy",
  ngay_duyet: "Ngày duyệt",
  chi_dinh: "Chỉ định",
  thong_tin_file_dinh_kem: "Thông tin file đính kèm",
  khoang_tham_chieu: "Khoảng tham chiếu",
  nguoi_lay_mau: "Người lấy mẫu",
  thoi_gian_lay_mau: "Thời gian lấy mẫu",
  ket_qua_nam_lech: "Kết quả nằm lệch",
  ket_qua_nam_giua: "Kết quả nằm giữa",
  ket_qua_in_dam: "Kết quả in đậm",
  trai: "trái",
  phai: "phải",
  label_ngay_tiep_don: "Ngày tiếp đón",

  khong_dung_dinh_dang: "không đúng định dạng",
  ten_hinh_thuc_thanh_toan: "Hình thức thanh toán",
  cate_hinh_thuc_thanh_toan: "hình thức thanh toán",

  answer_co: "Có",
  answer_khong: "Không",

  thong_tin_benh_nhan: "Thông tin bệnh nhân",
  question_1: "Bạn có dị ứng với thực phẩm, thuốc hoặc chất liệu gì không?",
  question_2: "Bạn có từng nhập viện hay phẫu thuật trước đây không?",
  question_3: "Bạn đã từng truyền máu chưa?",
  question_4: "Bạn có đang điều trị hay sử dụng thuốc nào không?",
  question_family: "Trong gia đình có ai bị mắc các bệnh trước đây không?",
  question_info_1: "Bạn biết phòng khám của chúng tôi qua nguồn tin nào?",
  nguyen_vong_khac: "Nguyện vọng khác",
  lich_kham_chua_hoan_thanh: "Bệnh nhân có lịch khám trong ngày chưa hoàn thành",
  huyet_ap_cao: "Huyết áp cao",
  tieu_duong: "Tiểu đường",
  tai_bien_nao: "Tai biến não",
  benh_tim_mach: "Bệnh tim mạch",
  benh_than: "Bệnh thận",
  benh_kawasaki: "Bệnh Kawasaki",
  sot_xuat_huyet: "Sốt xuất huyết",
  benh_lao: "Lao",
  viem_da_di_ung: "Viêm da dị ứng",
  other: "Khác",
  ung_thu: "Ung thư",
  dau_nua_dau: "Đau nửa đầu",
  bao_chi: "Báo chí",
  cong_ty_ban_be: "Công ty/Bạn bè người thân",
  save_image: "Lưu ảnh",
  error_khong_co_dich_vu: "Không có dịch vụ",
  action_dung: "Dừng",
  chup_tu_dong: "Chụp tự động",
  so_luong_anh_toi_da: "Số lượng ảnh tối đã 6",
  thoi_gian_dem_lui: "Thời gian đếm lùi",
  tiep_tuc_tai_anh_len: "Tiếp tục tải ảnh lên",
  chinh_sua_anh: "Chỉnh sửa ảnh",
  chon_anh: "Chọn ảnh",
  tai_len_hinh_anh: "Tải lên hình ảnh",

  ma_loai_dich_vu: "Mã loại dịch vụ",
  nhom_loai_dich_vu: "Nhóm loại dịch vụ",
  truong_khoa_chan_doan_hinh_anh: "Trưởng khoa chẩn đoán hình ảnh",
  thong_ke_hoat_dong_chan_doan_tham_do_chuc_nang: "Thống kê hoạt động chẩn đoán thăm dò chức năng",
  thoi_gian_chi_dinh: "Thời gian chỉ định",
  ngay_thanh_toan: "Ngày thanh toán",
  ma_kh: "Mã KH",
  bao_cao_doanh_thu_kh: "Báo cáo doanh thu khách hàng",
  bao_cao_phieu_thu_dich_vu: "Báo cáo phiếu thu dịch vụ",
  phieu_dich_vu: "Phiếu dịch vụ",

  noti_khong_the_xoa_cuoc_kham_co_dich_vu_da_thanh_toan:
    "Không thể xóa cuộc khám do cuộc khám có ít nhất một dịch vụ đã thanh toán",

  CIS_ql_pk: "DM Khám chữa bệnh",
  LIS_ql_xn: "DM Xét nghiệm",
  RIS_ql_cdha: "DM CĐHA",
  PRM_ql_cskh: "PRM - Quản lý CSKH",
  home_vtc: "Trang\n" + "chủ",
  right_duoc: "Dược\n" + "ngoại trú",
  khuyen_nghi: "Khuyến nghị",
  cap_nhat_phan_mem: "Cập nhật phần mềm",
  content_update_version:
    "Hiện tại hệ thống đã có phiên bản cập nhật mới, quý khách vui lòng đăng nhập để trải nghiệm tính năng mới.",

  thu_gon: "Thu gọn",
  mo_rong: "Mở rộng",
  trang_thai_kham: "Trạng thái khám",
  trang_thai_thanh_toan: "Trạng thái thanh toán",
  field_Dat_kham: "Đặt khám",

  so_chan_doan_hinh_anh: "Sổ chẩn đoán hình ảnh",
  hd_chan_doan_hinh_anh_theo_loai_dv: "Hoạt động chẩn đoán hình ảnh theo loại dịch vụ",
  thong_ke_dv_cdha_theo_bn: "Thống kê dịch vụ CDHA, TDCN theo bệnh nhân",
  confirm_huy_thuc_hien_khi_co_dv_da_tra: "Phiếu đã có dịch vụ trả kết quả. \nBạn có muốn tiếp tục không?",
  confirm_huy_thuc_hien: "Bạn có chắc chắn muốn huỷ thực hiện không?",
  thuc_hien_thu_thuat: "Thực hiện thủ thuật",
  huy_thuc_hien: "Hủy thực hiện",
  da_thanh_toan: "Đã thanh toán",
  can_tao_lich_lam_viec: "Cần tạo lịch làm việc",
  menu_phauthuat_thuthuat: "Phẫu thuật, thủ thuật",
  dm_thuthuat_ma_cttt: "Mã cách thức TT",
  dm_thuthuat_ten_cttt: "Tên cách thức TT",
  dm_thuthuat_ma_pptt: "Mã phương pháp TT",
  dm_thuthuat_ten_pptt: "Tên phương pháp TT",
  field_thuthuat_ma_cttt: "Mã cách thức thủ thuật",
  field_thuthuat_ten_cttt: "Tên cách thức thủ thuật",
  field_thuthuat_ma_pptt: "Mã phương pháp thủ thuật",
  field_thuthuat_ten_pptt: "Tên phương pháp thủ thuật",

  tong_kh_thanh_toan: "Tổng KH thanh toán",
  tong_tien_hoan: "Tổng tiền hoàn",

  da_xac_thuc: "Đã xác thực",
  chuyen_huong_toi_trang_chu: "Chuyển hướng tới trang chủ",
  quan_ly_tai_khoan: "Quản lý tài khoản",
  thong_tin_tai_khoan: "Thông tin tài khoản",
  doi_mat_khau: "Đổi mật khẩu",
  mat_khau_cu: "Mật khẩu cũ",
  mat_khau_moi: "Mật khẩu mới",
  nhap_lai_mat_khau_moi: "Nhập lại mật khẩu mới",
  content_doi_mat_khau: "Nhập lại mật khẩu để thay đổi mật khẩu mới",
  vui_long_xac_nhapn_mat_khau: "Vui lòng xác nhận mật khẩu",
  xac_nhan_mat_khau_khong_dung: "Xác nhận mật khẩu không đúng",
  thong_tin_ket_qua_xet_nghiem_chuan: "Thông tin kết quả xét nghiệm chuẩn",
  cate_quan_ly_phan_he: "Quản lý phân hệ",
  ket_qua_mac_dinh: "Kết quả mặc định",
  ghi_chu_can_tren: "Ghi chú cận trên",
  ghi_chu_can_duoi: "Ghi chú cận dưới",
  ghi_chu_trung_binh: "Ghi chú trung bình",
  hien_thi: "Hiển thị",
  cs_kem_theo: "CS kèm theo",
  can_duoi_nam: "Cận dưới nam",
  can_duoi_nu: "Cận dưới nữ",
  can_tren_nu: "Cận trên nữ",
  can_tren_nam: "Cận trên nam",
  can_tren_tre_em: "Cận trên trẻ em",
  can_duoi_tre_em: "Cận dưới trẻ em",
  can_tren: "Cận trên",
  can_duoi: "Cận dưới",
  phong_lay_mau: "Phòng lấy mẫu",
  loai_xet_nghiem: "Loại XN",
  danh_sach_chi_so_kem_theo: "Danh sách chỉ số kèm theo",
  thong_tin_chi_so_kem_theo: "Thông tin chỉ số kèm theo",
  title_chinh_sua_thong_tin_ket_qua_xn_chuan: "Chỉnh sửa thông tin kết quả xét nghiệm chuẩn",

  text_loai_XN: "Loại XN",

  field_ten_ket_qua: "Tên kết quả",
  field_ten_ket_qua_en: "Tên kết quả (EN)",
  field_ten_excel: "Tên excel",
  field_STT_excel: "STT excel",
  field_thu_tu_in: "Thứ tự in",
  field_TB_tre_em: "TB trẻ em",
  title_quan_ly_phan_quyen: "Quản lý phân quyền",
  tab_Bao_cao_thu_thuat: "Báo cáo thủ thuật",
  phan_he_lam_viec: "Phân hệ làm việc",
  le_tan: "Lễ tân",
  Duoc_si: "Dược sĩ",
  mkt_leader: "Trưởng nhóm marketing",
  cskh_leader: "Trưởng nhóm cskh",
  cskh: "Chăm sóc khách hàng",

  noPermission: "Bạn không có quyền sử dụng chức năng này !",
  // noPermission: "Nạp VIP lần đầu đi",

  title_thong_tin_nguoi_dung: "Thông tin người dùng",
  noti_reload: "Thao tác này thay đổi phiên đăng nhập của bạn, vui lòng đăng nhập lại",
  sub_title_dm: "Quản trị danh mục, phân hệ, quản lý hệ thống, nhân viên...",
  sub_title_cis: "Quản lý hoạt động khám chữa bệnh phòng khám",
  sub_title_duoc_ngoai_tru: "Quản lý dược vật tư, tủ trực",
  sub_title_lis: "Quản lý phòng xét nghiệm, trung tâm xét nghiệm",
  sub_title_ris: "Quản lý trung tâm chẩn đoán hình ảnh",
  sub_title_prm: "Quản lý chăm sóc khách hàng, gia tăng doanh số",
  noti_khong_the_huy_thao_tac: "Bạn không thể hủy thực hiện thao tác này, vẫn tiếp tục ?",
  in_luot_kham: "In lượt khám",
  phong_kham_ve_tinh: "Phòng khám vệ tinh",
  ma_loai_xet_nghiem: "Mã loại XN",
  noti_da_ton_tai: "đã tồn tại",
  MA_THIET_BI: "Mã thiết bị",
  MA_MAY: "Mã máy",
  mo_hinh_cskcb: "Mô hình CSKCB",
  loai_hinh_cskcb: "Loại hình CSKCB",
  tham_do_chuc_nang: "Thăm dò chức năng",
  dich_vu_thu_thuat_phau_thuat: "Dịch vụ thủ thuật, phẫu thuật",
  dm_chung: "DM chung",
  duoc_ban_thuoc: "Dược ngoại trú/Bán thuốc",
  qlch: "Quản lý cấu hình",

  mo_hinh_CSKCB: "Mô hình CSKCB",
  mo_hinh_chuoi: "Mô hình chuỗi",
  mo_hinh_HUB: "Mô hình HUB",
  mo_hinh_mot_phong_kham: "Mô hình một phòng khám",
  quan_ly_hoa_hong: "Quản lý hoa hồng",
  dm_doi_tuong_nguoi_dung: "DM đối tượng người dùng",
  nhom_doi_tuong_nguoi_dung: "nhóm đối tượng người dùng",
  hoa_hong: "Hoa hồng",
  nguon_chi_dinh: "Nguồn chỉ định",

  tim_kiem: "Tìm kiếm",
  loai_phong_kham: "Loại phòng khám",
  pk_chuyen_khoa: "Chuyên khoa",
  pk_da_khoa: "Đa khoa",
  title_pop_up_thong_bao: 'Thông báo',
  message_thong_bao: 'Bạn có muốn đồng bộ dịch vụ với phòng khám trung tâm không?',
  noi_soi_tieu_hoa: 'Nội soi tiêu hoá',
  dien_tim: 'Điện tim',
  noi_soi_tai_mui_hong: 'Nội soi tai mũi họng',
  dien_nao: 'Điện não',
  soi_co_tu_cung: 'Soi cổ tử cung',
  phuc_hoi_chuc_nang: 'Phục hồi chức năng',
  vi_sinh: 'Vi sinh',
  sinh_hoc_phan_tu: 'Sinh học phân tử',
  te_bao: 'Tế bào',
  nuoc_tieu: 'Nước tiểu',
  huyet_hoc: 'Huyết học',
  sinh_hoa: 'Sinh hoá',
  giai_phau_benh: 'Giải phẫu bệnh',
  sieu_am:'Siêu âm',
  CT: 'CT',
  xquang: 'Xquang',
  chup_MRI: 'Chụp MRI (Cộng hưởng từ)',
  mamo: 'Mamo',
  loang_xuong: 'Loãng xương',
  bao_cao_thong_ke: "Báo cáo, thống kê",
  sub_title_bao_cao: "Quản lý báo cáo, thống kê các hoạt động KCB",
  merge: 'Gộp',
  detach: 'Tách',
  noti_xac_nhan_thay_doi: "Bạn có xác nhận thay đổi không ?",
  noti_existed_email: 'Email đã tồn tại',

  noti_lock_service: 'Bạn không thể chỉ định dịch vụ sau khi tắt dịch vụ, bạn có chắc chắn muốn tắt dịch vụ không ?',
  noti_delete_service1: 'Có một lịch hẹn đang sử dụng dịch vụ, thông tin dịch vụ trong cuộc hẹn ',
  noti_delete_service2: 'sẽ biến mất',
  noti_delete_service3: 'Sau khi xoá, thông tin dịch vụ ',
  noti_delete_service4: 'không thể khôi phục',
  noti_delete_service5: '. Thông tin lịch sử sử dụng dịch vụ vẫn được giữ. Bạn có chắc chắn muốn tiếp tục xoá dịch vụ không ?',
  noti_delete_service5_1: "Bạn có chắc chắn muốn tiếp tục xoá dịch vụ không ?",

  dm_dv_pk: 'DM dịch vụ Phòng khám',
  dm_pk_vt: 'DM Phòng khám vệ tinh',
  dm_pk_tt: 'DM Phòng khám trung tâm',
  chon_pk_vt: 'Chọn PK vệ tinh',

  dm_loai_goi_dv: "DM loại gói dịch vụ",
  loai_goi_dv: "Loại gói dịch vụ",
  loai_goi_dv_lower: "loại gói dịch vụ",
  ma_goi_dv: "Mã loại gói",
  ten_goi_dv: "Tên loại gói",

  noti_delete_applied_commission_1: 'Cơ chế hoa hồng đã được áp dụng cho đối tượng',
  noti_delete_applied_commission_2: 'Sau khi xoá, thông tin cơ chế hoa hồng',
  noti_delete_applied_commission_3: ' không thể khôi phục.',
  noti_delete_applied_commission_4: 'Báo cáo hoa hồng dịch vụ vẫn được giữ. Bạn có chắc chắn muốn tiếp tục xoá cơ chế hoa hồng không?',

  noti_delete_applied_exemption_1: 'Cơ chế miễn giảm đã được áp dụng cho đối tượng',
  noti_delete_applied_exemption_2: 'Sau khi xoá, thông tin cơ chế miễn giảm',
  noti_delete_applied_exemption_3: ' không thể khôi phục.',
  noti_delete_applied_exemption_4: 'Báo cáo miễn giảm dịch vụ vẫn được giữ. Bạn có chắc chắn muốn tiếp tục xoá cơ chế miễn giảm không?',

  pl_update_exemption:"Vui lòng cập nhật cơ chế miễn giảm",
  already_duplicated:'Đã bị trùng, hãy chọn lại',

  normal_index: "Chỉ số thường",
  index_information:'Thông tin chỉ số',
  index_group: "Nhóm chỉ số",
  option_value: 'Giá trị lựa chọn',
  index_type: 'Kiểu chỉ số',
  decimal: 'Số thập phân',
  integer: 'Số nguyên',
  singleChoice: 'Một lựa chọn',
  multipleChoice: 'Nhiều lựa chọn',
  textInput: 'Nhập văn bản',
  choiceName: 'Tên lựa chọn',
  serviceNameTT13: 'Tên dịch vụ theo TT13',
  serviceCodeTT13: 'Mã dịch vụ theo TT13',
  underOneYearOld: 'Dưới 1 tuổi',
  oneToTwoYearsOld:"1-2 tuổi",
  twoToSixYearsOld:"2-6 tuổi",
  sixToTenYearsOld: "6-15 tuổi",
  overTenYearsOld:"Trên 15 tuổi",
  overSixtyYearsOld: "Trên 60 tuổi",
  createNewContinuously :'Tạo mới liên tục',
  addNewSubscript: 'Thêm mới chỉ số con',
  referenceIndex: 'Chỉ số tham chiếu',
  messageErrorAddNewDV: 'Vui lòng nhập đủ các trường dữ liệu bắt buộc.',
  listOfAttachedStats: 'Danh sách chỉ số kèm theo',
  pleaseAddTheAttachedIndex :'Vui lòng thêm chỉ số kèm theo',
  cf_lock_xn_dv:'Bạn không thể chỉ định dịch vụ sau khi khóa dịch vụ, bạn có chắc chắn muốn khóa dịch vụ không?',
  noti_delete_applied_DvXn_1:'Có một lịch hẹn đang sử dụng dịch vụ, thông tin dịch vụ trong cuộc hẹn ',
  cf_lock_xn_dv_dvcon:'Bạn có chắc chắn khóa chỉ số này không?',
  cf_delete_xn_dv_dvcon: 'Bạn có chắc chắn xóa chỉ số này không?',
  ma_chi_so: 'Mã chỉ số',
  warning_can_duoi: 'Giá trị nhỏ hơn hoặc bằng cận trên',
  warning_can_tren: 'Giá lớn hơn hoặc bằng cận dưới',
  default_value: 'Giá trị mặc định',
  warning_decimal_or_zero:  'Vui lòng nhập số thập phân hợp lệ hoặc số 0',
  warning_integer:  'Vui lòng nhập một số nguyên hợp lệ!',
  create_subscript_translation_with_success : "Tạo chỉ số kèm theo thành công",
  create_a_successful_service: 'Tạo dịch vụ thành thông',
  noti_error_create_fail : "Tạo mới thất bại",
  

  tiep_theo: 'Tiếp theo',
  exemption_management: 'Quản lý miễn giảm',
  add_new_exemption_mechanism: 'Thêm mới cơ chế miễn giảm',
  exemption_mechanism: 'Cơ chế miễn giảm',

  quan_ly_goi_dich_vu:"Quản lý gói dịch vụ",
  danh_sach_goi_dich_vu:"Danh sách gói dịch vụ",
  common_dang_ky:"Đăng ký",
  common_kich_hoat:"Kích hoạt",
  hoan_thanh_goi:"Hoàn thành gói",
  tam_ngung:"Tạm ngưng",
  in_file:"In file",
  ql_dang_ky_goi_dich_vu:"Quản lý đăng ký gói dịch vụ",
  ql_su_dung_goi_dich_vu:"Quản lý sử dụng gói dịch vụ",
  sao_chep:"Sao chép",
  quan_ly_cong_no:"Quản lý công nợ",
  quan_ly_tam_ung:"Quản lý tạm ứng",
  tao_phieu_thu_tam_ung:"Tạo phiếu thu tạm ứng",
  huy_phieu_thu_tam_ung:"Hủy phiếu thu tạm ứng",
  benh_pham:"Bệnh phẩm",
  quan_ly_khach_hang:"Quản lý khách hàng",
  khoa_kho_thuoc:"Khóa kho thuốc",
  tien_ich: 'Tiện ích',
  gioi_thieu_chung: 'Giới thiệu chung',
  app_mobicare: 'Ứng dụng PHR',
  sub_app_mobicare: 'Ứng dụng đặt hẹn, quản lý lịch hẹn, quản lý hồ sơ sức khỏe, dịch vụ tư vấn bác sĩ riêng',
  tro_ly_y_khoa_AI: 'Trợ lý y khoa AI',
  sub_title_tro_ly_y_khoa_AI: 'Trợ lý AI hỗ trợ chuyên môn cho đội ngũ nhân viên y tế trong KCB và CSKH',
  trung_sdt: "Số điện thoại đã được sử dụng",
  ton_tai_username_sdt: "Username hoặc sđt đã tồn tại",

  // PRM Sider
  roomCatalog: "Danh mục phòng",
  SMC_Catalog: "DM phòng SMC",
  customerCatalog: "Danh mục khách hàng",
  customerStatus: "Trạng thái khách hàng",
  relationship: "Mối quan hệ",
  potentialReview: "Đánh giá tiềm năng",
  career: "Ngành nghề",
  customerDemand: "Nhu cầu khách hàng",
  tagManagement: "Quản lý thẻ tag",
  businessCatalog: "Danh mục công việc",
  businessType: "Loại công việc",
  businessStatus: "Trạng thái công việc",
  appointmentStatus: "Trạng thái đặt hẹn",
  orderStatus: "Trạng thái đơn hàng",
  quoteStatus: "Trạng thái báo giá",
  contractStatus: "Trạng thái hợp đồng",
  debtStatus: "Trạng thái công nợ",
  med_HealthCare_Catalog: "Danh mục thuốc và CSSK",
  healthCareProductGroup: "Nhóm sản phẩm CSSK",
  healthCareProductCatalog: "DM Sản phẩm CSSK",
  config: "Cấu hình",
  configManagement: "Quản lý cấu hình tổng đài",
  feedbackConfigManagement: "Quản lý cấu hình phản hồi KH",
  employeeManagement: "Quản lý nhân viên",
  proWorkingSchedule: "Lịch làm việc chuyên môn",
  smcWorkingSchedule: "Lịch làm việc BP SMC",
  voucherManagement: "Quản lý voucher",

  // PRM Columns
  workDay: "Ngày làm việc",
  employeeList: "Danh sách nhân viên",
  statusCode: "Mã trạng thái",
  statusName: "Tên trạng thái",
  relationshipCode: "Mã mối quan hệ",
  relationshipName: "Tên mối quan hệ",
  statusMechanism: "Cơ chế trạng thái",
  careerCode: "Mã ngành nghề",
  careerName: "Tên ngành nghề",
  demandCode: "Mã nhu cầu",
  demandName: "Tên nhu cầu",
  tagName: "Tên thẻ tag",
  businessCode: "Mã công việc",
  businessName: "Tên công việc",
  level: "Mức độ",
  warningLimit: "Hạn mức cảnh báo",
  limitedTime: "Thời gian hạn mức",
  act: "Hành động",
  ext: "Số máy lẻ",
  part: "Bộ phận",
  productGroup: "Nhóm sản phẩm",
  specifications: "Quy cách",
  formCode: "Mã form",
  feedbackFormName: "Tên form phản hồi",
  latestEditor: "Người sửa cuối",
  latestEditedDate: "Ngày sửa cuối",
  voucherCode: "Mã voucher",
  voucherName: "Tên voucher",
  voucherType: "Loại voucher",
  duration: "Thời hạn",
  voucherContent: "Nội dung voucher",
  appliedClinic: "Cơ sở áp dụng",
  waiting_to_apply: "Chờ áp dụng",
  applying: "Đang áp dụng",
  expired: "Hết hạn",

  //PRM Header
  smcRoom: "Phòng SMC",
  smc_room: "phòng SMC",
  customer_status: "trạng thái khách hàng",
  cate_relationship: "mối quan hệ",
  potential: "tiềm năng",
  cate_career: "ngành nghề",
  customer_demand: "nhu cầu khách hàng",
  tag: "thẻ tag",
  business_type: "loại công việc",
  business_status: "trạng thái công việc",
  appointment_status: "trạng thái đặt hẹn",
  order_status: "trạng thái đơn hàng",
  quote_status: "trạng thái báo giá",
  contract_status: "trạng thái hợp đồng",
  debt_status: "trạng thái công nợ",
  switch_config: "cấu hình tổng đài",
  feedbackFormManagement: "Quản lý form phản hồi KH",
  survey_form: "form khảo sát",
  healthcare_group: "nhóm CSSK",
  healthcareProduct: "Sản phẩm CSSK",
  healthcare_product: "sản phẩm CSSK",

  // RRM Form
  refValue: "Giá trị quy chiếu",
  businessTypeCode: "Mã loại công việc",
  businessTypeName: "Tên loại công việc",
  businessStatusName: "Tên trạng thái công việc",
  appointmentStatusName: "Tên trạng thái đặt hẹn",
  orderStatusName: "Tên trạng thái đơn hàng",
  quoteStatusName: "Tên trạng thái báo giá",
  contractStatusName: "Tên trạng thái hợp đồng",
  businessStatusCode: "Mã trạng thái công việc",
  appointmentStatusCode: "Mã trạng thái đặt hẹn",
  orderStatusCode: "Mã trạng thái đơn hàng",
  quoteStatusCode: "Mã trạng thái báo giá",
  contractStatusCode: "Mã trạng thái hợp đồng",
  levelName: "Tên mức độ",
  message: "Nhắn tin",
  call: "Gọi điện",
  wholeWeek: "Cả tuần",
  revenue: "Doanh thu",
  examNumber: "Số lượt khám",
  viewList: "Xem danh sách",
  healthcare_group_name: "Tên nhóm CSSK",
  pleaseSelectPart: "Vui lòng chọn bộ phận !",
  productCode: "Mã sản phẩm",
  generalInfo: "Thông tin chung",
  preview: "Bản xem trước",
  title: "Tiêu đề",
  questionList: "Danh sách câu hỏi",
  questionType: "Loại câu hỏi",
  content: "Nội dung",
  addQuestion: "Thêm câu hỏi",
  addOption: "Thêm lựa chọn",
  option: "Lựa chọn",
  convertPoint: "Quy đổi điểm",
  select1answ: "Chọn 1 đáp án",
  selectMultiAnsw: "Chọn nhiều đáp án",
  shortAnsw: "Câu trả lời ngắn",
  paragraph: "Đoạn văn",
  please_add_question: "Vui lòng thêm câu hỏi",
  please_choose_suitable_answ: "Vui lòng chọn đáp án phù hợp !",
  employeeInfo: "Thông tin nhân viên",
  workShift: "Ca làm việc",
  apply_to_all_week_of_month: "Áp dụng cho tất cả các tuần trong tháng",
  duplicateShift: "Ca làm việc bị trùng",
  voucherInfo: "Thông tin voucher",
  applyTime: "Thời gian áp dụng",
  service_discount_percent: "Giảm % dịch vụ",
  service_discount_amount: "Giảm tiền dịch vụ",
  bill_discount_percent: "Giảm % hóa đơn",
  bill_discount_amount: "Giảm tiền hóa đơn",
  service_gift: "Tặng kèm dịch vụ",
  identification: "Định danh",
  voucherIdentifier: "Định danh mã voucher",
  regular: "Bình thường",
  servicePackage: "Gói dịch vụ",
  priceType: "Loại giá",
  discount: "Ưu đãi",
  discountPrice: "Giá ưu đãi",
  clinic_apply: "Nơi áp dụng",
  condition_apply: "Điều kiện áp dụng",
  discountContent: "Nội dung ưu đãi",
  service_based_discount: "Giảm giá theo dịch vụ",
  invoice_based_discount: "Giảm giá theo hóa đơn",
  seeDetail: "Xem chi tiết",
  //

  signature_name: "Tên chữ ký",
  resident_doctor: "Bác sĩ nội trú",
  doctor: "Bác sĩ",
  bachelor: "Cử nhân",
  engineer: "Kỹ sư",
  can_do:"Cân đo",
  
  tool_tip_quan_tri: 'Quản trị danh mục, phân hệ',
  tool_tip_cis: 'Quản lý hoạt động khám chữa bệnh',
  tool_tip_lis: 'Quản lý phòng xét nghiệm',
  tool_tip_ris: 'Quản lý phòng CĐHA',
  tool_tip_prm: 'Quản lý chăm sóc khách hàng',
  tool_tip_duoc: 'Quản lý dược ngoại trú',
  tool_tip_bao_cao: 'Quản lý báo cáo, thông kê',
  mess_error_field_Loai_chi_so: 'Vui lòng chọn loại chỉ số',
  mess_error_field_Loai_chi_vu: 'Vui lòng chọn loại dịch vụ',
  thuc_hien_can_lam_sang: "Thực hiện cận lâm sàng",
  dm_nhom_dv_thuong_dung: "DM Nhóm dịch vụ\nthường dùng",
  nhom_dv_thuong_dung: "Nhóm dịch vụ thường dùng",
  ten_nhom_dv_thuong_dung: "Tên nhóm dịch vụ thường dùng",
  nam:"Nam",
  nu:"Nữ",
}

export default vi;
