import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dsPhongKham: [],
  pagination: {},
};

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    /**
     *
     * @param {typeof initialState} state
     * @param {{payload : payloadSetDataPhongKham}} action
     */
    setDataPhongKham: (state, action) => {
      let { payload } = action;
      state.dsPhongKham = payload.dsPhongKham;
      state.pagination = payload.pagination;
    },
    resetSlice: () => initialState,
  },
});

export const CustomerActions = customerSlice.actions;
/**
 * 
 * @param {*} state 
 * @returns {typeof initialState}
 */
export const getCustomers = (state) => state.customers;

export default customerSlice.reducer;

/**
 * @typedef payloadSetDataPhongKham
 * @property {Array<ThongTinPhongKham>} dsPhongKham
 * @property {Pagination} pagination
 */
/**
 * @typedef Pagination
 * @property {number} total
 * @property {number} currentPage
 */
/**
* @typedef ThongTinPhongKham
* @property {string}  ID 
* @property {string}  partner_code 
* @property {string}  TEN_CSKCB 
* @property {string}  EMAIL 
* @property {string}  SO_DIEN_THOAI 
* @property {string}  MA_TINH_THANH 
* @property {string}  TEN_TINH_THANH 
* @property {string}  MA_QUAN_HUYEN 
* @property {string}  TEN_QUAN_HUYEN 
* @property {string}  MA_PHUONG_XA 
* @property {string}  TEN_PHUONG_XA 
* @property {string}  DIA_CHI_CHI_TIET 
* @property {object}  LOAI_HINH_CSKCB 
* @property {string}  MO_HINH_CSKCB 
* @property {string}  WEBSITE 
* @property {object}  NGAY_KHOI_TAO 
* @property {number}  KHOA 
* @property {string}  TRANG_THAI 
* @property {object}  NHAN_VIEN_TRIEN_KHAI_ID 
* @property {object}  NHAN_VIEN_CHAM_SOC_ID 
*/