import { notification } from "antd";
import { put } from "redux-saga/effects";
import { exportToCSV, getErrorMessage } from "../../../helpers.js";
import { setFormLoading, setLoadingTable } from "../../slices/DM_Chung/DmMain.js";

export function* handleCommonGet(payload, apiFunction, getSuccess) {
    try {
        
        yield put(setLoadingTable(true))
        const res = yield apiFunction(payload)
        if (!!res) {
            if (res.status === "OK") {
                yield put(getSuccess(res))
            } else {
                let error_msg = getErrorMessage(
                    res.error_code,
                    "Lấy danh sách thất bại"
                );
                notification.error({
                    message: error_msg,
                    placement: "bottomLeft",
                });
            }
        } else {
            notification.error({
                message: "Lấy danh sách thất bại",
                placement: "bottomLeft",
            })
        }
    } catch (e) {
        notification.error({
            message:"Lấy danh sách thất bại",
            placement: "bottomLeft",
        })
    } finally {
        yield put(setLoadingTable(false)) 
    }
}

export function* handleCommonAdd(payload, apiFunction, reloadApi) {
    try {
        yield put(setFormLoading(true))
        const res = yield apiFunction(payload.add)
        if (!!res) {
            if (res.status === "OK") {
                notification.success({
                    message: "Thêm mới thành công",
                    placement: "bottomLeft",
                });
                if(reloadApi)
                    yield put(reloadApi(payload.get))
            } else {
                let error_msg = getErrorMessage(
                    res.error_code,
                   "Thêm mới thất bại"
                );
                notification.error({
                    message: error_msg,
                    placement: "bottomLeft",
                });
            }
        } else {
            notification.error({
                message:  "Thêm mới thất bại",
                placement: "bottomLeft",
            })
        }
    } catch (e) {
    } finally {
        yield put(setFormLoading(false))
    }
}

export function* handleCommonEdit(payload, apiFunction, reloadApi) {
    try {
        yield put(setFormLoading(true))
        const res = yield apiFunction(payload.edit)
        if (!!res) {
            if (res.status === "OK") {
                notification.success({
                    message: "Cập nhật thành công",
                    placement: "bottomLeft",
                });
                
                if(reloadApi)
                    yield put(reloadApi(payload.get))
            } else {
                let error_msg = getErrorMessage(
                    res.error_code,
                    "Cập nhật thất bại",
                );
                notification.error({
                    message: error_msg,
                    placement: "bottomLeft",
                });
            }
        } else {
            notification.error({
                message:  "Cập nhật thất bại",
                placement: "bottomLeft",
            })
        }
    } catch (e) {
    } finally {
        yield put(setFormLoading(false))
    }
}

export function* handleCommonExport(payload, apiFunction, fileName) {
    try {
        const data = yield apiFunction(payload)
        if (!!data.result) {
            exportToCSV(data.result, fileName).then(() => {
                notification.success({
                    key: "noti_export_key",
                    message: "Xuất danh sách thành công",
                    placement: "bottomLeft",
                });
            });
        } else {
            notification.error({
                key: "noti_export_key",
                message:"Xuất danh sách thất bại",
                placement: "bottomLeft",
            });
        }
    } catch (e) {
    } finally {

    }
}

export function* handleCommonImport(payload, apiFunction, reloadApi) {
    try {
        yield put(setFormLoading(true))
        const res = yield apiFunction(payload.import)
        if (res.status === "OK") {
            notification.success({
                message: "Nhập dữ liệu thành công",
                placement: "bottomLeft",
            });
            if(reloadApi)
                yield put(reloadApi(payload.get))
        } else if (res.status === "KO") {
            notification.error({
                message: getErrorMessage(res.error_code,"Nhập dữ liệu thất bại", res),
                placement: "bottomLeft",
            });
        } else {
            notification.error({
                message: "Nhập dữ liệu thất bại",
                placement: "bottomLeft",
            });
        }
    } catch (e) {
        console.log(e);
        notification.error({
            message: "Nhập dữ liệu thất bại",
            placement: "bottomLeft",
        });
    } finally {
        yield put(setFormLoading(false))
    }
}

export function* handleCommonDelete(payload, apiFunction, reloadApi) {
    try {

        yield put(setFormLoading(true))
        const res = yield apiFunction(payload.delete)
        if (!!res) {
            if (res.status == "OK") {
                notification.success({
                    message: "Xoá thành công",
                    placement: "bottomLeft",
                });
                if(reloadApi)
                    yield put(reloadApi(payload.get))
            } else {

                let error_msg = getErrorMessage(
                    res?.error_code ||res?.error,
                    "Xoá thất bại"
                );
                notification.error({
                    message: res.message,
                    placement: "bottomLeft",
                });
                notification.error({
                    message: error_msg,
                    placement: "bottomLeft",
                });
            }
        } else {
            notification.error({
                message: "Xoá thất bại",
                placement: "bottomLeft",
            })
        }
    } catch (e) {
    } finally {
        yield put(setFormLoading(false))
    }
}

export function* handleCommonLock(payload, apiFunction, reloadApi, updateData) {
    try {
        yield put(setFormLoading(true))
        const res = yield apiFunction(payload)
        if (!!res) {
            if (res.status === "OK") {
                notification.success({
                    message: "Cập nhật thành công",
                    placement: "bottomLeft",
                });
               if ( payload.get) {
                yield put(reloadApi(payload.get))

               } else {
                yield put(updateData({
                    isLock: payload.isKhoa !== undefined ? payload.isKhoa : payload.KHOA,
                    id: payload.listId || payload.arr_ID ||payload.ID || payload.arr_KHOA_ID,
                }))
               }
                    
            } else {
                let error_msg = getErrorMessage(
                    res.error_code,
                    "Cập nhật thất bại"
                );
                // if (!!res.field) {
                //     res.field.toUpperCase().includes("DM")
                //     ? error_msg += `: ${i18n.t(languageKeys.cate_Danh_muc)} ${i18n.t(keysCatalog()[res.field].i18nKeyLower)}`
                //     : error_msg += `: ${i18n.t(keysCatalog()[res.field].i18nKeyLower)}`
                // }
                notification.error({
                    message: error_msg,
                    placement: "bottomLeft",
                });
            }
        } else {
            notification.error({
                message: "Cập nhật thất bại",
                placement: "bottomLeft",
            })
        }
    } catch (e) {
    } finally {
        yield put(setFormLoading(false))
    }
}

