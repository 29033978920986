import axios from "axios";

import apiServices from "../config/apiServices";
import webStorage from "../utils/webStorage";
import Cookies from "js-cookie";


const mainInstance = axios.create({
  baseURL: apiServices.URL_CLINIC40_API_1,
  timeout: 600000,
});

export { mainInstance };

export const setupInterceptor = (_store) => {
  mainInstance.interceptors.request.use(
    (config) => {
      if (window.CIS_WEB_UI_URL?.includes('localhost')) ;
      const accessToken = webStorage.getToken();
      if (config.headers && accessToken) {
        config.headers["authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  )

  mainInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {

      if (!error.response) {
        // Network error
        return Promise.reject({ status: "KO", message: "Network Error" });
      }
      
      if (error.response && error.response.status === 401) {
       
      }
      return Promise.reject(error);
    },
  );
};

export const common_get = (url, params) => mainInstance.get(url, { params }).then((res) => res);

export const common_post = (url, params, config) => mainInstance.post(url, params, config).then((res) => {
  // if (res.data && res.data?.status === "OK") {
    return res.data
  // }
  // console.log(`2-----res.data`,res.data );
  // return Promise.reject(res.data)
});


export const common_post_with_error = async (url , params, config) => {
  try{
    const response = await mainInstance.post(url, params, config);
    if (response?.data.status === "OK") {
      return response.data;
    }
    throw response.data;
  }catch(error) {
    throw error;
  }
}

export const common_put = (url, params) => mainInstance.put(url, params).then((res) => res);

export const common_patch = (url, params) => mainInstance.patch(url, params).then((res) => res);

export const common_delete = (url, params) => mainInstance.delete(url, { data: params }).then((res) => res);
