import TableCustom from "../../../../components/TableCustom";
import { Tooltip, notification } from "antd";
import React, { useState, useEffect, useRef } from "react";
import HeaderClinic from "./Header";
import ActionClinic from "./ActionClinic";
import { common_post } from "../../../../services/apiInstances";
import { ENDPOINT } from "../../../../services/endPoint";
import { useDispatch, useSelector } from "react-redux";
import { ClinicDrawer } from "./Components";
import { MO_HINH_CSKCB, TRANG_THAI_PK } from "../../../../constants/data";
import {
  CustomerActions,
  getCustomers,
} from "../../../../duck/slices/slices/customer.slice";
import { ConfirmModal } from "../../../../components";
import { Apis } from "../../../../constants";
import { checkResponse } from "../../../../helpers";

const Clinic = () => {
  const dispatch = useDispatch();
  const confirmRef = useRef();
  const lockRef = useRef();
  const { pagination } = useSelector(getCustomers);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const editRef = React.useRef();

  useEffect(() => {
    getData({});
    return () => {};
  }, []);

  const getData = async ({
    search_string = "",
    page = 1,
    limit = 20,
    filter_theo_trang_thai = [],
    filter_theo_mo_hinh = [],
  }) => {
    try {
      setLoading(true);
      const response = await common_post(ENDPOINT.layDsKhachHang, {
        search_string,
        page,
        limit,
        filter_theo_trang_thai,
        filter_theo_mo_hinh,
      });
      const { result, total, totalPage, currentPage } = response;
      setLoading(false);
      setData(result);
      dispatch(
        CustomerActions.setDataPhongKham({
          dsPhongKham: result,
          pagination: { total, currentPage },
        })
      );
      setCurrentPage(currentPage);
      setTotalPage(totalPage);
      setTotalResult(total);
    } catch (error) {}
  };

  const ellipsisProps = {
    ellipsis: true,
    render: (data) => {
      return (
        <Tooltip placement="topLeft" title={data} zIndex={1}>
          {data === "null" || data === "undefined" || !data ? "" : data}
        </Tooltip>
      );
    },
  };
  const columns = [
    {
      title: "Mã phòng khám",
      key: "partner_code",
      dataIndex: "partner_code",
      width: 140,
      ...ellipsisProps,
    },
    {
      title: "Tên phòng khám",
      key: "TEN_CSKCB",
      dataIndex: "TEN_CSKCB",
      width: 170,
      //render: (data, record) => getDiaChi(record),
      //width: 150,
    },
    {
      title: "Email",
      key: "EMAIL",
      dataIndex: "EMAIL",
      width: 150,
    },
    {
      title: "Số điện thoại",
      key: "SO_DIEN_THOAI",
      dataIndex: "SO_DIEN_THOAI",
      width: 150,
      ...ellipsisProps,
    },
    {
      title: "Địa chỉ",
      key: "DIA_CHI_CHI_TIET",
      dataIndex: "DIA_CHI_CHI_TIET",
      width: 150,
    },
    {
      title: "Mô hình CSKCB",
      key: "MO_HINH_CSKCB",
      dataIndex: "MO_HINH_CSKCB",
      width: 150,
      align: "center",
      render: (mo_hinh) => {
        const result = MO_HINH_CSKCB.find((item) => item.key === mo_hinh);
        return result ? result.name : "-";
      },
    },
    // {
    //   title: 'Hạn sử dụng',
    //   key: 'fieldPartner.EMAIL',
    //   dataIndex: fieldPartner.EMAIL,
    //   witdh:150,
    // },
    {
      title: "Đối tác",
      key: "tenant",
      dataIndex: "tenant",
      width: 150,
    },
    {
      title: "Trạng thái",
      key: "TRANG_THAI",
      dataIndex: "TRANG_THAI",
      width: 150,
      render: (TRANG_THAI, record, index) => (
        <span style={{ color: getColorStatus(TRANG_THAI) }}>
          {getTitleStatus(TRANG_THAI)}
        </span>
      ),
    },
    {
      title: "Thao tác",
      key: "action_btn",
      fixed: "right",
      width: 160,
      render: (_, record) => (
        <>
          <ActionClinic
            record={record}
            onEdit={onClickRecord}
            onAfterSubmit={() => getData(filterData)}
            onOpenConfirm={handleOpenConfirm}
          />
        </>
      ),
    },
  ];

  const confirmLoading = (bool) => {
    try {
      confirmRef.current.loading(bool)
      lockRef.current.loading(bool)
    } catch (error) {
      
    }
  }

  const handleOpenConfirm = (data, bool) => {
    try {
      bool ? lockRef.current.open({ arr_ID: data, isLock: bool })
       : confirmRef.current.open({ arr_ID: data, isLock: bool })
    } catch (error) {
      console.log(error);
    }
  };

  const handleLockHospital = async ({ arr_ID = [], isLock = true }) => {
    confirmLoading(true)
    try {
      if (!arr_ID?.[0]) {
        arr_ID = [arr_ID];
      }
      let body = {
        list_partner_code: arr_ID,
        khoa: isLock,
      };
      let rs = await common_post(Apis.khoaKhachHang, body);
      if (checkResponse(rs)) {
        notification.success({ message: "Thao tác thành công" });

        if(isLock){
          
        setData((ls) => {
          return ls.map((c) => {
            let newD = { ...c };
            if (arr_ID.includes(c.partner_code)) {
              newD = { ...newD, KHOA: +isLock,TRANG_THAI:"TAM_DUNG" };
            }

            return newD;
          });
        });
        
        lockRef.current.close();
      } else {
        confirmRef.current.close();
        reloadList();
      }
        // return rs;
      } else {
        throw new Error(rs.message);
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: error.message });
    } finally {
      confirmLoading(false)
    }
  };

  const onClickRecord = (record) => {
    //console.log(record);
    editRef.current.open(record);
  };

  const onChangePage = (value) => {
    const payload = {
      ...filterData,
      page: currentPage + value,
    };
    setFilterData(payload);
    getData(payload);
  };

  const handleSearch = (value) => {
    const payload = {
      page: 1,
      filter_theo_mo_hinh: value?.filterType ? [value?.filterType] : [],
      filter_theo_trang_thai: value?.filterStatus ? [value?.filterStatus] : [],
      search_string: value?.searchString,
    };
    getData(payload);
    setFilterData(payload);
  };

  const reloadList = () => {
    getData({
      ...filterData,
      page: currentPage,
    });
  };

  return (
    <div className="clinic">
      <HeaderClinic
        onFilter={handleSearch}
        onAdd={() => editRef.current.open()}
      />
      <TableCustom
        columns={columns}
        dataSource={data}
        loading={loading}
        onClickRow={onClickRecord}
        currentPage={currentPage}
        totalResult={totalResult}
        rowKey={"ID"}
        pagination={{
          showPagination: true,
          showSizeChanger: true,
          onChangePageSize: (page, limit) => {
            getData({ ...filterData, page, limit });
          },
        }}
        limit={pagination?.limit}
        scroll={{
          y: "calc(100vh - 270px)",
          x:"max-content"
        }}
        onNext={() => onChangePage(1)}
        onPrev={() => onChangePage(-1)}
      />
      <ClinicDrawer ref={editRef} reloadList={reloadList} />
      <ConfirmModal
        ref={lockRef}
        content={
          "Bạn có chắc chắn muốn tạm dừng phòng khám này lại hay không ?"
        }
        onOk={handleLockHospital}
      />
      <ConfirmModal
        ref={confirmRef}
        content={
          "Bạn có chắc chắn muốn kích hoạt lại phòng khám này hay không ?"
        }
        onOk={handleLockHospital}
      />
    </div>
  );
};
export default Clinic;

export const fieldPartner = {
  TEN: "TEN",
  DIA_CHI_CHI_TIET: "DIA_CHI_CHI_TIET",
  LOAI_DOI_TAC: "LOAI_DOI_TAC",
  NGUOI_LIEN_HE: "NGUOI_LIEN_HE",
  SO_DIEN_THOAI: "SO_DIEN_THOAI",
  EMAIL: "EMAIL",
  DICH_VU: "DICH_VU",
  arr_chuyen_gia: "arr_chuyen_gia",
};

export const getColorStatus = (index) => {
  switch (index) {
    case "CHUA_KICH_HOAT":
      return "#4080FF";
    case "DA_KICH_HOAT":
      return "#2CB2A5";
    case "TAM_DUNG":
      return "#F6A447";
    case "DA_HUY":
      return "#FF3432";
    default:
      return "#4080FF";
  }
};
export const getTitleStatus = (index) => {
  switch (index) {
    case "CHUA_KICH_HOAT":
      return "Chưa kích hoạt";
    case "DA_KICH_HOAT":
      return "Đã kích hoạt";
    case "TAM_DUNG":
      return "Tạm dừng";
    case "DA_HUY":
      return "Đã hủy";
    default:
      return "Chưa kích hoạt";
  }
};
