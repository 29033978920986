import { put, takeLatest } from "redux-saga/effects";
import {
  handleCommonAdd,
  handleCommonDelete,
  handleCommonEdit,
  handleCommonExport,
  handleCommonGet,
  handleCommonImport,
  handleCommonLock,
} from "../commonSaga/commonSaga";
import {
  doCreateDmNhomDv,
  doDeleteDmNhomDv,
  doGetListDmNhomDv,
  doSetListDmNhomDv,
  doUpdateDmNhomDv,
  exportNhomDV,
  lockDmNhomDv,
  updateDmNhomDv,
  doImportNhomDv
} from "../../slices/DM_Chung/DmNhomDv";
import {
  dispatchAddDmNhomDv,
  dispatchDeleteDmNhomDv,
  dispatchGetListDmNhomDv,
  dispatchLockDmNhomDv,
  dispatchUnLockDmNhomDv,
  dispatchUpdateDmNhomDv,
  exportNDV,
  nhapDanhMucNhomDv,
} from "./apiDM_Chung";
import { fieldDoiTuong } from "../../../pages/ClinicManagement/Main/Catalog/GroupCatalogs/GeneralCatalogries/fieldsCatalog.js";
import { mappingDataExcel } from "../../../constants/data.js";
import { convertKeys } from "../../../helpers.js";

export function* watchDoDmNhomDv() {
  yield takeLatest(doGetListDmNhomDv, handleGetListDmNhomDv);
  yield takeLatest(doCreateDmNhomDv, handleCreateDmNhomDv);
  yield takeLatest(lockDmNhomDv, handleLockDmNhomDv);
  yield takeLatest(doDeleteDmNhomDv, handleDeleteDmNhomDv);
  yield takeLatest(doUpdateDmNhomDv, handleUpdateDmNhomDv);
  yield takeLatest(exportNhomDV, handleExportDmNhomDv);
  yield takeLatest(doImportNhomDv, handleImportNhomDv);
}

function* handleGetListDmNhomDv(action) {
  try {
    const info = action.payload;

    yield handleCommonGet(
      { ...info },
      dispatchGetListDmNhomDv,
      doSetListDmNhomDv
    );
  } catch (error) {}
}

function* handleCreateDmNhomDv(action) {
  const info = action.payload;
  yield handleCommonAdd(
    {
      add: {
        data:[{...info.add.data}],
        BENH_VIEN_ID:info.add.data.BENH_VIEN_ID,
        partner_code:info.add.data.partner_code
      },
      get: info.get,
    },
    dispatchAddDmNhomDv,
    doGetListDmNhomDv
  );
  //   yield common_post(Apis.themDmNhomDv, { ...info });
}

function* handleUpdateDmNhomDv(action) {
  const info = action.payload;

  yield handleCommonEdit(
    {
      edit: {
        ...info.edit.data,
        NHOM_DV_ID:info.edit.data.ID
      },
      get: info.get,
    },
    dispatchUpdateDmNhomDv,
    doGetListDmNhomDv
  );
}

function* handleLockDmNhomDv(action) {
  const info = action.payload;

  yield handleCommonLock(
    {
      listId: info.data,
      KHOA: info.lockVal,
      get: info.get,
      ...info,
    },
    info.KHOA ? dispatchLockDmNhomDv : dispatchUnLockDmNhomDv,
    doGetListDmNhomDv,
    updateDmNhomDv
  );
}

function* handleDeleteDmNhomDv(action) {
  const info = action.payload;

  yield handleCommonDelete(
    {
      delete: {
        listId: Array.isArray(info.delete.data)
          ? info.delete.data.map((item) => item[fieldDoiTuong.id])
          : [info.delete.data[fieldDoiTuong.id]],
        partner_code: info.delete.partner_code,
        BENH_VIEN_ID: info.delete.BENH_VIEN_ID,
      },
      get: info.get,
    },
    dispatchDeleteDmNhomDv,
    doGetListDmNhomDv
  );
}




function* handleExportDmNhomDv(action) {
  const info = action.payload
  yield handleCommonExport(
      {
          template: 0,
          partner_code: info.partner_code,
          BENH_VIEN_ID: info.BENH_VIEN_ID,
      },
      exportNDV,
      info.name,
  )
}

function* handleImportNhomDv(action) {
  const info = action.payload;
  info.data = convertKeys(info.data, mappingDataExcel,['Nhóm chi phí BHYT','Mã nhóm BHYT']);
  // console.log(info.data);return
  let getPayload = !!(info && info.reload)
    ? {
        ...info.reload,
        partner_code: info.partner_code,
        BENH_VIEN_ID: info.BENH_VIEN_ID,
      }
    : { partner_code: info.partner_code, BENH_VIEN_ID: info.BENH_VIEN_ID };
  yield handleCommonImport(
    {
      template: 0,
      partner_code: info.partner_code,
      BENH_VIEN_ID: info.BENH_VIEN_ID,
      limit: 999,
      page: 1,
      import: info,
      get: getPayload,
    },
    nhapDanhMucNhomDv,
    doGetListDmNhomDv
  );
}
