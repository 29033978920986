import React, { useEffect, useState } from 'react';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button } from 'antd';
import { CommonSelect } from '../../../../components/CommonSelect';
import { MO_HINH_CSKCB, TRANG_THAI_PK } from '../../../../constants/data';
const HeaderClinic = ({
    onFilter = () => { },
    onAdd = () => {},

}) => {
    const [searchString, setSearchString] = useState('');
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterType, setFilterType] = useState(null);

    useEffect(() => {
       if (searchString || filterStatus || filterType) {
            onFilter({
                searchString,
                filterStatus,
                filterType
            })
       }
    }, [searchString, filterStatus, filterType])


    return (
        <div className='clinic__header'>
            <Row justify="space-between">
                <Col>
                    <h1>Quản lý phòng khám</h1>
                </Col>
                <Col>
                    <Row>
                        <Input className='clinic__header__search'
                            placeholder='Tìm kiếm'
                            prefix={<SearchOutlined style={{ color: '#4080FF', width: 20 }} />}
                            onPressEnter={(e) => {
                                setSearchString(e.target.value);
                            }}
                        />

                    </Row>
                </Col>
            </Row>

            <Row justify="space-between" style={{ marginTop : "10px"}}>
                <Col flex={"auto"}>
                    <Row gutter={10}>
                        <Col>
                        <CommonSelect
                            dataSource={MO_HINH_CSKCB}
                            placeholder='Loại hình CSKCB'
                            labelKey='name'
                            valueKey= 'key'
                            onSelect={(value) => {
                                setFilterType(value.key)
                            }}
                            onClear={e => {
                                onFilter({
                                    searchString,
                                    filterStatus,
                                    filterType:null
                                })
                            }}
                        />
                        </Col>
                        <Col>
                        <CommonSelect
                            dataSource={TRANG_THAI_PK}
                            labelKey='name'
                            valueKey= 'key'
                            placeholder='Trạng thái'
                            onSelect={(value) => {
                                setFilterStatus(value.key)
                            }}
                            onClear={e => {
                                onFilter({
                                    searchString,
                                    filterStatus: null,
                                    filterType
                                })
                            }}
                            /></Col>
                    </Row>
                    
                </Col>
                <Col>
                    <Button icon = {<PlusOutlined />} type='primary' onClick={() => onAdd()}>
                        Thêm mới 
                    </Button>
                </Col>
            </Row>
        </div>
    )

}

export default React.memo(HeaderClinic)