import { createSlice } from "@reduxjs/toolkit";

const initState = {
    data: {},
    reload:""
};

const dmLoaiDvSlice = createSlice({
    name: "dmLoaiDv",
    initialState: initState,
    reducers: {
        doGetListDmLoaiDv: () => {console.log("doGetListDmLoaiDv");},
        doCreateDmLoaiDv: () => {console.log("doCreateDmLoaiDv");},
        doUpdateDmLoaiDv: () =>{console.log("doUpdateDmLoaiDv");},
        doDeleteDmLoaiDv: () => {console.log("doDeleteDmLoaiDv");},
        doExportDmLoaiDv: () => {},
        doImportDmLoaiDv : () => {},

        doSetListDmLoaiDv: (state,action) => {
            state.data = action.payload;
        },
        
        lockDmLoaiDv: () => {},
        updateLoaiDv: (state, action) => {

            const updateLock = state.data.result.findIndex((id) => id.ID == action.payload.id[0].toString() )
            state.data.result[updateLock].KHOA = action.payload.isLock
        },


    },
});

export const {doImportDmLoaiDv,doGetListDmLoaiDv,doExportDmLoaiDv, doCreateDmLoaiDv, doUpdateDmLoaiDv, doSetListDmLoaiDv, lockDmLoaiDv, updateLoaiDv, doDeleteDmLoaiDv} = dmLoaiDvSlice.actions
export const DataDmLoaiDv = (state) => state.DM_Chung.DmLoaiDv
export default dmLoaiDvSlice.reducer;