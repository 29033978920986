import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  hospitalInfo:null,
};

const hospitalInfoSlice = createSlice({
  name: 'hospitalInfo',
  initialState,
  reducers: {
    doUpdateHospital : () => {},
    setHospitalInfo : (state,action) => {
      let payload = {...action.payload}
      if('NHAN_VIEN_MAC_DINH_VIEN_ID' in payload) {
        payload.NHANSU_ID = payload.NHAN_VIEN_MAC_DINH_VIEN_ID
      }
      state.hospitalInfo = payload;
    },
  },

});

const { reducer } = hospitalInfoSlice;
export const { setHospitalInfo, doUpdateHospital } = hospitalInfoSlice.actions;
export default reducer;
