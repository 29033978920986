import { common_post } from "../apiInstances";
import { ENDPOINT } from "../endPoint";

export const layDSChuyenKhoa = (dataRequest) => common_post(ENDPOINT.layDSChuyenKhoa, dataRequest);
export const luuKhoiTaoPhongKham = (dataRequest) => common_post(ENDPOINT.luuKhoiTaoPhongKham, dataRequest);
export const sendOTPEmail = (dataRequest) => common_post(ENDPOINT.sendOTPEmail, dataRequest);
export const verifyEmail = (dataRequest) => common_post(ENDPOINT.verifyEmail, dataRequest);
export const kiemTraPartnerCode = (dataRequest) => common_post(ENDPOINT.kiemTraPartnerCode, dataRequest);
export const kiemtraEmail = (dataRequest) => common_post(ENDPOINT.kiemtraEmail, dataRequest);
export const luuDangKyMoPhongKham = (dataRequest) => common_post(ENDPOINT.luuDangKyMoPhongKham, dataRequest);
// export const layChiTietKhachHang = (dataRequest) => common_post(ENDPOINT.layChiTietKhachHang, dataRequest);
export const layDsTinhThanh = (dataRequest) => common_post(ENDPOINT.layDSTinhThanh, dataRequest);
export const layDSQuanHuyen = (dataRequest) => common_post(ENDPOINT.layDSQuanHuyen, dataRequest);
export const layDSPhuongXa = (dataRequest) => common_post(ENDPOINT.layDSPhuongXa, dataRequest);
export const layDSDichVuCungCap = (dataRequest) => common_post(ENDPOINT.layDSDichVuCungCap, dataRequest);
export const layDSDichVuCLS = (dataRequest) => common_post(ENDPOINT.layDSDichVuCLS, dataRequest);
export const kiemTraTonTaiUsername = (dataRequest) => common_post(ENDPOINT.kiemTraTonTaiUsername, dataRequest);
export const layCauHinhBenhVien = (dataRequest) => common_post(ENDPOINT.layCauHinhBenhVien, dataRequest);