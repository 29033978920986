import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataDetail: null,
  loadingModal: false,
  dataXoaDvConKemTheo: [],
  dataDVT: [],
};

const DetailServicesSlice = createSlice({
  name: "DetailServicesSlice",
  initialState,
  reducers: {
    fecthDetailServices: (state,) => {
      state.loadingModal = true 
    },
    cancelFecthDetailServices: (state,) => {
      state.loadingModal = true 
    },

    fecthDetailServicesSuccess: (state, { payload }) => {
      state.loadingModal = false 
      state.dataDetail = payload.data 
    },

    fecthDetailServicesFail: (state, ) => {
      state.dataDetail = null
      state.loadingModal = false 
    },


    updateDetailServices: (state,) => {
      state.loading = true 
    },
    updateDetailServicesFalse: (state, ) => {
      state.loading = false 
    },

    setLoadingModal:  (state, { payload }) => {
      state.loadingModal = payload 
    },
    setDataDVT:  (state, { payload }) => {
      state.dataDVT = payload 
    },
    pushDataDVT:  (state, { payload }) => {

      if (payload.ID_DVT) {
        const currData = state.dataDVT
        const index = currData.findIndex(object => {
          return object.ID_DVT === payload.ID_DVT;
        })
  
        if (index == -1 ) {
          currData.push(payload)
          state.dataDVT = currData
        }
      }

    },
    setDataXoaDvConKemTheo:  (state, { payload }) => {
      state.dataXoaDvConKemTheo = payload 
    },
    
  },
});

export const {
  fecthDetailServices,
  fecthDetailServicesSuccess,
  fecthDetailServicesFail,
  updateDetailServices,
  updateDetailServicesFalse,
  setLoadingModal,
  cancelFecthDetailServices,
  setDataXoaDvConKemTheo,
  setDataDVT,
  pushDataDVT
} = DetailServicesSlice.actions;
export default DetailServicesSlice.reducer;
