import React, { useState } from 'react';
import { Button, Col, Row, Select as AntSelect, Typography } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { Input } from './Input/Input';
import _ from "lodash";
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';

const colors = {
  secondaryColor: '',
};

const filterOption = (input, option) => {
  return (
    option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option?.value?.toString().toLowerCase()?.indexOf(input.toLowerCase()) >= 0
  );
};

export const CommonSelect = ({
  dataSource = [],
  labelKey = '',
  valueKey = '',
  defaultValue = null,
  optionProps = {},
  isGetAll = false,
  showSearch = true,
  onSelect = () => {},
  tableProps = {
    visible: false,
    columns: [],
    width: 300,
    color: colors.secondaryColor,
  },
  addItemProps = {
    visible: false,
    onAddItem: () => {},
    textAddItem: '',
    styleAddItemExtra: {},
  },
  allowClear = true,
  onClear,
  labelSuffix = '-', // suffix nối các trường
  onComplicateLabel, // tùy chỉnh label  (result) => result (cần return sau khi tùy chỉnh)
  placeholder = 'Chọn',
  readOnly,
  fontWeight = 500,
  className="",
  selectType = 0,
  onDropdownVisibleChange = () => {},
  ...props
}) => {
  const isDropdownTable = _.isObject(tableProps) && tableProps.visible;
  const visibleAddItem = _.isObject(addItemProps) && addItemProps.visible;
  const [open, setOpen] = useState(false);

  const getLabel = (data, isLabelProp = false) => {
    // tùy chỉnh label chọn trường
    if (typeof data === 'string' && !!onComplicateLabel) {
      return onComplicateLabel(data);
    }

    if (!_.isObject(data)) return data;

    if (!labelKey) return JSON.stringify(data);
    if (isDropdownTable && !isLabelProp) {
      return (
        <Row align="middle" wrap={false}>
          {tableProps.columns.map((col) => (
            <Col
              key={nanoid()}
              style={{ width: col?.width, minWidth: col?.width, maxWidth: col?.width, textAlign: col?.align }}
            >
              <Typography.Text
                style={{ color: `${tableProps.color ? tableProps.color : colors.secondaryColor}` }}
                ellipsis={{ tooltip: data[col?.dataIndex] }}
              >
                {!!col.render ? col.render(data) : data[col?.dataIndex]}
              </Typography.Text>
            </Col>
          ))}
        </Row>
      );
    }

    // nối label hiển thị
    if (labelKey.includes(labelSuffix)) {
      let listKey = labelKey.split(labelSuffix);
      let result = ``;

      for (let i = 0; i < listKey.length; i++) {
        const key = listKey[i];
        result += `${data[key]} ${labelSuffix} `;
      }
      result = result.substring(0, result.length - 2).trim();

      if (!!onComplicateLabel) {
        result = onComplicateLabel(result);
      }

      return result;
    }
    return data[labelKey];
  };

  const getValue = (data) => {
    if (!_.isObject(data)) return data;
    return !!valueKey ? data[valueKey] : JSON.stringify(data);
  };

  const handleSelect = (value, optionInstance) => {

    if (!valueKey) return onSelect(value);
    onSelect(optionInstance.fullValue);
  };

  const options = dataSource.map((data) => (
    <AntSelect.Option key={nanoid()} value={getValue(data)} label={getLabel(data, true)} fullValue={data}>
      {getLabel(data)}
    </AntSelect.Option>
  ));

  const dropdownRender = (originNode) => {
    const defaultNode = (
      <>
        {originNode}

        {visibleAddItem && (
          <div>
            <Button
              type="link"
              icon={<PlusCircleFilled className="icon-add" />}
              onClick={() => {
                addItemProps?.onAddItem();
                handleDropdownChange(false);
              }}
            >
              {addItemProps.textAddItem || 'Nhập'}
            </Button>
          </div>
        )}
      </>
    );

    if (!isDropdownTable) {
      return defaultNode;
    }

    return (
      <>
        <Row align="middle" wrap={false}>
          {tableProps.columns.map((col, index) => (
            <Col key={index} flex={col?.width} className={classNames('col', index === 0 && 'first-col')}>
              {col?.title}
            </Col>
          ))}
        </Row>

        {defaultNode}
      </>
    );
  };

  const handleDropdownChange = (bool) => {
    setOpen(bool);
    onDropdownVisibleChange(bool);
  };
  const getTableWidth = () => {
    if (!!tableProps.width) return tableProps.width;
    return tableProps.columns.reduce((sum, col) => (sum += col.width), 0);
  };

  if (readOnly) return <Input readOnly />;
  return (
    <AntSelect
      defaultValue={defaultValue}
      placeholder={placeholder}
      className={classNames(`custom__select${selectType ? '-' + selectType : ''}`,className)}
      showSearch={showSearch}
      onSelect={handleSelect}
      optionLabelProp={isDropdownTable && 'label'}
      dropdownRender={dropdownRender}
      dropdownMatchSelectWidth={isDropdownTable ? getTableWidth() : undefined}
      allowClear={allowClear}
      onClear={onClear}
      filterOption={filterOption}
      onDropdownVisibleChange={handleDropdownChange}
      open={open}
      {...props}
    >
      {options}
    </AntSelect>
  );
};
