import { useKeycloak } from "@react-keycloak/web";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import webStorage from "../utils/webStorage";
/**
 *
 * @param {*} props
 * @returns {{handleLogout : Function, DefaultBtnLogout : import("react").ReactElement}}
 */
const useLogout = (props) => {
  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    webStorage.removeAll();
    keycloak.logout();
    webStorage.removeAll(keycloak.token)
  };

  const DefaultBtnLogout = ({text = 'Đăng xuất',...props}) => {
    return (
      <Button
        icon={<LogoutOutlined />}
        style={{ marginBottom: 12 }}
        onClick={handleLogout}
        type="primary"
        ghost
        danger
        {...props}
      >
        {text}
      </Button>
    );
  };


  return {
    handleLogout,
    DefaultBtnLogout,
  };
};

export { useLogout };
