import React from 'react';
import { Steps as StepAntd } from 'antd';
import cn from 'classnames';
import style from './step.module.scss';
import {CheckCircleFilled} from "@ant-design/icons";

export const Steps = ({ current = 1, items = [], className, ...props }) => {

    const customDot = (dot, { status, index }) => {
      return (
        status === "finish"
            ? <div className={style['finish-icon']}><CheckCircleFilled/></div>
            : dot
    )
    };

  return (
    <>
      <StepAntd
        progressDot={customDot}
        current={current}
        items={items}
        {...props}
        className={cn(className, style['custom-step'])}
      />
    </>
  );
};
