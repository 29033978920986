import Apis from "../../../constants/apis";
import { common_post } from "../../../services/apiInstances";

//DM Nhóm đối tượng
export const dispatchGetListDmNhomDT = (dataRequest) => common_post(Apis.layDMNhomDoiTuong, dataRequest);
export const dispatchAddDmNhomDT = (dataRequest) => common_post(Apis.themDmNhomDoiTuong, dataRequest);
export const dispatchEditDmNhomDT = (dataRequest) => common_post(Apis.updateDmNhomDoiTuong, dataRequest);
export const dispatchDeleteDmNhomDT = (dataRequest) => common_post(Apis.xoaNhieuDoiTuong, dataRequest);
export const dispatchLockDmNhomDT = (dataRequest) => common_post(Apis.khoaNhieuDoiTuong, dataRequest);

//DM khoa
export const dispatchGetListDmKhoa = (dataRequest) => common_post(Apis.layDMKhoa, dataRequest);
export const dispatchAddDmKhoa = (dataRequest) => common_post(Apis.themDMKhoa, dataRequest);
export const dispatchListMaChuyenKhoa = (dataRequest) => common_post(Apis.layMaChuyenKhoa, dataRequest);
export const dispatchUpateDmKhoa = (dataRequest) => common_post(Apis.suaDMKhoa, dataRequest);
export const dispatchDeleteDmKhoa = (dataRequest) => common_post(Apis.xoaDMKhoa, dataRequest);
export const dispatchLockDmKhoa = (dataRequest) => common_post(Apis.suaTrangThaiDMKhoa, dataRequest);

//DM phòng
export const dispatchGetListDmPhong = (dataRequest) => common_post(Apis.layDMPhong, dataRequest);
export const dispatchAddDmPhong = (dataRequest) => common_post(Apis.themDMPhong, dataRequest);
export const dispatchUpateDmPhong = (dataRequest) => common_post(Apis.suaDMphong, dataRequest);
export const dispatchDeleteDmPhong = (dataRequest) => common_post(Apis.xoaDMphong, dataRequest);
export const dispatchLockDmPhong = (dataRequest) => common_post(Apis.suaTrangThaiDMphong, dataRequest);

//DM nhóm dịch vụ
export const dispatchGetListDmNhomDv = (dataRequest) => common_post(Apis.layDMNhomDichVu, dataRequest);
export const dispatchAddDmNhomDv = (dataRequest) => common_post(Apis.themDMNhomDichVu, dataRequest);
export const dispatchUpdateDmNhomDv = (dataRequest) => common_post(Apis.suaDMNhomDichVu, dataRequest);
export const dispatchDeleteDmNhomDv = (dataRequest) => common_post(Apis.xoaDMNhomDichVu, dataRequest);
export const dispatchLockDmNhomDv = (dataRequest) => common_post(Apis.lockDMNhomDichVu, dataRequest);
export const dispatchUnLockDmNhomDv = (dataRequest) => common_post(Apis.moDMNhomDichVu, dataRequest);

//DM Loại Dịch Vụ
export const dispatchGetListDmLoaiDv = (dataRequest) => common_post(Apis.layDMLoaiDv, dataRequest);
export const dispatchAddDmLoaiDv = (dataRequest) => common_post(Apis.themDMLoaiDv, dataRequest);
export const dispatchUpateDmLoaiDv = (dataRequest) => common_post(Apis.suaDMLoaiDv, dataRequest);
export const dispatchDeleteDmLoaiDv = (dataRequest) => common_post(Apis.xoaDMLoaiDv, dataRequest);
export const dispatchLockDmLoaiDv = (dataRequest) => common_post(Apis.khoaDMLoaiDv, dataRequest);

export const dispatchGetListDmLoaiDvHL = (dataRequest) => common_post(Apis.layDMLoaiDvHL, dataRequest);
export const dispatchExportNhomDoiTuong = (dataRequest) => common_post(Apis.dm_export_nhom_doi_tuong, dataRequest);
export const exportPhong = (dataRequest) => common_post(Apis.exportPhong, dataRequest);
export const exportNDV = (dataRequest) => common_post(Apis.exportNDV, dataRequest);
export const exportLDV = (dataRequest) => common_post(Apis.exportLDV, dataRequest);
export const nhapDanhMucKhoa = (dataRequest) => common_post(Apis.nhapDanhMucKhoa, dataRequest);
export const nhapDanhMucNhomDv = (dataRequest) => common_post(Apis.nhapDanhMucNhomDv, dataRequest);
export const postImportPhong = (dataRequest) => common_post(Apis.nhapDanhMucPhong, dataRequest);
export const nhapDanhMucLoaiDv = (dataRequest) => common_post(Apis.nhapDanhMucLoaiDv, dataRequest);
export const postGetDsPhongThucHien = (dataRequest) => common_post(Apis.khambenh_lay_dm_phong, dataRequest);
