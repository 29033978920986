import {createSlice, current} from "@reduxjs/toolkit";

const initState = {
    data: {},
};

const dmNhomDTSlice = createSlice({
    name: "dmNhomDT",
    initialState: initState,
    reducers: {
        doGetListNhomDT: () => {console.log("doGetListNhomDT");},
        doCreateNhomDT: () => {console.log("doCreateNhomDT");},
        doUpdateNhomDT: () =>{console.log("doUpdateNhomDT");},
        doDeleteDmNhomDT: () =>{console.log("doDeleteDmNhomDT");},
        doExportDmNhomDT: () =>{console.log("doExportDmNhomDT");},
        doImportDmNhomDT: () =>{console.log("doImportDmNhomDT");},

        doSetListNhomDT: (state,action) => {

            state.data = action.payload;
        },

        lockDmNhomDt: () => {},
        updateDmNhomDt: (state, action) => {
            
            const updateLock = state.data.result.findIndex((id) => id.ID == action.payload.id[0].toString() )
            state.data.result[updateLock].KHOA = action.payload.isLock
        },
    },
});

export const {
  doGetListNhomDT,
  doCreateNhomDT,
  doUpdateNhomDT,
  doSetListNhomDT,
  lockDmNhomDt,
  updateDmNhomDt,
  doDeleteDmNhomDT,
  doExportDmNhomDT,
  doImportDmNhomDT
} = dmNhomDTSlice.actions;
export const DataDmNhomDT = (state) => state.DM_Chung.DmNhomDT
export default dmNhomDTSlice.reducer;