import React, { useCallback } from "react";
import { Form, Col, Row, Checkbox, Radio } from "antd";
import style from "./styles/style.module.scss";
import classNames from "classnames";
import dmLoaiDichVu from "../../../../../assets/initData/dataDichVu.json";
import { TreeSelect } from "../../../../../components";
const { Item } = Form;
const ClinicServicesProvide = () => {
  const formattedDsChuyenKhoaKhamBenh = React.useMemo(() => {
    let data = [];
    dmLoaiDichVu.forEach((d) => {
      if (d?.MA_LOAI?.includes("KB_")) {
        data.push({
          title: d.TEN_LOAI,
          value: d.ID,
          fullValue: d,
        });
      }
    });

    return [
      {
        title: "Tất cả",
        value: "ALL",
        children: data,
      },
    ];
  }, [dmLoaiDichVu]);
  //================================================================
  const filterDataLoaiXn = useCallback(
    (type) => {
      return dmLoaiDichVu
        .filter((d) => {
          return d?.MA_LOAI?.includes(type);
        })
        .map((i) => ({ title: i.TEN_LOAI, value: i.ID }));
    },
    [dmLoaiDichVu]
  );

  const dataXetNghiem = filterDataLoaiXn("XN_");

  const dataDiagnose = filterDataLoaiXn("CDHA_");

  const thamDoChucNang = filterDataLoaiXn("TDCN_");

  //================================================================

  return (
    <>
      <Row justify={"center"} className={style["form-container"]}>
        <Col flex="60%">
          <Row gutter={[10,10]}>
            <Col span={24} className={style["center-header"]} style={{ marginBottom:16 }}>
              <b>Khởi tạo thông tin dịch vụ</b>
            </Col>{" "}
            <Col> Hãy chọn loại dịch vụ phòng khám của bạn đang cung cấp </Col>
            <Col
              span={24}
              className={classNames(
                style["wrapper-specialist"],
                // typeSpecialist === clinicType.DA_KHOA &&
                style["wrapper-specialist--mode1"]
              )}
            >
              <Form.Item name={fieldInitClinic.DICH_VU_KHAM_BENH}>
                <TreeSelect
                  className={style["tree-select"]}
                  style={{ width: 200 }}
                  mode="multiple"
                  placeholder="Tìm kiếm dịch vụ"
                  dataSource={formattedDsChuyenKhoaKhamBenh}
                  filterTreeNode={(input, option) =>
                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  maxTagCount={2}
                  showArrow
                  isCheckAll
                  treeCheckable
                  treeNodeFilterProp={"title"}
                  treeDefaultExpandAll={true}
                  autoClearSearchValue={false}
                  showCheckedStrategy={TreeSelect.SHOW_PARENT}

                  // isNoChildren
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span className={style["sub-title"]}>Thăm dò chức năng</span>
            </Col>
            <Col span={24} className={style["wrapp-list"]}>
              <Item name={fieldInitClinic.THAM_DO_CHUC_NANG}>
                <Checkbox.Group>
                  {thamDoChucNang.map((i, index) => {
                    return (
                      <div className={style["wrap-checkbox"]} key={i.value}>
                        <Checkbox value={i.value}>{i.title}</Checkbox>
                      </div>
                    );
                  })}
                </Checkbox.Group>
              </Item>
            </Col>

            <Col span={24}>
              <span className={style["sub-title"]}>Xét nghiệm</span>
            </Col>
            <Col span={24} className={style["wrapp-list"]}>
              <Item name={fieldInitClinic.XET_NGHIEM}>
                <Checkbox.Group>
                  {dataXetNghiem.map((i) => (
                    <div className={style["wrap-checkbox"]} key={i.value}>
                      <Checkbox value={i.value}>{i.title}</Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>
              </Item>
            </Col>
            <Col span={24}>
              <span className={style["sub-title"]}>Chẩn đoán hình ảnh</span>
            </Col>
            <Col span={24} className={style["wrapp-list"]}>
              <Item name={fieldInitClinic.CHAN_DOAN}>
                <Checkbox.Group>
                  {dataDiagnose.map((i) => (
                    <div className={style["wrap-checkbox"]} key={i.value}>
                      <Checkbox value={i.value}>{i.title}</Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>
              </Item>
            </Col>

            <Col span={24} style={{display:"none"}}>
              <span className={style["sub-title"]}>
                Phòng khám của bạn có Thủ thuật, phẫu thuật không?{" "}
              </span>
              <Item name={fieldInitClinic.CO_TIEU_PHAU} initialValue={0}>
                <Radio.Group className={style["radio-wrapper"]}>
                  <Radio value={1}>Có</Radio>
                  <Radio value={0}>không</Radio>
                </Radio.Group>
              </Item>
            </Col>

            <Col span={24} style={{display:"none"}}>
              <span className={style["sub-title"]}>
                Phòng khám của bạn có bán thuốc không?
              </span>
              <Item name={fieldInitClinic.KE_THUOC} initialValue={1}>
                <Radio.Group className={style["radio-wrapper"]}>
                  <Radio value={1} defaultChecked>Có</Radio>
                  <Radio value={0}>không</Radio>
                </Radio.Group>
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const fieldInitClinic = {
  DICH_VU_KHAM_BENH: "DICH_VU_KHAM_BENH",
  THAM_DO_CHUC_NANG: "THAM_DO_CHUC_NANG",
  XET_NGHIEM: "XET_NGHIEM",
  CHAN_DOAN: "CHAN_DOAN",
  CO_TIEU_PHAU: "CO_TIEU_PHAU",
  KE_THUOC:"KE_THUOC"
};

export default ClinicServicesProvide;
