import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { paths } from "../../utils/constant";
import webStorage from "../../utils/webStorage";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { updateUser } from "../../duck/slices/auth.slice";
/**
 *
 * @param {{element: import("react").ReactElement}} param0
 */
const PrivatedGuard = ({ element }) => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  if (keycloak.authenticated) {
   let userProfile = jwtDecode(keycloak.token);
   webStorage.setToken(keycloak.token);
   dispatch(updateUser(userProfile));
    return element;
  } else {
    return <Navigate to={"/" + paths.LOGIN} replace={true} />;
  }
};
export default PrivatedGuard;
