import React from 'react'
import { Button } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { useNavigate, useHistory } from "react-router-dom"
import { paths } from '../../utils/constant'
const BackBtn = ({onClick = () => {}}) => {
    const navigate = useNavigate()
    const handleClick = () => {
        onClick();
        navigate(`/${paths.HOME}`);
    }
  return (
    <Button icon={<LeftOutlined style={{transform:"scale(0.8)",color:'#6576ff'}}/>} size='small'  shape='circle' onClick={handleClick}/>
  )
}

export default BackBtn