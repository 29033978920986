import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  nhomNguonKhach: [],
  commissionList: [],
  loadingCommission: false,
  commissionListLocal: {},
};

const commission = createSlice({
  name: "commissionSlice",
  initialState,
  reducers: {
    fetchNhomDoiTuong: () => {},
    fetchNhomDoiTuongSuccess: (state, { payload }) => {
      state.nhomNguonKhach = payload;
    },
    fetchCommissionList: (state) => {
      state.loadingCommission = true;
    },
    fetchCommissionListSuccess: (state, { payload }) => {
      state.commissionList = payload;
      state.loadingCommission = false;
    },
    addCommissionListLocal: (state, { payload }) => {
      state.commissionListLocal[payload.name] = payload.value;
    },
    saveCommissionListLocal: (state, { payload }) => {
    },
    deleteCommissionLocal: (state, { payload }) => {
      const clone = state.commissionListLocal;
      delete clone[payload];
      state.commissionListLocal = clone;
    },
    clearCommissionLocal: (state, { payload }) => {
      state.commissionListLocal = {};
    },
  },
});
export const {
  fetchNhomDoiTuong,
  fetchNhomDoiTuongSuccess,
  fetchCommissionList,
  fetchCommissionListSuccess,
  saveCommissionListLocal,
  addCommissionListLocal,
  deleteCommissionLocal,
  clearCommissionLocal
} = commission.actions;

export default commission.reducer;
