import React, { useState, useEffect } from "react";
import { CommonSelect, FormDrawer, Input, TreeSelect } from "../../../../../components";
import { Form, Row, Col, Button, notification, Spin } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import TINH from "../../../../../models/tinh.json";
import HUYEN from "../../../../../models/huyen.json";
import XA from "../../../../../models/xa.json";
import { MO_HINH_CSKCB, lisenceModules } from "../../../../../constants/data";
import { common_get, common_post } from "../../../../../services/apiInstances";
import { ENDPOINT } from "../../../../../services/endPoint";
import { locdau } from "../../../../../helpers";
const ClinicDrawer = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [initData, setInitData] = React.useState();
  const [selectedTinh, setSelectedTinh] = useState();
  const [selectedHuyen, setSelectedHuyen] = useState();
  const [form] = Form.useForm();
  const [loading , setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const handleOpen = (data) => {
    setInitData(data);
    handleGetDetail(data);
    setOpen(true);
  };
  const handleClose = (isReload = false) => {
    setInitData();
    setOpen(false);
    if (isReload && props?.reloadList) {
      props.reloadList()
    } 
    form.resetFields()
  };

  React.useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const onSubmit = async (values) => {
    setLoading(true);
    const { TEN_PHUONG_XA , TEN_QUAN_HUYEN, TEN_TINH_THANH } = values;
    let  payload = {
      ...values,
      GIAY_PHEP_TINH_NANG:Array.isArray(values.GIAY_PHEP_TINH_NANG) ? ['MANAGEMENT',...values.GIAY_PHEP_TINH_NANG] : ['MANAGEMENT'],
      partner_code : values?.partner_code?.toLowerCase(),
      TEN_PHUONG_XA : TEN_PHUONG_XA ? JSON.parse(TEN_PHUONG_XA).TEN_PHUONG_XA : "",
      MA_PHUONG_XA : TEN_PHUONG_XA ? JSON.parse(TEN_PHUONG_XA).MA_PHUONG_XA : "",
      TEN_QUAN_HUYEN : TEN_QUAN_HUYEN ? JSON.parse(TEN_QUAN_HUYEN).TEN_QUAN_HUYEN : "",
      MA_QUAN_HUYEN : TEN_QUAN_HUYEN ? JSON.parse(TEN_QUAN_HUYEN).MA_QUAN_HUYEN : "",
      TEN_TINH_THANH : TEN_TINH_THANH ? JSON.parse(TEN_TINH_THANH).TEN_TINH_THANH : "",
      MA_TINH_THANH : TEN_TINH_THANH ? JSON.parse(TEN_TINH_THANH).MA_TINH_THANH : "",
    }
    if (initData) {
      payload = {
        ...payload,
        ID : initData.ID
      }
    }
   try {
  
      const response = await common_post(initData ? ENDPOINT.suaKhachHang: ENDPOINT.themKhachHang, payload);
    if(response.status === 'OK') {
      setLoading(false);
      notification.success({message : initData ? "Sửa thành công" : "Thêm khách hàng thành công", placement : "bottomLeft",})
      handleClose(true);
    } else {
      throw new Error(response.message)
    }
   } catch (error) {
      notification.error({message : error.toString(), placement : "bottomLeft",})
   } finally {
    setLoading(false)
   }
  };

  const handleGetDetail = async (data) => {
    try {
      if(!data.ID) return
      setLoading(true);
      const response = await common_post('api/khachHang/layChiTietKhachHang', {ID : data.ID})
     
      const customer = response.result
      form.setFields(Object.keys(fieldsKH).map(item => {
        if (item === fieldsKH.NGUOI_LIEN_HE) {
          return {
            name: item,
            value : customer.NGUOI_LIEN_HE
          }
        }
        if (item === fieldsKH.TEN_TINH_THANH && customer.MA_TINH_THANH) {
          const tinh =  TINH.find(el => el.MA_TINH_THANH === customer.MA_TINH_THANH)
          setSelectedTinh(tinh)
          return {
            name: item,
            value : JSON.stringify(tinh)
          }
        }

        if (item === fieldsKH.TEN_QUAN_HUYEN && customer.MA_TINH_THANH && customer.MA_QUAN_HUYEN) {
          const huyen = HUYEN[`${customer.MA_TINH_THANH}`].find(el => el.MA_QUAN_HUYEN === customer.MA_QUAN_HUYEN)
          setSelectedHuyen(huyen)
          return {
            name: item,
            value : JSON.stringify(huyen)
          }
        }

        if (item === fieldsKH.TEN_PHUONG_XA  && customer.MA_PHUONG_XA && customer.MA_QUAN_HUYEN) {
          const xa = XA[`${customer.MA_QUAN_HUYEN}`].find(el => el.MA_PHUONG_XA === customer.MA_PHUONG_XA)
          return {
            name: item,
            value : JSON.stringify(xa)
          }
        }

        if(item === fieldsKH.GIAY_PHEP_TINH_NANG) {
          try {
            return {
              name:item,
              value:customer[item].map(c => c?.TINH_NANG)
            }
          } catch (error) {
            return {
              name:item,
              value:[]
            }
          }
        }
        return {
          name : item,
          value : customer[`${item}`]
        }
      }))
      setLoading(false);
     
    }catch(error) {
      console.log(error)
    }
  }

  let lisenceOptions = React.useMemo(() => {
    try {
      const renderLabel = (mod) => {
        let name = '';
        switch (mod) {
          case 'DUOC':
            name = 'Dược ngoại trú';
            break
            case 'BAO_CAO':
              name = 'Báo cáo';
              break
              case 'MANAGEMENT':
                name = 'Quản trị';
                break
          default:
            name = mod;
            break;
        }
        return name;
      }
      return lisenceModules.map((ls) => {
        return { label: renderLabel(ls), key: ls, value: ls };
      });
    } catch (error) {}
  }, [lisenceModules]);

  return (
    <FormDrawer
      width={"calc(100vw - 252px)"}
      visible={open}
      onCancel={()=> handleClose()}
      title={(initData ? "Chi tiết" : "Thêm mới") + " khách hàng"}
      hiddenTopAction={true}
      loading = {loading}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Row gutter={[10, 10]}>
          <Col flex={"20%"}>
            <Form.Item
              name={fieldsKH.TEN_CSKCB}
              label={
                <>
                  Tên phòng khám<span className={"red-txt"}> *</span>
                </>
              }
              rules={[{ required: true, message:"" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex={"20%"}>
            <Form.Item
             name={fieldsKH.partner_code}
              label={
                <>
                  Tên viết tắt<span className={"red-txt"}> *</span>
                </>
              }
              rules={[{ required: true, message:"" }]}
            >
              <Input onBlur={e => {
                try {
                  form.setFieldValue(fieldsKH.partner_code, locdau(e.target.value?.toLowerCase()?.replace?.(/\s+/g, '')?.trim?.()))
                } catch (error) {
                  console.log(error)
                }
              }} />
            </Form.Item>
          </Col>
          <Col flex={"20%"}>
            <Form.Item label="Mô hình CSKCB"  name={fieldsKH.MO_HINH_CSKCB}>
              <CommonSelect selectType={2}   dataSource={MO_HINH_CSKCB} labelKey="name" valueKey="key"/>
            </Form.Item>
          </Col>

          <Col flex={"20%"}>
            <Form.Item label="Số điện thoại"  name={fieldsKH.SO_DIEN_THOAI}>
              <Input />
            </Form.Item>
          </Col>

          <Col flex={"20%"}>
            <Form.Item label="Email"  name={fieldsKH.EMAIL}>
              <Input />
            </Form.Item>
          </Col>

          <Col flex={"20%"}>
            <Form.Item label="Tỉnh/Thành phố"  name={fieldsKH.TEN_TINH_THANH}>
              <CommonSelect selectType={2}  
                dataSource={TINH}
                labelKey="TEN_TINH_THANH"
                onSelect={(value) => {
                  form.resetFields([fieldsKH.TEN_QUAN_HUYEN,fieldsKH.TEN_PHUONG_XA])
                  setSelectedTinh(JSON.parse(value));
                }}
                onClear={() => {setSelectedHuyen();form.resetFields([fieldsKH.TEN_QUAN_HUYEN,fieldsKH.TEN_PHUONG_XA])}}
              />
            </Form.Item>
          </Col>

          <Col flex={"20%"}>
            <Form.Item label="Quận/Huyện"  name={fieldsKH.TEN_QUAN_HUYEN}>
              <CommonSelect selectType={2}  
                dataSource={
                  selectedTinh ? HUYEN[`${selectedTinh.MA_TINH_THANH}`] : []
                }
                labelKey="TEN_QUAN_HUYEN"
                onSelect={(value) => {
                  form.resetFields([fieldsKH.TEN_PHUONG_XA])
                  setSelectedHuyen(JSON.parse(value));
                }}
                onClear={() => {form.resetFields([fieldsKH.TEN_PHUONG_XA])}}
              />
            </Form.Item>
          </Col>

          <Col flex={"20%"}>
            <Form.Item label="Xã/Phường"  name={fieldsKH.TEN_PHUONG_XA}>
              <CommonSelect selectType={2}  
                dataSource={
                  selectedHuyen ? XA[`${selectedHuyen.MA_QUAN_HUYEN}`] : []
                }
                labelKey="TEN_PHUONG_XA"
              />
            </Form.Item>
          </Col>

          <Col flex={"20%"}>
            <Form.Item label="Địa chỉ chi tiết"  name={fieldsKH.DIA_CHI_CHI_TIET}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex={"20%"}>
            <Form.Item label="Website"  name={fieldsKH.WEBSITE}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex={"20%"}>
            <Form.Item label="Giấy phép triển khai" rules={[{required:true,message:"Vui lòng chọn phân hệ làm việc"}]} name={fieldsKH.GIAY_PHEP_TINH_NANG} initialValue={['CIS','MANAGEMENT']}>
              <TreeSelect
                className="custom__tree__select"
                style={{backgroundColor: '#F4F7FF'}}
                dataSource={lisenceOptions} 
                maxTagCount={1}
               />
            </Form.Item>
          </Col>
          
          {/* <Col flex={"20%"}>
            <Form.Item label="Nhân viên triển khai"  name={fieldsKH.TR}>
              <CommonSelect selectType={2}   />
            </Form.Item>
          </Col>

          <Col flex={"20%"}>
            <Form.Item label="Nhân viên chăm sóc">
              <CommonSelect selectType={2}   />
            </Form.Item>
          </Col> */}
        </Row>
        <h2 style={{ marginBlock: "10px" }}>Thông tin người liên hệ</h2>
        <Form.List name="NGUOI_LIEN_HE" initialValue={[{ HO_TEN: "", SO_DIEN_THOAI: "", EMAIL : "", DIA_CHI_CHI_TIET : "" }]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={[10, 10]} style={{ marginTop: "10px" }} key={key}>
                  <Col flex={"0%"} style={{display : "none"}}>
                    <Form.Item
                      {...restField}
                      name={[name, "ID"]}
                      label="Họ tên"
                    >
                      <Input placeholder="Họ tên" />
                    </Form.Item>
                  </Col>
                  <Col flex={"24%"}>
                    <Form.Item
                      {...restField}
                      name={[name, "HO_TEN"]}
                      label="Họ tên"
                    >
                      <Input placeholder="Họ tên" />
                    </Form.Item>
                  </Col>
                  <Col flex={"24%"}>
                    <Form.Item
                      {...restField}
                      name={[name, "SO_DIEN_THOAI"]}
                      label="Số điện thoại"
                    >
                      <Input placeholder="Số điện thoại" />
                    </Form.Item>
                  </Col>
                  <Col flex={"24%"}>
                    <Form.Item
                      {...restField}
                      name={[name, "EMAIL"]}
                      label="Email"
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col flex={"24%"}>
                    <Form.Item
                      {...restField}
                      name={[name, "DIA_CHI_CHI_TIET"]}
                      label="Địa chỉ"
                    >
                      <Input placeholder="Địa chỉ" />
                    </Form.Item>
                  </Col>
                  <Col flex={"4%"}>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{ marginTop: "40px", color: "red" }}
                    />
                  </Col>
                </Row>
              ))}

              <Row onClick={() => add()} style={{ marginTop: "10px" }}>
                <PlusCircleOutlined style={{ color: "#6576FF" }} size={20} />
                <div style={{ width: "10px" }}></div>
                <span
                  style={{
                    color: "#6576FF",
                    fontWeight: "600",
                    fontSize: "17px",
                  }}
                >
                  Thêm mới
                </span>
              </Row>
            </>
          )}
        </Form.List>
        </Form>
        <Row justify="end">
          <Button
            type="primary"
            ghost
            style={{ width: 150, margin: 10 }}
            onClick={handleClose}
          >
            Thoát
          </Button>
          <Button
            type="primary"
            style={{ width: 150, margin: 10 }}
            onClick={() => form.submit()}
          >
            Lưu
          </Button>
        </Row>
      </Spin>
    </FormDrawer>
  );
});

export default ClinicDrawer;


export const fieldsKH = {
  partner_code: 'partner_code',
  TEN_CSKCB: 'TEN_CSKCB',
  LOAI_HINH_CSKCB: 'LOAI_HINH_CSKCB',
  MO_HINH_CSKCB: 'MO_HINH_CSKCB',
  MA_TINH_THANH: 'MA_TINH_THANH',
  TEN_TINH_THANH: 'TEN_TINH_THANH',
  MA_QUAN_HUYEN: 'MA_QUAN_HUYEN',
  TEN_QUAN_HUYEN: 'TEN_QUAN_HUYEN',
  MA_PHUONG_XA: 'MA_PHUONG_XA',
  TEN_PHUONG_XA: 'TEN_PHUONG_XA',
  DIA_CHI_CHI_TIET: 'DIA_CHI_CHI_TIET',
  SO_DIEN_THOAI: 'SO_DIEN_THOAI',
  EMAIL: 'EMAIL',
  WEBSITE: 'WEBSITE',
  NGUOI_LIEN_HE : "NGUOI_LIEN_HE",
  GIAY_PHEP_TINH_NANG:"GIAY_PHEP_TINH_NANG"
}