import { select, takeLatest } from "redux-saga/effects";
import {
  doCreateNhomDT,
  doDeleteDmNhomDT,
  doGetListNhomDT,
  doSetListNhomDT,
  doUpdateNhomDT,
  lockDmNhomDt,
  updateDmNhomDt,
  doExportDmNhomDT,
  doImportDmNhomDT
} from "../../slices/DM_Chung/DmNhomDTSlice.js";
import { common_post } from "../../../services/apiInstances.js";
import Apis from "../../../constants/apis.js";
import { notification } from "antd";
import _ from "lodash";
import { setReloadList } from "../../slices/DM_Chung/DmMain.js";
import {
  handleCommonAdd,
  handleCommonDelete,
  handleCommonEdit,
  handleCommonExport,
  handleCommonGet,
  handleCommonImport,
  handleCommonLock,
} from "../commonSaga/commonSaga.js";
import {
  dispatchAddDmNhomDT,
  dispatchDeleteDmNhomDT,
  dispatchEditDmNhomDT,
  dispatchExportNhomDoiTuong,
  dispatchGetListDmNhomDT,
  dispatchLockDmNhomDT,
  nhapDanhMucNhomDv,
} from "./apiDM_Chung.js";
import { fieldDoiTuong } from "../../../pages/ClinicManagement/Main/Catalog/GroupCatalogs/GeneralCatalogries/fieldsCatalog.js";

export function* watchDoDmNhomDT() {
  yield takeLatest(doGetListNhomDT, handleGetListNhomDT);
  yield takeLatest(doCreateNhomDT, handleCreateNhomDT);
  yield takeLatest(lockDmNhomDt, handleLockDmNhomDt);
  yield takeLatest(doDeleteDmNhomDT, handleDeleteDmNhomDt);
  yield takeLatest(doUpdateNhomDT, handleUpdateDmNhomDt);
  yield takeLatest(doExportDmNhomDT, handleExportDmDoiTuong)
  yield takeLatest(doImportDmNhomDT, handleImportDmDoiTuong)
  
}

function* handleGetListNhomDT(action) {
  try {
    const info = action.payload;
    yield handleCommonGet(
      { ...info },
      dispatchGetListDmNhomDT,
      doSetListNhomDT
    );
  } catch (error) {}
}

function* handleCreateNhomDT(action) {
  const info = action.payload;

  yield handleCommonAdd(
    {
      add: {
        ...info.add.data,
      },
      get: info.get,
    },
    dispatchAddDmNhomDT,
    doGetListNhomDT
  );
}

function* handleUpdateDmNhomDt(action) {
  const info = action.payload;

  yield handleCommonEdit(
    {
      edit: {
        ...info.edit.data,

      },
      get: info.get,
    },
    dispatchEditDmNhomDT,
    doGetListNhomDT
  );
}

function* handleLockDmNhomDt(action) {
  const info = action.payload;

  yield handleCommonLock(
    {
      arr_ID: info.data,
      KHOA: info.lockVal,
      get: info.get,
      ...info,
    },
    dispatchLockDmNhomDT,
    doGetListNhomDT,
    updateDmNhomDt
  );
}

function* handleDeleteDmNhomDt(action) {
  const info = action.payload;

  yield handleCommonDelete(
    {
      delete: {
        arr_ID: Array.isArray(info.delete.data)
          ? info.delete.data.map((item) => item[fieldDoiTuong.id])
          : [info.delete.data[fieldDoiTuong.id]],
        partner_code: info.delete.partner_code,
        BENH_VIEN_ID: info.delete.BENH_VIEN_ID,
      },
      get: info.get,
    },
    dispatchDeleteDmNhomDT,
    doGetListNhomDT
  );
}

function* handleExportDmDoiTuong(action) {
  const info = action.payload
  yield handleCommonExport(
      {
          template: 0,
          partner_code: info.partner_code,
          BENH_VIEN_ID: info.BENH_VIEN_ID
      },
      dispatchExportNhomDoiTuong,
      info.name,
  )
}

function* handleImportDmDoiTuong(action) {
  // yield handleCommonImport(
  //   {
  //     import: action.payload,
  //     get: {
  //       BENH_VIEN_ID: action.payload.BENH_VIEN_ID,
  //       partner_code: action.payload.partner_code,
  //     },
  //   },
  //   nhapDanhMucNhomDv,
  //   Apis.layDMNhomDoiTuong
  // );
}


