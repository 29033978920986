import {
  Col,
  Form,
  notification,
  Row,
  Input as AntInput,
  Typography,
} from "antd";
import React, { forwardRef, memo, useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react18-input-otp";
import { Input as CustomInput, Modal } from "../../../components";
import { locdau, validFragment } from "../../../helpers";
import {
  kiemTraTonTaiUsername,
  kiemtraEmail,
  luuDangKyMoPhongKham,
  sendOTPEmail,
  verifyEmail,
} from "../../../services/register";
import { commonConfig, formPattern } from "../../../utils/constant";
import { fieldInitClinic } from "./InitClinic";
import style from "./style.module.scss";
import changePasswordImg from "../../../assets/images/change-password.svg";
import { changeLoading, setUser } from "../../../duck/slices/registerClinic.slice";

const fieldSubmit = {
  LOAI_PK: "LOAI_PK", // CHUYEN_KHOA
  KE_THUOC: "KE_THUOC",
  LIST_CHUYEN_KHOA_ID: "LIST_CHUYEN_KHOA_ID",
  TEN_NGUOI_LIEN_HE: "TEN_NGUOI_LIEN_HE",
  SDT_NGUOI_LIEN_HE: "SDT_NGUOI_LIEN_HE",
  EMAIL_NGUOI_LIEN_HE: "EMAIL_NGUOI_LIEN_HE",
  USERNAME: "USERNAME",
  PASSWORD: "PASSWORD",
  TEN_PHONG_KHAM: "TEN_PHONG_KHAM",
  TEN_PK_VIET_TAT: "TEN_PK_VIET_TAT",
  LOAI_DICH_VU_DAC_BIET: "LOAI_DICH_VU_DAC_BIET",
};

const fieldInfo = {
  TEN: "TEN",
  SO_DIEN_THOAI: "SO_DIEN_THOAI",
  EMAIL: "EMAIL",
  USERNAME: "USER_NAME",
  PASSWORD: "PASS_WORD",
};

const fieldDataToSubmit = {
  [fieldSubmit.TEN_NGUOI_LIEN_HE]: fieldInfo.TEN,
  [fieldSubmit.LIST_CHUYEN_KHOA_ID]: fieldInitClinic.CHUYEN_KHOA,
  [fieldSubmit.SDT_NGUOI_LIEN_HE]: fieldInfo.SO_DIEN_THOAI,
  [fieldSubmit.EMAIL_NGUOI_LIEN_HE]: fieldInfo.EMAIL,
  [fieldSubmit.PASSWORD]: fieldInfo.PASSWORD,
  [fieldSubmit.TEN_PHONG_KHAM]: fieldInitClinic.TEN_PHONG_KHAM,
  [fieldSubmit.TEN_PK_VIET_TAT]: fieldInitClinic.TEN_PK_VIET_TAT,
  [fieldSubmit.LOAI_DICH_VU_DAC_BIET]: fieldInitClinic.XET_NGHIEM,
  [fieldSubmit.USERNAME]: fieldInfo.USERNAME,
  [fieldSubmit.KE_THUOC]: fieldInitClinic.KE_THUOC,
  [fieldSubmit.LOAI_PK]: fieldInitClinic.LOAI_PK,
};

// from Backend
const fieldInputInit = {
  USER_ID: "USER_ID", // neu co thi update
  TEN_NGUOI_LIEN_HE: "TEN_NGUOI_LIEN_HE", // => TEN, // :HUY,
  SDT_NGUOI_LIEN_HE: "SDT_NGUOI_LIEN_HE", // => SO_DIEN_THOAI, //:0362108386,
  EMAIL_NGUOI_LIEN_HE: "EMAIL_NGUOI_LIEN_HE", // => EMAIL , // :MST12@gmail.com,
  USERNAME: "USERNAME",
  PASSWORD: "PASSWORD",
  TEN_PHONG_KHAM: "TEN_PHONG_KHAM",
  TEN_PK_VIET_TAT: "TEN_PK_VIET_TAT", // max 10

  LOAI_PK: "LOAI_PK", // :CHUYEN_KHOA, DA_KHOA
  KE_THUOC: "KE_THUOC", // => KE_THUOC, // :0,
  LIST_DICH_VU_ID: "LIST_DICH_VU_ID",
  NHU_CAU: "NHU_CAU",
  LIST_DV_CLS_ID: "LIST_DV_CLS_ID", // :[CDHA],XET_NGHIEM
  LIST_CHUYEN_KHOA_ID: "LIST_CHUYEN_KHOA_ID", // :["DA_LIEU"],
  LOAI_DICH_VU_DAC_BIET: "LOAI_DICH_VU_DAC_BIET",
  CO_TIEU_PHAU: "CO_TIEU_PHAU", // có tiểu phẫu, thủ thuật hay không
};

let Input = ({ ...props }) => {
  return <CustomInput className={style["input"]} {...props} />;
};

export const InitInfo = memo(
  forwardRef(({ onNextStep = () => {}, ...props }, ref) => {
    const otpRef = useRef();
    const [form] = Form.useForm();
    const [otp, setOtp] = useState("");
    const [validOtp, setValidOtp] = useState(false);

    const { userInfo, loading } = useSelector((state) => state.registerClinic);
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
      // loading,
      submit: () => onOpenOTPmodal(),
    }));

    const mapData = async () => {
      if (!userInfo) return {};
      try {
        let data = {};

        data = await validFragment(userInfo, fieldInputInit);
        data = {
          ...data,
          [fieldInputInit.LOAI_DICH_VU_DAC_BIET]: [
            ...userInfo[fieldInitClinic.CHAN_DOAN],
            ...userInfo[fieldInitClinic.XET_NGHIEM],
            ...userInfo[fieldInitClinic.THAM_DO_CHUC_NANG],
          ],
        };
        Object.keys(data).forEach((f) => {
          if (
            [
              fieldInitClinic.CHAN_DOAN,
              fieldInitClinic.THAM_DO_CHUC_NANG,
              fieldInitClinic.XET_NGHIEM,
              fieldInitClinic.CHUYEN_KHOA,
            ].includes(f)
          ) {
            delete data[f];
          }
        });

        return data;
      } catch (error) {
        console.log(error);
        return {};
      }
    };

    const handleSubmit = async () => {
      dispatch(changeLoading(true));

      try {
        let body = await mapData();

        let res = await luuDangKyMoPhongKham(body);
        if (res && res.status === "OK") {
          setOtp("");
          onNextStep();
          dispatch(changeLoading(false));
          return true;
        } else {
          dispatch(changeLoading(false));
          return false;
        }
      } catch (error) {
        if (error.status) {
          notification.error({
            message: error?.message ? error?.message : "Đăng ký thất bại",
          });
        }
        dispatch(changeLoading(false));
        return false;
      }
    };

    const onOpenOTPmodal = async () => {
      if (validOtp) {
        handleSubmit();
        return;
      }

      try {
        let errorField = await form.getFieldsError();
        let check = false;
        for (let i = 0; i < errorField.length; i++) {
          const error = errorField[i];
          if (!!error.errors.length) check = true;
        }

        if (check) return;

        let values = await form.validateFields();
        let body = {};

        Object.entries(fieldDataToSubmit).forEach((f) => {
          if (Object.values(fieldInfo).includes(f[1])) {
            body = { ...body, [f[0]]: values[f[1]] };
          } else {
            if (
              f[0] === fieldSubmit.LOAI_DICH_VU_DAC_BIET &&
              Array.isArray(userInfo[f[1]]) &&
              Array.isArray(userInfo[fieldInitClinic.CHAN_DOAN])
            ) {
              body = {
                ...body,
                [f[0]]: [
                  ...userInfo[f[1]],
                  ...userInfo[fieldInitClinic.CHAN_DOAN],
                ],
              };
            } else {
              body = { ...body, [f[0]]: userInfo[f[1]] };
            }
          }
        });

        dispatch(setUser({ ...userInfo, ...body }));
        handleSendOTP(body.EMAIL_NGUOI_LIEN_HE).then((resolve) => {
          resolve && otpRef.current.open();
        });
        // otpRef.current.open();
      } catch (error) {
        console.log(error);
      }
    };

    const apiKiemTraUsername = async (USERNAME) => {
      try {
        let body = {
          USERNAME,
        };

        let res = await kiemTraTonTaiUsername(body);
        if (res && res.status === "OK") {
          form.setFields([
            {
              name: fieldInfo.USERNAME,
              value: USERNAME,
              errors: null,
            },
          ]);
        }
      } catch (error) {
        form.setFields([
          {
            name: fieldInfo.USERNAME,
            errors: [
              ("Tên đăng nhập đã tồn tại trong hệ thống, vui lòng sử dụng tên khác"),
            ],
          },
        ]);
      }
    };
    let apiKiemtraEmail = async (EMAIL = "") => {
      try {
        if (EMAIL === "") {
          return;
        }
        let body = { EMAIL };
        let res = await kiemtraEmail(body);

        if (res && res.status === "OK") {
          form.setFields([
            {
              name: fieldInfo.EMAIL,
              value: EMAIL,
              errors: null,
            },
          ]);
        }
      } catch (error) {
        form.setFields([
          {
            name: fieldInfo.EMAIL,
            errors: [
              "Email đã tồn tại trong hệ thống, vui lòng nhập email khác",
            ],
          },
        ]);
      }
    };

    const handleChangeOtp = (val) => {
      setOtp(val);
      if (`${val}`.length === 6) {
        onValidateEmail(val);
      }
    };

    useEffect(() => {
      return () => {
        setValidOtp(false);
        setOtp("");
        dispatch(changeLoading(false));
      };
    }, [dispatch]);

    const onValidateEmail = async (valueOtp) => {
      dispatch(changeLoading(true));
      try {
        let body = {
          OTP: valueOtp || otp,
          EMAIL: userInfo?.EMAIL_NGUOI_LIEN_HE,
        };

        let res = await verifyEmail(body);

        if (res && res.status === "OK") {
          // init phòng khám
          dispatch(changeLoading(false));
          setValidOtp(true);
          await handleSubmit();
          otpRef.current.close();
        }
      } catch (error) {
        console.log(error);
        notification.error({ message: "Mã xác thực không chính xác" });
      }
      dispatch(changeLoading(false));
    };

    const handleSendOTP = async (email) => {
      dispatch(changeLoading(true));
      try {
        let res = await sendOTPEmail({
          EMAIL: email,
          from: commonConfig.ENV_NAME,
        });

        if (res && res.status === "OK") {
          notification.success({
            message: "Gửi email thành công, vui lòng kiểm tra email của bạn",
          });
          dispatch(changeLoading(false));
          return true;
        } else {
          dispatch(changeLoading(false));
          return false;
        }
      } catch (error) {}
      dispatch(changeLoading(false));
    };

    return (
      <div className={style["wrapper"]}>
        <Form
          form={form}
          layout="vertical"
          style={{ width: "100%" }}
          className={style["init-info"]}
        >
          <Row gutter={10} style={{ marginTop: 20 }}>
            <Col span={24}>
              <Form.Item
                label="Họ và tên"
                name={fieldInfo.TEN}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập họ và tên của bạn",
                  },
                ]}
              >
                <Input readOnly={validOtp} />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Form.Item
                label="Số điện thoại"
                name={fieldInfo.SO_DIEN_THOAI}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số điện thoại",
                  },
                  {
                    pattern: formPattern.phone,
                    message: "Vui lòng nhập đúng định dạng số điện thoại",
                  },
                ]}
              >
                <Input readOnly={validOtp} />
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
              <Form.Item
                label="Email"
                name={fieldInfo.EMAIL}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập email",
                  },
                  {
                    pattern: formPattern.email,
                    message: "Vui lòng nhập đúng định dạng email",
                  },
                ]}
              >
                <Input
                  onBlur={() => {
                    const EMAIL = form.getFieldValue(fieldInfo.EMAIL);
                    apiKiemtraEmail(EMAIL);
                  }}
                  readOnly={validOtp}
                />
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
              <Form.Item
                label="Tên đăng nhập"
                name={fieldInfo.USERNAME}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: (rule, value) => {
                      if (typeof value === "string") {
                        let checkValue = locdau(value).replaceAll(" ", "");
                        if (checkValue === value.toLowerCase()) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `Tên đăng nhập không đúng định dạng, vui lòng viết liền, không dấu`
                          );
                        }
                      } else {
                        return Promise.reject(`Vui lòng nhập tên đăng nhập`);
                      }
                    },
                  },
                ]}
              >
                <Input
                  readOnly={validOtp}
                  onBlur={() => {
                    const USERNAME = form.getFieldValue(fieldInfo.USERNAME);
                    apiKiemTraUsername(USERNAME);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
              <Form.Item
                label="Mật khẩu"
                name={fieldInfo.PASSWORD}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mật khẩu đăng nhập",
                  },
                ]}
              >
                <AntInput.Password
                  className={style["input"]}
                  placeholder="Mật khẩu gồm ký tự và chữ số"
                  readOnly={validOtp}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Form.Item
                label="Xác nhận mật khẩu"
                name={"CONFIRM_PASSWORD"}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: (rule, value) => {
                      let pass = form.getFieldValue(fieldInfo.PASSWORD);
                      if (value === "") {
                        return Promise.reject(
                          `Vui lòng nhập xác nhận mật khẩu`
                        );
                      } else if (value !== pass) {
                        return Promise.reject(`Xác nhận mật khẩu không đúng`);
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <AntInput.Password
                  className={style["input"]}
                  placeholder="Nhập lại mật khẩu"
                  readOnly={validOtp}
                />
              </Form.Item>
            </Col>
            <div style={{ height: "50px", width: "100%" }} />
          </Row>
        </Form>
        <Modal
          ref={otpRef}
          title={false}
          onSubmit={(e) => onValidateEmail()}
          width="440px"
          footerProps={{
            justify: "center",
          }}
          maskClosable={false}
          okText={"Xác thực"}
          loadingBtn={loading}
          onVisibleClose={() => setOtp("")}
        >
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <h1 style={{ fontSize: 28, textAlign: "center" }}>
                Xác thực OTP
              </h1>
            </Col>
            <Col span={24}>
              <img
                style={{ display: "block", margin: "auto" }}
                src={changePasswordImg}
                alt=""
              />
            </Col>
            <Col span={24}>
              <span>Vui lòng nhập mã OTP vừa được gửi đến email </span>
              <Typography.Link>
                {userInfo?.EMAIL_NGUOI_LIEN_HE?.toUpperCase()}
              </Typography.Link>{" "}
              <span> của bạn để xác minh.</span>
            </Col>
          </Row>{" "}
          <Row className={style["space-wrapper"]} justify="center">
            <Col span={24}>
              <OtpInput
                containerStyle={style["otp-input-wrapper"]}
                inputStyle={style["otp-input"]}
                value={otp}
                onChange={handleChangeOtp}
                numInputs={6}
                separator={<b style={{ width: 12 }}> </b>}
              />
            </Col>
            <Col span={24}>
              <sub>
                (Vui lòng kiểm tra thư mục spam hoặc thư rác nếu không nhận được
                email)
              </sub>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  })
);
