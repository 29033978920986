import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  userInfo:null,
  loading :false,
};

const registerClinic = createSlice({
  name: 'registerClinic',
  initialState,
  reducers: {

    setUser : (state,action) => {
      state.userInfo = action.payload;
    },
    changeLoading : (state,action) => {
      state.loading = action.payload;
    }
  },

});

const { reducer } = registerClinic;
export const { setUser,changeLoading } = registerClinic.actions;
export default reducer;
