import { put, takeLatest } from "redux-saga/effects";
import { fieldDoiTuong } from "../../../pages/ClinicManagement/Main/Catalog/GroupCatalogs/GeneralCatalogries/fieldsCatalog.js";
import { handleCommonAdd, handleCommonDelete, handleCommonEdit, handleCommonExport, handleCommonGet, handleCommonImport, handleCommonLock } from "../commonSaga/commonSaga.js";
import { doCreateDmLoaiDv, doDeleteDmLoaiDv, doExportDmLoaiDv, doGetListDmLoaiDv, doImportDmLoaiDv, doSetListDmLoaiDv, doUpdateDmLoaiDv, lockDmLoaiDv, updateLoaiDv } from "../../slices/DM_Chung/DmLoaiDv.js";
import { dispatchAddDmLoaiDv, dispatchDeleteDmLoaiDv, dispatchGetListDmLoaiDv, dispatchLockDmLoaiDv, dispatchUpateDmLoaiDv, exportLDV, nhapDanhMucLoaiDv } from "./apiDM_Chung.js";
import { updateKhoa } from "../../slices/DM_Chung/DmKhoa.js";
import { convertKeys } from "../../../helpers.js";
import { mappingDataExcel } from "../../../constants/data.js";


export function* watchDoDmLoaiDv() {
  yield takeLatest(doGetListDmLoaiDv, handleGetListDmLoaiDv);
  yield takeLatest(doCreateDmLoaiDv, handleCreateDmLoaiDv);
  yield takeLatest(lockDmLoaiDv, handleLockDmLoaiDv);
  yield takeLatest(doDeleteDmLoaiDv, handleDeleteDmLoaiDv);
  yield takeLatest(doUpdateDmLoaiDv, handleUpdateDmLoaiDv);
  yield takeLatest(doExportDmLoaiDv, handleExportDmLoaiDv);
  yield takeLatest(doImportDmLoaiDv, handleImportDmLoaiDv);
}

function* handleGetListDmLoaiDv(action) {
  try {
    const info = action.payload;

    yield handleCommonGet({ ...info,}, dispatchGetListDmLoaiDv, doSetListDmLoaiDv);
    
  } catch (error) {}
}

function* handleCreateDmLoaiDv(action) {
  const info = action.payload;
  yield handleCommonAdd(
    {
      add: {
        data:[{...info.add.data}],
        BENH_VIEN_ID:info.add.data.BENH_VIEN_ID,
        partner_code:info.add.data.partner_code
      },
      get: info.get,
    },
    dispatchAddDmLoaiDv,
    doGetListDmLoaiDv
  );
}

function* handleUpdateDmLoaiDv(action) {
  const info = action.payload;

  yield handleCommonEdit(
    {
      edit: {
        ...info.edit.data,
        LOAI_DV_ID:info.edit.data.ID
      },
      get: info.get,
    },
    dispatchUpateDmLoaiDv,
    doGetListDmLoaiDv
  );
}

function* handleLockDmLoaiDv(action) {
  const info = action.payload;
  yield handleCommonLock(
    {
      listId: info.data,
      isKhoa: info.KHOA,
      KHOA: info.KHOA,
      get: info.get,
      ...info,
    },
    dispatchLockDmLoaiDv,
    doGetListDmLoaiDv,
    updateLoaiDv
  );
}

function* handleDeleteDmLoaiDv(action) {
  const info = action.payload;

  yield handleCommonDelete(
    {
      delete: {
        listId: Array.isArray(info.delete.data)
          ? info.delete.data.map((item) => item[fieldDoiTuong.id])
          : [info.delete.data[fieldDoiTuong.id]],
        partner_code: info.delete.partner_code,
        BENH_VIEN_ID: info.delete.BENH_VIEN_ID,
      },
      get: info.get,
    },
    dispatchDeleteDmLoaiDv,
    doGetListDmLoaiDv
  );

}


function* handleExportDmLoaiDv(action) {
  const info = action.payload
  yield handleCommonExport(
      {
          template: 0,
          partner_code: info.partner_code,
          BENH_VIEN_ID: info.BENH_VIEN_ID,
          limit:999,
          page:1
      },
      exportLDV,
      info.name,
  )
}

function* handleImportDmLoaiDv(action) {
  const info = action.payload;
  info.data = convertKeys(info.data, mappingDataExcel,['Nhóm chi phí BHYT']);
  let getPayload = !!(info && info.reload)
    ? {
        ...info.reload,
        partner_code: info.partner_code,
        BENH_VIEN_ID: info.BENH_VIEN_ID,
      }
    : { partner_code: info.partner_code, BENH_VIEN_ID: info.BENH_VIEN_ID };
  yield handleCommonImport(
    {
      template: 0,
      partner_code: info.partner_code,
      BENH_VIEN_ID: info.BENH_VIEN_ID,
      limit: 999,
      page: 1,
      import: info,
      get: getPayload,
    },
    nhapDanhMucLoaiDv,
    doGetListDmLoaiDv
  );
}
