import {createSlice} from "@reduxjs/toolkit";

const initState = {
    data: [],
    currentPage : 1,
    totalPage : 0,
    totalResult : 0,
    selectedDrug : undefined,
    loading : false,
    loadingImport : false
};

const DmThuocSlice = createSlice({
    name: "DmThuocSlice",
    initialState: initState,
    reducers: {
        getDataSource: (state ) => {
            state.loading = true
        },
        getDataSourceSuccess: (state, action) => {
            const { current_page, result, total} = action.payload;
            state.data = result;
            state.currentPage = current_page;
            state.totalResult = total
            state.loading = false
        },
        setSelectedDrug : (state, action) => {
            state.selectedDrug = action.payload;
        },
        addNewDrug: () => {},
        editDrug: () => {},
        deleteDrug: () => {},
        lockDrug: () => {},
        exportDrug : () => {},
        importDrug : (state ) => {
            state.loading = true;
        },
        updateLockDrug: (state, action) => {
           state.data = state.data.map(item => {
                if (action.payload.id.includes(item.ID)) {
                    return {...item, KHOA : action.payload.isLock}
                }
                return item;
           })
        },
    },
});

export const {
    getDataSource,
    getDataSourceSuccess, 
    addNewDrug,
    editDrug,
    deleteDrug,
    setSelectedDrug,
    lockDrug,
    updateLockDrug,
    exportDrug,
    importDrug
} = DmThuocSlice.actions

export default DmThuocSlice.reducer;