import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage/session";
import createSagaMiddleware from "@redux-saga/core";
import {
  registerInfoSlice,
  registerSlice,
  registerClinicSlice,
  authSlice,
  customerSlice,
  hospitalInformationSlice,
  DetailServicesSlice,
  ListOfServicesSlice,
  DmKhoThuocSlice,
  DmThuocSlice
} from "./duck/slices";
import DM_ChungReducer from "./duck/slices/DM_Chung";
import rootSaga from "./duck/saga/rootSaga";
import CommissionSlice from "./duck/slices/slices/commission.slice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  register: registerSlice,
  registerInfo: registerInfoSlice,
  registerClinic: registerClinicSlice,
  hospitalInformation: hospitalInformationSlice,
  auth: authSlice,
  customers: customerSlice,
  DM_Chung: DM_ChungReducer,
  commission: CommissionSlice,
  DetailServicesSlice: DetailServicesSlice,
  ListOfServicesSlice:ListOfServicesSlice,
  DmThuoc:DmThuocSlice,
  DmKhoThuoc: DmKhoThuocSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
  devTools: true,
});

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export default store;
