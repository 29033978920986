import {createSlice,current} from "@reduxjs/toolkit";

const initState = {
    data: [],
    currentPage: 1,
    totalResult : 0,
    detail: {},
    DsNhanSu: [],
    DsKhoChuyen: [],
    DsKhoPhong: [],
    reloadMessage: undefined,
};

const DmKhoThuocSlice = createSlice({
    name: "DmKhoThuoc",
    initialState: initState,
    reducers: {
        getDmKhoThuoc: () => {},
        getDataSuccess: (state, action) => {
            const {current_page, result, total} = action.payload;
            state.data = result
            state.currentPage = current_page
            state.totalResult = total
        },
        addKhoThuoc: () => {},
        editKhoThuoc: () => {},
        deleteKhoThuoc: () => {},
        lockDmKhoThuoc: () => {},
        updateKhoThuoc: (state, action) => {
            const updateLock = state.data.findIndex((id) => id.ID === action.payload.id.toString() )
            state.data[updateLock].KHOA = action.payload.isLock
        },
        getDetailKho: () => {},
        getDetailSuccess: (state, action) => {
            state.detail = action.payload.result
        },
        getDsNhanSu: () => {},
        getDsNhanSuSuccess: (state, action) => {
            state.DsNhanSu = action.payload.result
        },
        getDsKhoChuyen: () => {},
        getDsKhoChuyenSuccess: (state, action) => {
            state.DsKhoChuyen = action.payload.result
        },
        setReloadMessage: (state, action) => {
            state.reloadMessage = action.payload;
        },
        exportKhoThuoc: () => {},
        importKhoThuoc: () => {},
    },
});

export const {setReloadMessage,getDataSuccess, addKhoThuoc, deleteKhoThuoc, editKhoThuoc, getDmKhoThuoc, lockDmKhoThuoc, updateKhoThuoc,
    getDsNhanSu, getDsNhanSuSuccess, getDetailKho, getDetailSuccess, getDsKhoChuyenSuccess, getDsKhoChuyen,
    exportKhoThuoc, importKhoThuoc} = DmKhoThuocSlice.actions
export const DmKhoThuocData = (state) => state.DmKhoThuoc
export default DmKhoThuocSlice.reducer;