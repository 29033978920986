import { takeLatest, select } from "@redux-saga/core/effects";
import {
  handleCommonAdd,
  handleCommonDelete,
  handleCommonEdit,
  handleCommonExport,
  handleCommonGet,
  handleCommonImport,
  handleCommonLock,
} from "../commonSaga/commonSaga.js";
import {
  postAddKhoThuoc,
  postDeleteKhoThuoc,
  postEditKhoThuoc,
  postExportKhoThuoc,
  postGetDetailKho,
  postGetDmKhoThuoc,
  postGetDsNhanSu,
  postGetKhoChuyen,
  postImportKhoThuoc,
  postLockKhoThuoc,
} from "./apiDuoc.js";

// import {errorState} from "../../slices/errorSlice.js";
import { put } from "redux-saga/effects";
import {
  getDmKhoThuoc,
  getDataSuccess,
  addKhoThuoc,
  editKhoThuoc,
  deleteKhoThuoc,
  updateKhoThuoc,
  lockDmKhoThuoc,
  getDsNhanSuSuccess,
  getDsNhanSu,
  getDetailSuccess,
  getDetailKho,
  getDsKhoChuyenSuccess,
  getDsKhoChuyen,
  exportKhoThuoc,
  importKhoThuoc,
  setReloadMessage,
} from "../../slices/slices/DmKhoThuocSlice.js";
import { setFormLoading } from "../../slices/DM_Chung/DmMain.js";

export function* watchDoDmKhoThuoc() {
  yield takeLatest(getDmKhoThuoc, handleGetDmKhoThuoc);
  yield takeLatest(addKhoThuoc, handleAddKhoThuoc);
  yield takeLatest(editKhoThuoc, handleEditKhoThuoc);
  yield takeLatest(deleteKhoThuoc, handleDeleteKhoThuoc);
  yield takeLatest(lockDmKhoThuoc, handleLockKhoThuoc);
  yield takeLatest(getDetailKho, handleGetDetailKho);
  yield takeLatest(getDsNhanSu, handleGetDsNhanSu);
  yield takeLatest(getDsKhoChuyen, handleGetDsKhoChuyen);
  yield takeLatest(exportKhoThuoc, handleExportKhoThuoc);
  yield takeLatest(importKhoThuoc, handleImportKhoThuoc);
}

function* handleGetDmKhoThuoc(action) {
  const info = action.payload;
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonGet(
    {
      partner_code: dataHos.partner_code,
      BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
      page: info.page,
      search_string: info.search_string,
      limit: info.limit,
    },
    postGetDmKhoThuoc,
    getDataSuccess
  );
}

function* handleAddKhoThuoc(action) {
  const info = action.payload;
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonAdd(
    {
      add: {
        partner_code: dataHos.partner_code,
        BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
        ...info.add.data,
      },
      get: {
        partner_code: dataHos.partner_code,
        BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
        ...info.get,
      },
    },
    postAddKhoThuoc,
    getDmKhoThuoc
  );
}

function* handleEditKhoThuoc(action) {
  const info = action.payload;
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonEdit(
    {
      edit: {
        partner_code: dataHos.partner_code,
        BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
        ...info.edit.data,
        TRANG_THAI: 1,
      },
      get: {
        partner_code: dataHos.partner_code,
        BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
        ...info.get,
      },
    },
    postEditKhoThuoc,
    getDmKhoThuoc
  );
}

function* handleLockKhoThuoc(action) {
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonLock(
    {
      partner_code: dataHos.partner_code,
      BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
      ...action.payload,
    },
    postLockKhoThuoc,
    getDmKhoThuoc,
    updateKhoThuoc
  );
  // yield put(setReloadMessage('reload'));

}

function* handleDeleteKhoThuoc(action) {
  const info = action.payload;
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonDelete(
    {
      delete: {
        partner_code: dataHos.partner_code,
        BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
        arr_ID: info.delete.ID,
        NHANSU_ID: null,
      },
      get: {
        partner_code: dataHos.partner_code,
        BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
        ...info.get,
      },
    },
    postDeleteKhoThuoc,
    getDmKhoThuoc
  );
  yield put(setReloadMessage('reload'));
}

function* handleGetDetailKho(action) {
  try {
    yield put(setFormLoading(true));
    let dataHos = yield select(
      (state) => state.hospitalInformation.hospitalInfo
    );
    const res = yield postGetDetailKho({
      partner_code: dataHos.partner_code,
      BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
      ...action.payload,
    });
    yield put(getDetailSuccess(res));
  } catch (e) {
    // yield put(errorState(e.message))
  } finally {
    yield put(setFormLoading(false));
  }
}

function* handleGetDsNhanSu(action) {
  yield handleCommonGet(
    action.payload,
    postGetDsNhanSu,
    getDsNhanSuSuccess,
    false
  );
}

function* handleGetDsKhoChuyen(action) {
  yield handleCommonGet(
    action.payload,
    postGetKhoChuyen,
    getDsKhoChuyenSuccess,
    false
  );
}

function* handleExportKhoThuoc(action) {
  const info = action.payload;
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonExport(
    { partner_code: dataHos.partner_code, BENH_VIEN_ID: dataHos.BENH_VIEN_ID },
    postExportKhoThuoc,
    info.name
  );
}

function* handleImportKhoThuoc(action) {
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  const info = action.payload;
  yield handleCommonImport(
    {
      import: {
        DATA: info.import.data,
        partner_code: dataHos.partner_code,
        BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
      },
      get: {
        ...info?.get,
        partner_code: dataHos.partner_code,
        BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
      },
    },
    postImportKhoThuoc,
    getDmKhoThuoc
  );
}
