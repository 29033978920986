import React, { useEffect } from "react";
import style from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../utils/constant";

export const LoginSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window?.opener?.postMessage("LOGIN SUCCESS");
    }, 600);

    setTimeout(() => {
      navigate(paths.HOME);
    }, 700);
  }, [navigate]);

  return (
    <div className={style["login-success"]}>
      <div className={`${style["text-block"]} blue-txt`}>
        đã xác thực. đang chuyển hướng tới trang chủ...
      </div>
      ;
    </div>
  );
};
