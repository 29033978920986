import Apis from "../../constants/apis";
import { common_post } from "../../services/apiInstances";

// Hoa hồng
export const handleGetNhomNguonKhach = (dataRequest) => common_post(Apis.getNhomNguonKhach, dataRequest);
export const handleGetCommissionList = (dataRequest) => common_post(Apis.getCommissionList, dataRequest);
export const postCheck = (dataRequest) => common_post(Apis.dm_check_trang_DVHH, dataRequest);
export const lockDvXnApi = (dataRequest) => common_post(Apis.khoaDVXetNghiem, dataRequest);
export const postLayDsChiSoKemTheoDv= (dataRequest) => common_post(Apis.layDsChiSoKemTheoDv, dataRequest);
export const suaChiSoXN = (dataRequest) => common_post(Apis.sua_chi_so_xet_nghiem, dataRequest);
