import { ACCESS_TOKEN } from './constant';

export default {
  set(key, rawValue) {
    const isString = (value) => typeof value === 'string'
    const value = isString(rawValue) ? rawValue : JSON.stringify(rawValue);
  
    localStorage.setItem(key, value);
  },
  get(key) {
    const value = localStorage.getItem(key);
  
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  },
  
  remove(key) {
    localStorage.remove(key);
  },
  removeAll() {
    const arrLocalKey = Object.keys(localStorage)
    for (let i = 0; i < arrLocalKey.length; i++) {
        localStorage.removeItem(arrLocalKey[i]);
    }
  },
  
  setToken(value) {
    this.set(ACCESS_TOKEN, value);
  },
  getToken() {
    return this.get(ACCESS_TOKEN);
  },
};
  