import React from 'react';
import { convertJson } from '../../helpers';
import {
  layDSChuyenKhoa,
  layDSDichVuCLS,
  layDSDichVuCungCap,
  layDSPhuongXa,
  layDSQuanHuyen,
  layDsTinhThanh,
} from '../../services/register';

export const useThx = () => {
  const [dsTinhThanh, setDsTinhThanh] = React.useState([]);
  const [dsQuanHuyen, setDsQuanHuyen] = React.useState([]);
  const [dsPhuongXa, setDsPhuongXa] = React.useState([]);

  React.useEffect(() => {
    layDsTinh();
  }, []);

  const handleReset = () => {
    setDsPhuongXa([]);
    setDsQuanHuyen([]);
  };

  const layDsQuanHuyen = async ({ search_string = '', MA_TINH_THANH = '' }) => {
    let body = {
      limit: 100,
      search_string,
      MA_TINH_THANH,
    };
    let res = await layDSQuanHuyen(body);
    setDsQuanHuyen(res.result);
  };

  const layDsPhuongXa = async ({ search_string = '', MA_QUAN_HUYEN = '' }) => {
    let body = {
      limit: 100,
      page: 1,
      search_string: '',
      MA_QUAN_HUYEN,
    };
    let res = await layDSPhuongXa(body);
    setDsPhuongXa(res.result);
  };

  const layDsTinh = async (search_string = '') => {
    let body = {
      limit: 100,
      page: 1,
      search_string: '',
    };
    let res = await layDsTinhThanh(body);
    setDsTinhThanh(res.result);
  };

  const handleSelectConscious = async (conscious) => {
    let tinh = await convertJson(conscious);
    layDsQuanHuyen({ MA_TINH_THANH: tinh.MA_HANH_CHINH });
  };

  const handleSelectDistrict = async (district) => {
    let quan = await convertJson(district);

    layDsPhuongXa({ MA_QUAN_HUYEN: quan?.MA_HANH_CHINH });
  };

  return {
    dsPhuongXa,
    dsQuanHuyen,
    dsTinhThanh,
    handleSelectConscious,
    handleSelectDistrict,
    layDsQuanHuyen,
    layDsTinh,
    layDsPhuongXa,
    handleReset,
    setDsQuanHuyen,
    setDsPhuongXa,
  };
};

export const useDsDichVu = ({ cls = true, chuyenKhoa = true, dsDichVu = true }) => {
  const [dataChuyenKhoa, setDataChuyenKhoa] = React.useState([]);
  const [dsDichVuCls, setDsDichVuCls] = React.useState([]);
  const [dataService, setDataService] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  let LayDsChuyenKhoa = async (search_string = '') => {
    setLoading(true);
    try {
      let body = {
        limit: 100,
        page: 1,
        search_string,
      };
      let res = await layDSChuyenKhoa(body);

      if (res && res.status === 'OK') {
        setDataChuyenKhoa(res.result);
        // dispatch(fetchDataSpecialList(res.result));
      }
    } catch (error) {}
    setLoading(false);
  };

  let LayDsDichvuCLS = async (search_string = '') => {
    setLoading(true);
    try {
      let body = {
        limit: 100,
        page: 1,
        search_string,
      };
      let res = await layDSDichVuCLS(body);

      if (res && res.status === 'OK') {
        setDsDichVuCls(res.result);
      }
    } catch (error) {}
    setLoading(false);
  };

  React.useEffect(() => {
    chuyenKhoa && LayDsChuyenKhoa();
    cls && LayDsDichvuCLS();
    dsDichVu && layDanhSachDichVuCungCap();
  }, [chuyenKhoa, cls, dsDichVu]);

  const layDanhSachDichVuCungCap = async () => {
    setLoading(true);
    try {
      let body = {
        limit: 15,
        page: 1,
        search_string: '',
      };

      let res = await layDSDichVuCungCap(body);

      if (res && res.status === 'OK') {
        setDataService(res.result);
      }
    } catch (error) {}
    setLoading(false);
  };

  return {
    dsDichVuCls,
    dataChuyenKhoa,
    dataService,
    loading,
  };
};
