import i18n, { languageKeys } from "../i18n";
import { keys } from "../utils/constant";

export const MO_HINH_CSKCB  = [
    {
        key : "HUB",
        name: "Mô hình Hub"
    },
    {
        key : "CHUOI",
        name: "Mô hình chuỗi"
    },
    {
        key : "MOT_THANH_VIEN",
        name: "Mô hình một thành viên"
    }
];

export const TRANG_THAI_PK = [
    {
        key : "CHUA_KICH_HOAT",
        name: "Chưa kích hoạt"
    },
    {
        key : "DA_KICH_HOAT",
        name: "Đã kích hoạt"
    },
    {
        key : "TAM_DUNG",
        name: "Tạm dừng"
    },
    {
        key : "DA_HUY",
        name: "Đã hủy"
    }
]

export const TIEN_ICH_OPTIONS = [
    {
        key:"BHYT",
        name:"BHYT"
    },
    {
        key:"BH_BAO_LANH",
        name:"BH Bảo lãnh"
    },
    {
        key:"THE_SUC_KHOE",
        name:"Thẻ sức khỏe"
    },
    {
        key:"CHO_DO_XE",
        name:"Chỗ đỗ xe"
    },
];

export const DM_DOI_TUONG_NGUOI_DUNG = [
    {
      label: 'Nguồn khách',
      value: "NGUON_KHACH",
    },
    {
      label: 'Nguồn chỉ định',
      value: "NGUON_CHI_DINH",
    },
  ];

  export const danhSachGioiTinh2 = [
    { value: 'tat_ca', label: i18n.t(languageKeys.field_tat_ca) },
    { value: keys.gioi_tinh_NAM, label: i18n.t(languageKeys.gioi_tinh_Nam) },
    { value: keys.gioi_tinh_NU, label: i18n.t(languageKeys.gioi_tinh_Nu) },
  ];
  
  export const rangeAge = [
    {
      value: JSON.stringify({ FROM_TUOI: null, TO_TUOI: null}),
      label: i18n.t(languageKeys.field_tat_ca),
    },
    {
      value: JSON.stringify({ FROM_TUOI: 0, TO_TUOI: 1 }),
      label: i18n.t(languageKeys.underOneYearOld),
    },
    {
      value: JSON.stringify({ FROM_TUOI: 1, TO_TUOI: 2 }),
      label: i18n.t(languageKeys.oneToTwoYearsOld),
    },
    {
      value: JSON.stringify({ FROM_TUOI: 2, TO_TUOI: 6 }),
      label: i18n.t(languageKeys.twoToSixYearsOld),
    },
    {
      value: JSON.stringify({ FROM_TUOI: 6, TO_TUOI: 15 }),
      label: i18n.t(languageKeys.sixToTenYearsOld),
    },
    {
      value: JSON.stringify({ FROM_TUOI: 15, TO_TUOI: null }),
      label: i18n.t(languageKeys.overTenYearsOld),
    },
    {
      value: JSON.stringify({ FROM_TUOI: 60, TO_TUOI: null }),
      label: i18n.t(languageKeys.overSixtyYearsOld),
    },
  ];


  export const DATA_LOAI_KHO = [
    {
      key: "KHO_TONG",
      name: i18n.t(languageKeys.kho_tong),
    },
    {
      key: "KHO_LE",
      name: i18n.t(languageKeys.kho_le),
    },
    {
      key: "NHA_THUOC",
      name: i18n.t(languageKeys.nha_thuoc),
    },
    {
      key: "TU_TRUC",
      name: i18n.t(languageKeys.data_Tu_truc),
    },
  ];


  export const LOAI_BENH_NHAN_AP_DUNG = [
    {
      key: "BHYT",
      name: i18n.t(languageKeys.benh_nhan_bhyt),
    },
    {
      key: "NOI_TRU",
      name: i18n.t(languageKeys.benh_nhan_noi_tru),
    },
    {
      key: "NGOAI_TRU",
      name: i18n.t(languageKeys.benh_nhan_ngoai_tru),
    },
  ];

  export const lisenceModules = ["CIS", "LIS","BAO_CAO","DUOC","MANAGEMENT","PRM","RIS"];


  export const mappingDataExcel = {
    //khoa
    "Mã khoa": "MA_KHOA",
    "Tên khoa": "TEN_KHOA_PHONG",
    "Loại khoa": "LOAI_KHOA",
    "Ghi chú": "GHI_CHU",
    //phòng
    "Mã phòng": "MA_PHONG",
    "Tên phòng": "TEN_KHOA_PHONG",
    "Ghi chú": "GHI_CHU",
    "Mã loại phòng": "LOAI_PHONG",
    "Mã khoa": "MA_KHOA",
    "Giờ bắt đầu": "GIO_BAT_DAU",
    "Giờ kết thúc": "GIO_KET_THUC",

    //nhóm dịch vụ
    "Mã nhóm": "MA_NHOM",
    "Tên nhóm": "TEN_NHOM",
    "Nhóm chi phí BHYT": "MA_NHOM_BHYT",

    //loại dịch vụ
    "Mã loại": "MA_LOAI",
    "Tên loại": "TEN_LOAI",
    "Mã nhóm dịch vụ": "MA_NHOM_DV",
    "Mã nhóm BHYT": "MA_NHOM_BHYT",
    "Nhóm BHYT": "NHOM_BHYT",
    // dich vu
    "Mã dịch vụ": "MA_DICHVU",
    "Tên dịch vụ": "TEN_DICHVU",
    "Tên dịch vụ theo BYT": "TEN_DICHVU_BYT",
    "Mã loại dịch vụ": "MA_LOAI_DV",
    "Loại chỉ số": "LOAI_CHI_SO",
    "Mã phòng thực hiện": "MA_PHONG_THUC_HIEN",
    "Giá thu phí": "GIA_THU_PHI",
    "Tên chỉ số": "TEN_CHI_SO",
    "Giá trị cận trên": "CAN_TREN",
    "Giá trị cận dưới": "CAN_DUOI",
    "Mã dịch vụ theo TT13": " MA_THONG_TU_13",
    "Kiểu chỉ số": "KIEU_CHI_SO",
    "Thứ tự in": "STT_DV",
    "Giá trị mặc định":"KQ_MAC_DINH",
    "Giá trị lựa chọn":"TEN_LUA_CHON",
    // data

    "Nội trú": "NOI_TRU",
    "Khám bệnh": "KHAM_BENH",
    "Nội khoa": "NOI_KHOA",
    "Dược": "DUOC",
    "Tài chính": "TAI_CHINH",
    "Xét nghiệm": "XET_NGHIEM",
    "Chẩn đoán hình ảnh": "CDHA",
    "Nội trú": "NOI_TRU",
    "Khác": "KHAC",
    "Khám bệnh": "KHAM_BENH",
    "Viện phí": "VIEN_PHI",
    "Bảo hiểm y tế": "BHYT",
    "Lấy mẫu": "LAY_MAU",
    "Xét nghiệm": "XET_NGHIEM",
    "Chuẩn đoán hình ảnh": "CDHA",
    "Thủ thuật": "THU_THUAT",
    "Thăm dò chức năng": "TDCN",
    "Cân đo": "CAN_DO",
    "Khám bệnh": "KHAM_BENH",
    "Xét nghiệm": "XET_NGHIEM",
    "Chẩn đoán hình ảnh": "CDHA",
    "Thăm dò chức năng": "TDCN",

    "Thuốc": "THUOC",
    "Máu": "MAU",

    "Phẫu thuật": "PHAU_THUAT",
    "Vật tư y tế": "VTYT",
    "Vận chuyển": "VAN_CHUYEN",
    
    "Ngày giường bệnh ban ngày": "GIUONG_NGOAITRU",
    "Ngày giường bệnh điều trị nội trú": "GIUONG_NOITRU",
    "Ngày giường lưu": "NGAY_GIUONG_LUU",
    "Chế phẩm máu": "CHE_PHAM_MAU",
    "Thủ thuật": "THU_THUAT",
    "Gói dịch vụ": "GOI_DICH_VU",
    "Combo dịch vụ": "COMBO_DV",
    "Mã nhóm dịch vụ":"MA_NHOM_DV",
    "Tên loại dịch vụ":"TEN_LOAI",
    "Tên dịch vụ BYT":"TEN_DICHVU_BHYT",
    "Thứ tự":"THU_TU",
    "Mã thuốc":"MA_THUOC",
    "Mã thuốc BHYT":"MA_THUOC_BHYT",
    "số tt BHYT":"STT_BHYT",
    "Tên thuốc":"TEN_THUOC",
    "Tên thuốc BHYT":"TEN_THUOC_BHYT",
    "Mã đơn vị tính":"MA_DVT",
    "Hàm lượng":"HAM_LUONG",
    "Nồng độ":"NONG_DO",
    "Thể tích":"THE_TICH",
    "Mã hoạt chất":"MA_HOAT_CHAT",
    "Nước sản xuất":"TEN_NUOC_SAN_XUAT",
    "Mã vạch":"MA_VACH",
    "Số đăng ký thuốc":"SO_DANG_KI",
    "Loại thuốc":"LOAI_THUOC",
    "Thuốc thường":"THUOC_THUONG",
    "Thuốc nhà thuốc":"THUOC_NHA_THUOC",
    "Mã nhóm thuốc":"MA_NHOM_THUOC",
    "Mã loại thuốc":"MA_LOAI_THUOC",
    "Hãng sản xuất":"MA_HANG_SAN_XUAT",
    "Quy cách đóng gói":"QUYCACH_DONGGOI",
    "Mã ATC":"ATC_CODE",
    "Nhóm chi phí BHYT":"MA_NHOM_CHIPHI_BHYT",
    "Thuốc kê đơn":"IS_THUOC_KE_DON",
    "Thuốc kháng sinh":"IS_THUOC_KHANG_SINH",
    "Thuốc tân dược":"IS_THUOC_TAN_DUOC",
    "Vị thuốc y học cổ truyền":"IS_THUOC_YHCT",
    "Chế phẩm y học cổ truyền":"IS_CHEPHAM_YHCT",
    "Thực phẩm chức năng":"IS_THUC_PHAM_CHUC_NANG",
    "Cho phép miễn giảm": 'IS_CHO_PHEP_MIEN_GIAM',
    "Giá nhập":"GIA_NHAP",
    "Giá bán":"GIA_BAN",
    "Chỉ số thường":"CHI_SO_THUONG",
    "Nhóm chỉ số":"NHOM_CHI_SO",
    "Có": 1,
    "Không": 0,

  };