export const fieldDonViTinh = {
  id: "ID_DVT",
  ten_dvt: "TEN_DVT",
  ma_dvt: "MA_DVT",
  ghi_chu: "GHI_CHU",
  khoa: "KHOA",
  ngay_khoi_tao: "NGAY_KHOI_TAO",
  stt: "STT",
};

export const fieldDoiTuong = {
  id: "ID",
  ma_doi_tuong: "MA_DOI_TUONG",
  loai_doi_tuong: "LOAI_DOI_TUONG",
  ten_doi_tuong: "TEN_DOI_TUONG",
  thu_tu: "THU_TU",
  ghi_chu: "GHI_CHU",
  dung_cho_cis: "DUNG_CHO_CIS",
  benh_vien_id: "BENH_VIEN_ID",
  khoa: "KHOA",
  trang_thai: "TRANG_THAI",
};

export const fieldNhomDoiTuongNguoiDung = {
  id: "ID",
  ma_doi_tuong_nguoi_dung: "MA_DM_NHOM_NGUON_KHACH",
  ten_doi_tuong_nguoi_dung: "TEN_DM_NHOM_NGUON_KHACH",
  ghi_chu: "GHI_CHU",
  benh_vien_id: "BENH_VIEN_ID",
  khoa: "KHOA",
  trang_thai: "TRANG_THAI",
  ten_doi_tuong: "TEN_DOI_TUONG",
};

export const fieldDmNguonKhach = {
  id: "ID",
  ma_nguon_khach: "MA_NGUON_KHACH",
  ten_nguon_khach: "TEN_NGUON_KHACH",
  ten_doi_tuong: "VT_ID",
  nhom_dt_nguoi_dung: "DM_NHOM_NGUON_KHACH_ID",
  ten_nhom_dt_nguoi_dung: "TEN_DM_NHOM_NGUON_KHACH",
  ghi_chu: "GHI_CHU",
  khoa: "KHOA",
  nhan_su_id: "NHANSU_ID",
};

export const fieldDmHinhThucTT = {
  id: "ID",
  stt: "THU_TU",
  ten_hinh_thuc_thanh_toan: "TEN_HINH_THUC_THANH_TOAN",
  ghi_chu: "GHI_CHU",
  khoa: "KHOA",
};

export const fieldKhoa = {
  id: "ID",
  ma_khoa: "MA_KHOA",
  ten_khoa: "TEN_KHOA_PHONG",
  ma_khoa_theo_byt: "MA_CHUYEN_KHOA",
  ma_byt: "MA_BYT",
  truong_khoa: "TEN_TRUONG_KHOA",
  loai_khoa: "LOAI_KHOA",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  id_khoa_cua_ds_byt: "ID",
  ten_khoa_cua_ds_byt: "CHUYEN_KHOA",
};

export const fieldPhong = {
  id: "ID",
  ma_phong: "MA_PHONG",
  ten_phong: "TEN_KHOA_PHONG",
  khoa: "MA_KHOA",
  loai_phong: "LOAI_PHONG",
  phong_chi_dinh: "PHONG_CHI_DINH",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  ten_khoa: "TEN_KHOA",
  ten_phong_chi_dinh: "TEN_PHONG_CHI_DINH",
  so_phong_chi_dinh: "SO_PHONG_CHI_DINH",
  ma_phong_duoc_chi_dinh: "MA_PHONG_DUOC_CHI_DINH",
  khoa_id: "KHOA_ID",
  gio_lam_viec: "GIO_LAM_VIEC",
  gio_bat_dau: "GIO_BAT_DAU",
  gio_ket_thuc: "GIO_KET_THUC",
};

export const fieldNhomDv = {
  id: "ID",
  ma_nhom: "MA_NHOM",
  ten_nhom: "TEN_NHOM",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
  nhom_bhyt: "NHOM_BHYT",
  ten_nhom_bhyt: "TEN_NHOM_BHYT",
  nguoi_sua: "NGUOI_SUA",
};

export const fieldLoaiDv = {
  id: "ID",
  stt: "STT_LDV",
  id_loai_dv_ki_thuat: "MA_LOAI",
  nhom_dv_ki_thuat: "NHOM_DV_ID",
  ten_loai_dv_ki_thuat: "TEN_LOAI",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
  ten_nhom_dvkt: "TEN_NHOM_DICH_VU",
  nhom_bhyt: "NHOM_BHYT",
  ten_nhom_bhyt: "TEN_NHOM_BHYT",
  nguoi_sua: "NGUOI_SUA",
  phan_loai: "PHAN_LOAI",
};

export const fieldDichVu = {
  id: "DV_ID",
  stt: "STT_DV",
  ma_dich_vu: "MA_DICHVU",
  ten_dich_vu: "TEN_DICHVU",
  ten_dich_vu_theo_byt: "TEN_DICHVU_BHYT",
  nhom_dich_vu: "NHOM_DV_ID",
  ten_nhom_dvkt: "TEN_NHOM_DICH_VU",
  loai_dich_vu: "LOAI_DV_ID",
  ten_loai_dvkt: "TEN_LOAI_DICH_VU",
  nhom_hoa_don: "NHOM_HOADON_ID",
  ten_nhom_hoa_don: "TEN_NHOM_HOA_DON",
  gia_thu_phi: "GIA_THU_PHI",
  gia_bhyt: "GIA_BHYT",
  gia_dich_vu: "GIA_DICHVU",
  gia_nuoc_ngoai: "GIA_NUOC_NGOAI",
  ti_le_dung_tuyen: "TYLE_DUNG_TUYEN",
  ti_le_trai_tuyen: "TYLE_TRAI_TUYEN",
  so_ngay_tai_chi_dinh: "SO_NGAY_TAI_CHI_DINH",
  phong_thuc_hien: "LIST_ID_PHONG_THUC_HIEN",
  phong_lay_mau: "LIST_ID_PHONG_LAY_MAU",
  cho_phep_mien_giam: "MIEN_GIAM",
  cho_sua_gia_khi_chi_dinh: "SUA_GIA_KHI_CHI_DINH",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
  chi_so_xet_nghiem: "chi_so_xet_nghiem",
  don_vi_chi_so: "don_vi_chi_so",
  giai_ket_qua_chi_so: "giai_ket_qua_chi_so",
  don_vi_tinh: "ID_DVT",
  ten_don_vi_tinh: "TEN_DVT",
  nhom_bhyt: "NHOM_BHYT_ID",
  ten_nhom_bhyt: "TEN_NHOM_BHYT",
  nhom_cls: "NHOM_CLS",
  loai_thu_thuat: "LOAI_THU_THUAT",
  hoa_hong: "HOA_HONG",
  DV_KEM_THEO: "DV_KEM_THEO",
  DV_KEM_THEO_ID: "DV_KEM_THEO_ID",
  PHONG_THUC_HIEN_ID: "PHONG_THUC_HIEN_ID",
  TEN_THONG_TU_13:"TEN_THONG_TU_13",
  MA_THONG_TU_13:"MA_THONG_TU_13",
  LOAI_CHI_SO:"LOAI_CHI_SO",
  MIEN_GIAM: "MIEN_GIAM",
  phan_loai: "PHAN_LOAI",
};

export const fieldCTTT = {
  ID: "ID",
  MA_CACHTHUC_THUTHUAT: "MA_CACHTHUC_THUTHUAT",
  TEN_CACHTHUC_THUTHUAT: "TEN_CACHTHUC_THUTHUAT",
  GHI_CHU: "GHI_CHU",
  KHOA: "KHOA",
  hien_thi: "KHOA",
};

export const fieldPPTT = {
  ID: "ID",
  MA_PHUONGPHAP_THUTHUAT: "MA_PHUONGPHAP_THUTHUAT",
  TEN_PHUONGPHAP_THUTHUAT: "TEN_PHUONGPHAP_THUTHUAT",
  GHI_CHU: "GHI_CHU",
  KHOA: "KHOA",
  hien_thi: "KHOA",
};

export const fieldThietBiMay = {
  id: "ID",
  ma_thiet_bi: "MA_THIET_BI",
  ten_thiet_bi: "TEN_THIET_BI",
  ghi_chu: "GHI_CHU",
  ma_may: "MA_MAY",
  khoa: "KHOA",
};

export const fieldDuLieuDieuKhienMay = {
  id: "ID",
  ten_thong_so_may: "TEN_THONG_SO_MAY",
  ten_KQ_XN_chuan: "TEN_KQ_XN_CHUAN",
  don_vi: "DON_VI",
  so_lam_tron: "SO_LAM_TRON",
  tb_nam: "TB_NAM",
  tb_nu: "TB_NU",
  ghi_chu: "GHI_CHU",
};

export const fieldHangSx = {
  id: "ID",
  ma_hang_sx: "MA_HANG_SX",
  ten_hang_sx: "TEN_HANG_SX",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

export const fieldDuongDung = {
  id: "ID",
  ma_duong_dung: "ma_duong_dung",
  ma_duong_dung_theo_byt: "MA_DUONG_DUNG_BYT",
  ten_duong_dung: "TEN_DUONG_DUNG_BYT",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

export const fieldHoatChat = {
  id: "ID",
  ma_hoat_chat: "MA_HOAT_CHAT",
  ma_hoat_chat_bhyt: "MA_HOAT_CHAT_BYT",
  ten_hoat_chat: "TEN_HOAT_CHAT",
  duong_dung: "ID_DUONG_DUNG_BYT",
  ten_duong_dung: "TEN_DUONG_DUNG_BYT",
  IS_YC_HOI_CHAN: "IS_YC_HOI_CHAN",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

export const fieldXuatKhac = {
  id: "ID",
  ma_ly_do: "MA_LY_DO",
  ly_do: "LY_DO",
  hien_thi: "KHOA",
};

export const fieldNguon = {
  id: "ID",
  ma_nguon: "MA_NGUON",
  ten_nguon: "TEN_NGUON",
  hien_thi: "KHOA",
};

export const fieldCachDung = {
  id: "ID",
  stt: "THU_TU",
  ten_cach_dung: "TEN_CACH_DUNG",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
};

export const fieldDmMayThucHien = {
  id: "ID",
  ma_may: "MA_MAY",
  ten_may: "TEN_MAY",
  ma_bhyt: "MA_BHYT",
  loai_may: "LOAI_MAY",
  hien_thi: "KHOA",
};

export const fieldPhongkhamVeTinh = {
  id: "id",
  TEN_CSKCB: "TEN_CSKCB",
  MA_CSKCB: "MA_CSKCB",
  LOAI_HINH: "LOAI_HINH_CSKCB",
  DIA_CHI: "DIA_CHI_CHI_TIET",
};

export const fieldsDmLoaiGoiDv = {
  id: "ID",
  ma_goi: "MA_LOAI_GOI",
  ten_goi: "TEN_LOAI_GOI",
  mo_ta: "MO_TA",
  hien_thi: "KHOA",
};
