import { all, fork } from "redux-saga/effects";
import rootDMC from "./DM_Chung/rootDMC";
import { watchCommission } from "./commonSaga/commissionSaga";
import { watchDetailServices } from "./LIS/DetailServicesSaga";
import { watchDoDmKhoThuoc } from "./DuocSaga/DmKhoThuocSaga";
import { watchDoDmThuoc } from "./DuocSaga/DmThuocSaga";
import { watchHospital } from "./hospitals/hospital.saga";

export default function* rootSaga() {
  yield all([
    fork(rootDMC),
    fork(watchCommission),
    fork(watchDetailServices),
    fork(watchDoDmThuoc),
    fork(watchDoDmKhoThuoc),
    fork(watchHospital),

  ]);
}
