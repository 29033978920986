import React from "react";
import { LeftOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, Drawer, Row, Spin, Tooltip } from "antd";
import formDrawer from "./formDrawer.module.scss";
import cn from "classnames";
import { Delete, NewBackIcon } from "../../assets/images/icons";

const FormDrawer = ({
  children,
  back = true,
  onClickBack = () => { },
  width = 800, // Chiều rộng của Drawer
  title = "Form Drawer", // Tiêu đề của Drawer, có thể là string hoặc Component
  onOk = () => { }, // Hàm xử lý khi bấm OK
  onCancel = () => { }, // Hàm xử lý khi bấm Cancel
  okText = (
    <>
      {'Lưu'}
      {" (Ctrl + S)"}
    </>
  ), // Text của nút OK
  cancelText ='Thoát', // Text của nút Cancel
  deleteText = "Xóa", // Text của nút Xoá
  showPlusIcon = false, // Hiển thị icon dấu cộng trước tiêu đề (VD là trường hợp tạo mới)
  visible = false, // Hiển thị modal
  pending = false,
  loading = false, // Loading nút submit
  addOnActions,
  footer,
  footerClass = "",
  hiddenTopAction = false,
  deleteTopAction = false,
  buttonsAtBottomOfContent = false,
  className = "",
  headerClass = "",
  addBtnAction = () => { }, // Hiển thị hành động thêm nút
  addBtnText = "In phiếu", // hiển thị nút thêm
  disableAddBtn = true,
  disableOkBtn = false,
  disableWithoutTooltip = false,
  enableTopBtn = true,
  addBtn = false,
  maskClosable = true,
  fixedFooter = false,
  ...props
}) => {
  return (
    <Drawer
      open={visible}
      width={width}
      closable={false}
      maskClosable={maskClosable}
      onClose={onCancel}
      className={className}
      {...props}
      headerStyle={{ background: "#f3f6f9" }}
      title={
        typeof title === "string" ? (
          <Row justify="space-between" align="middle" className={cn(formDrawer["header"], headerClass)} wrap={false}>
            {/* =================== Tiêu đề Drawer =================== */}
            <Row align="middle" gutter={10}>
              {showPlusIcon && (
                <Col>
                  <PlusCircleFilled className={formDrawer["icon"]} />
                </Col>
              )}
              {back && (
                <Col style={{ paddingTop: 3 }}>
                  <NewBackIcon className={formDrawer["icon"]} onClick={onCancel} style={{ cursor: "pointer" }} />
                </Col>
              )}

              <Col>
                <h1 style={{ marginBottom: 0 }}>{title}</h1>
              </Col>
            </Row>

            <Row gutter={10} align="middle">
              {!!addOnActions && <Col>{addOnActions}</Col>}

              {!hiddenTopAction && deleteTopAction && (
                <Col className={formDrawer["button-row"]}>
                  {/* =================== Nút Cancel =================== */}
                  <Button
                    type="primary"
                    ghost
                    onClick={onCancel}
                    style={{
                      backgroundColor: "#ED6B69",
                      color: "#FFF",
                      border: "0",
                    }}
                    icon={<Delete style={{ marginRight: "8px" }} />}
                    className={formDrawer["button"]}
                  >
                    {deleteText}
                  </Button>
                </Col>
              )}

              {enableTopBtn && !buttonsAtBottomOfContent && !hiddenTopAction && (
                <Col>
                  {/* =================== Nút Cancel =================== */}
                  <Button type="primary" ghost onClick={onCancel} className={formDrawer["button"]}>
                    {cancelText}
                  </Button>
                </Col>
              )}

              {enableTopBtn && !buttonsAtBottomOfContent && !hiddenTopAction && (
                <Col>
                  {/* =================== Nút OK =================== */}
                  <Button type="primary" loading={loading} onClick={onOk} className={formDrawer["button"]}>
                    {okText}
                  </Button>
                </Col>
              )}
            </Row>
          </Row>
        ) : (
          title
        )
      }
      footer={footer ? footer : fixedFooter && (
        <Row gutter={10} align="middle" justify="end" style={{ padding: "7px 45px" }}>
          <Col>
            <Button type="primary" ghost onClick={onCancel} className={formDrawer["button"]}>
              {cancelText} (ESC)
            </Button>
          </Col>
          <Col>
            <Tooltip title={disableOkBtn ? 'Không có quyền' : null}>
              <Button
                type="primary"
                loading={loading}
                onClick={onOk}
                className={formDrawer["button"]}
                disabled={disableOkBtn || disableWithoutTooltip}
              >
                {okText}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      )}
    >
      <div className={formDrawer["container"]}>{children}</div>
      {/* <Button icon={<LeftOutlined />} className={formDrawer["backBtn"]} /> */}

      {buttonsAtBottomOfContent && (
        <Row
          gutter={10}
          align="middle"
          justify="end"
          style={{ padding: "15px 25px", margin: "0px" }}
          className={footerClass}
        >
          {/* {!!addOnActions && <Col>{addOnActions}</Col>} */}

          {!hiddenTopAction && (
            <Col>
              {/* =================== Nút Cancel =================== */}
              <Button type="primary" ghost onClick={onCancel} className={formDrawer["button"]}>
                {cancelText} (ESC)
              </Button>
            </Col>
          )}

          {!hiddenTopAction && (
            <Col>
              {/* =================== Nút OK =================== */}
              <Tooltip title={disableOkBtn ? 'Không có quyền' : null}>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={onOk}
                  className={formDrawer["button"]}
                  disabled={disableOkBtn || disableWithoutTooltip}
                >
                  {okText}
                </Button>
              </Tooltip>

            </Col>
          )}
          {addBtn && (
            <Col>
              {/* =================== Nút Thêm =================== */}
              <Button
                type="primary"
                loading={loading}
                onClick={addBtnAction}
                className={formDrawer["button"]}
                disabled={disableAddBtn}
              >
                {addBtnText}
              </Button>
            </Col>
          )}
        </Row>
      )}
      {pending && (
        <div className={formDrawer["pending_screen"]}>
          <Spin />
        </div>
      )}
    </Drawer>
  );
};

export default FormDrawer;
