const apiServices = {
  enable_app_log: true, //ẩn, hiện log
  URL_CLINIC40_API_1: `${window.GATEWAY || ""}` + "clinic40/",// api môi trường dev
  CIS_WEB_UI_URL: window.CIS_WEB_UI_URL,
  LANDING_PAGE:"https://clinic40.vn",

  GATEWAY_LIS: window.GATEWAY + "lis/",
  GATEWAY_CIS: window.GATEWAY + "cis/",
  GATEWAY_CLINIC_40: window.GATEWAY + "clinic40/",
  //KEYCLOAK
  KEYCLOAK_CLIENT_ID: window.KEYCLOAK_CLIENT_ID,
  KEYCLOAK_URL: window.KEYCLOAK_URL,
  KEYCLOAK_REALM: window.KEYCLOAK_REALM,
};

export default apiServices;