import { Button, Space } from "antd";
import { useNavigate } from "react-router-dom";
import React, { memo, useRef, useState } from "react";
import InitComponent from "./features";
import { RegisterContentLeft } from "../Components/RegisterContentLeft";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
export const stepProgress = {
  INIT: "INIT",
  INFO: "INFO",
  END: "END",
};

const { HeaderInitClinic, InitClinic, InitInfo, InitEnd } = InitComponent;

const RegisterClinic = () => {

    const { loading } = useSelector((state) => state.registerClinic);

  const initRef = useRef();
  const infoRef = useRef();
  const [step, setStep] = useState(stepProgress.INIT);
  const navigate = useNavigate();

  const renderStep = () => {
    switch (step) {
      case stepProgress.INIT:
        return <InitClinic ref={initRef} />;
      case stepProgress.INFO:
        return (
          <InitInfo
            ref={infoRef}
            onNextStep={() => {
              handleNextStep();
            }}
          />
        );
      case stepProgress.END:
        return <InitEnd />;
      default:
        break;
    }
  };
  const renderHeading = () => {
    if (step === stepProgress.INFO) {
      return "Thông tin liên hệ";
    } else if (step === stepProgress.END) {
      return "";
    } else {
      return "Khởi tạo thông tin phòng khám";
    }
  };

  const handleGoToCis = () => {
    // window.location.href = `${window.CIS_WEB_UI_URL}/auth/dang-nhap?info=4ea5c508a6566e76240543f8feb06fd457777be39549c4016436afda65d2330e`;
    window.location.href = window.CIS_WEB_UI_URL;
  };

  const handleNextStep = async (to = 1) => {
    if (step === stepProgress.END) {
      handleGoToCis();
    }
    const nextStep = () => {
      const index = Object.values(stepProgress).findIndex((cs) => {
        return cs === step;
      });
      return Object.values(stepProgress)[index + to];
    };
    if (to === -1) {
      if (step === stepProgress.INIT) {
        navigate(-1);
        return;
      }
      setStep((c) => nextStep());
      return;
    }
    setStep((c) => nextStep());
  };

  const handleTriggerStep = async () => {
    switch (step) {
      case stepProgress.INIT:
        let checkInit = await initRef.current?.submit();
        checkInit && handleNextStep(1);
        break;
      case stepProgress.INFO:
        //only call next step
        await infoRef.current.submit();
        break;
      case stepProgress.END:
        handleGoToCis()
        break;

      default:
        break;
    }
  };

  return (
    <div className="register">
      {/* <div className='responsive-text-content'>
      {"Chuyển đổi số\ntoàn diện\nphòng khám 4.0"}
      </div> */}
      <RegisterContentLeft />
      <div
        style={{ width: "100%", background: "#F6F8FE", position: "relative" }}
      >
        <div className={style["header"]}>
          <HeaderInitClinic step={step} stepProps={stepProgress} />
        </div>
        <div className={`${style["wrapper-container"]} style-scroll-bar`}>
          <div className={style["container"]}>
            <h2 style={{ fontSize: 32, marginBottom: 32 }}>
              {renderHeading()}
            </h2>
            {renderStep()}
          </div>
        </div>
        <div className={style["footer"]}>
          <Space
            gutter={10}
            align="center"
            className={style["footer-group-btn"]}
          >
            {![stepProgress.END,stepProgress.INIT].includes(step) ? (
              <Button
                className={style["footer-btn"]}
                type="primary"
                ghost
                danger = {!![stepProgress.INFO,stepProgress.INIT].includes(step)}
                block
                onClick={() => handleNextStep(-1)}
              >
                {step === stepProgress.INFO ? 'Quay lại' : 'Hủy'}
              </Button>
            ) : (
              <></>
            )}
            <Button
              className={style["footer-btn"]}
              type="primary"
              block
              loading={loading}
              onClick={handleTriggerStep}
            >
              {" "}
              {step !== stepProgress.END ? "Tiếp theo" : "Bắt đầu sử dụng"}
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default memo(RegisterClinic);
