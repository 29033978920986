import React from "react";
import { RegisterSuccess } from "../../../assets/images";
import style from "./style.module.scss";

export const InitEnd = ({ description }) => {
  const defaultDesc = (
    <span>
      Vui lòng kiểm tra email của bạn để biết thêm thông tin. Chúng tôi sẽ liên hệ
      với bạn trong khoảng từ 1-2 ngày tới.
    </span>
  );
  return (
    <div className={style["init-end"]}>
      <img className={style["init-end-img"]} src={RegisterSuccess} alt="" />
      <div className={style["heading-end"]}>
        <h2>Chúc mừng bạn đã khởi tạo thành công!</h2>
      </div>
      {description || defaultDesc}
    </div>
  );
};
