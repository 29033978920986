import { all, fork } from "redux-saga/effects";
import {watchDoDmNhomDT} from "./dmNhomDTSaga";
import { watchDoDmKhoa } from "./dmKhoa";
import { watchDoDmPhong } from "./dmPhong";
import { watchDoDmNhomDv } from "./dmNhomDv";
import { watchDoDmLoaiDv } from "./dmDmLoaiDv";

export default function* rootDMC() {
    yield all([
        fork(watchDoDmNhomDT),
        fork(watchDoDmKhoa),
        fork(watchDoDmPhong),
        fork(watchDoDmNhomDv),
        fork(watchDoDmLoaiDv),

    ])
}