import React, { Fragment, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout, Row, Button, Col } from 'antd';
import MainSider from './MainSider';
import { common_post } from '../services/apiInstances';
import { Apis } from '../constants';

import { useDispatch } from "react-redux"
import { updateUser } from '../duck/slices/auth.slice';
import { WarningModal } from '../components';
import { useLogout } from "../hooks/useLogout";
import _ from "lodash";
import { convertKeys, danhSachLoaiKhoa } from '../helpers';
import { mappingDataExcel } from '../constants/data';

export const AdminLayout = () => {
  let { handleLogout } = useLogout();
  const warningRef = React.useRef();
  const dispatch = useDispatch();
  React.useEffect(() => {
    getMe();
  },[]);

  const getMe = async () => {
    
    try {
      let rs = await common_post(Apis.getMe,{});
        if(rs?.status === 'OK'){
        console.log(`%cDeepcare check me status : OK`,'color:red');
        dispatch(updateUser(rs.result));
      } else {
        if(rs && rs.status === 'KO') {
          warningRef?.current?.open()
        }
      }
    } catch (error) {
      console.log(error);
      warningRef?.current?.open()
    }
  }

  return (
    <Fragment>
      <Layout>
        <MainSider/>
        <Layout>
            <div className='main-wrapper'>
            <Suspense fallback={null}>
                <Outlet />
            </Suspense>
        </div>
        </Layout>
      </Layout>
      <WarningModal maskClosable={false} title={"Cảnh báo"} ref={warningRef} content='Tài khoản của bạn không đủ thẩm quyền để sử dụng tính năng này'>
        <Row justify={'center'} gutter={20} style={{marginTop:20}}>
          {/* <Col span={8}>
            <Button block type='primary' danger ghost>Huỷ</Button>
          </Col> */}
          <Col span={8}>
            <Button block type='primary' danger onClick={handleLogout}>Đăng xuất</Button>
          </Col>
        </Row>
      </WarningModal>
    </Fragment>


  );
};

export default AdminLayout;
