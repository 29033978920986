import React,{ useEffect, useState } from 'react';
import { Steps } from '../../../components';
import { stepProgress } from '../../RegisterClinic';
import style from "./style.module.scss"

const stepItems = [
  {
    title: <div style={{marginTop:-50}}>Khởi tạo thông tin</div>,
    description: '',
  },
  {
    title: <div style={{marginTop:-50}}>Thông tin liên hệ</div>,
    description: '',
  },
  {
    title: <div style={{marginTop:-50, width: 200}}>Xác nhận thành công</div>,
    description: '',
  },
];

const HeaderInitClinic = ({step,stepProps,optionSteps = stepItems, stepProgressive = stepProgress}) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const numStep = Object.values(stepProgressive).findIndex((i)=> i === step);
    console.log(step,'numStepnumStepnumStep',stepProgressive);
  setCurrentStep(numStep)
  },[step])

  return (
    <div className={style['initClinic-header']}>
      <Steps items={optionSteps} current={currentStep} className={style['step']} />
    </div>
  );
};

export default HeaderInitClinic