import { put, takeLatest } from "redux-saga/effects";
import { fieldDoiTuong, fieldKhoa } from "../../../pages/ClinicManagement/Main/Catalog/GroupCatalogs/GeneralCatalogries/fieldsCatalog.js";
import {
  doCreateDmKhoa,
  doDeleteDmKhoa,
  doGetListDmKhoa,
  doSetListDmKhoa,
  doUpdateDmKhoa,
  lockDmKhoa,
  updateKhoa,
  doExportDmKhoa,
  doImportDmKhoa,
} from "../../slices/DM_Chung/DmKhoa.js";
import {
  handleCommonAdd,
  handleCommonDelete,
  handleCommonEdit,
  handleCommonExport,
  handleCommonGet,
  handleCommonImport,
  handleCommonLock,
} from "../commonSaga/commonSaga.js";
import {
  dispatchAddDmKhoa,
  dispatchDeleteDmKhoa,
  dispatchGetListDmKhoa,
  dispatchLockDmKhoa,
  dispatchUpateDmKhoa,
  nhapDanhMucKhoa,
} from "./apiDM_Chung.js";
import Apis from "../../../constants/apis.js";
import { convertKeys } from "../../../helpers.js";
import { mappingDataExcel } from "../../../constants/data.js";

export function* watchDoDmKhoa() {
  yield takeLatest(doGetListDmKhoa, handleGetListDmKhoa);
  yield takeLatest(doCreateDmKhoa, handleCreateDmKhoa);
  yield takeLatest(lockDmKhoa, handleLockDmKhoa);
  yield takeLatest(doDeleteDmKhoa, handleDeleteDmKhoa);
  yield takeLatest(doUpdateDmKhoa, handleUpdateDmKhoa);
  yield takeLatest(doExportDmKhoa, handleExportDmKhoa);
  yield takeLatest(doImportDmKhoa, handleImportDmKhoa);
}

function* handleGetListDmKhoa(action) {
  try {
    const info = action.payload;

    yield handleCommonGet({ ...info }, dispatchGetListDmKhoa, doSetListDmKhoa);
  } catch (error) {
    console.log(error);
  }
}

function* handleCreateDmKhoa(action) {
  const info = action.payload;
  yield handleCommonAdd(
    {
      add: {
        ...info.add.data,
        MA_CHUYEN_KHOA: "DA_KHOA_CHUYEN_KHOA",
      },
      get: info.get,
    },
    dispatchAddDmKhoa,
    doGetListDmKhoa
  );
  //   yield common_post(Apis.themDMKhoa, { ...info });
}

function* handleUpdateDmKhoa(action) {
  const info = action.payload;

  yield handleCommonEdit(
    {
      edit: {
        ...info.edit.data,
        MA_CHUYEN_KHOA: "DA_KHOA_CHUYEN_KHOA",
      },
      get: info.get,
    },
    dispatchUpateDmKhoa,
    doGetListDmKhoa
  );
}

function* handleLockDmKhoa(action) {
  const info = action.payload;

  yield handleCommonLock(
    {
      arr_ID: info.data,
      KHOA: info.lockVal,
      get: info.get,
      ...info,
    },
    dispatchLockDmKhoa,
    doGetListDmKhoa,
    updateKhoa
  );
}

function* handleDeleteDmKhoa(action) {
  const info = action.payload;

  yield handleCommonDelete(
    {
      delete: {
        arr_ID: Array.isArray(info.delete.data)
          ? info.delete.data.map((item) => item[fieldDoiTuong.id])
          : [info.delete.data[fieldDoiTuong.id]],
        partner_code: info.delete.partner_code,
        BENH_VIEN_ID: info.delete.BENH_VIEN_ID,
      },
      get: info.get,
    },
    dispatchDeleteDmKhoa,
    doGetListDmKhoa
  );
}

function* handleExportDmKhoa(action) {
  const info = action.payload;
  yield handleCommonExport(
    {
      template: 0,
      partner_code: info.partner_code,
      BENH_VIEN_ID: info.BENH_VIEN_ID,
      limit: 999,
      page: 1,
    },
    dispatchGetListDmKhoa,
    info.name
  );
}
function* handleImportDmKhoa(action) {
  const info = action.payload;
  info.data = yield convertKeys(info.data,mappingDataExcel,['Loại khoa'])
  let getPayload = !!(info && info.reload)
    ? {
        ...info.reload,
        partner_code: info.partner_code,
        BENH_VIEN_ID: info.BENH_VIEN_ID,
      }
    : { partner_code: info.partner_code, BENH_VIEN_ID: info.BENH_VIEN_ID };
  yield handleCommonImport(
    {
      template: 0,
      partner_code: info.partner_code,
      BENH_VIEN_ID: info.BENH_VIEN_ID,
      limit: 999,
      page: 1,
      import: info,
      get: getPayload,
    },
    nhapDanhMucKhoa,
    doGetListDmKhoa
  );
}