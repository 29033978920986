import { put, select, takeLatest } from "redux-saga/effects";
import { notification } from "antd";
import { HLog, getErrorMessage } from "../../../helpers";

import {
  fecthDetailServices,
  fecthDetailServicesFail,
  fecthDetailServicesSuccess,
  updateDetailServices,
} from "../../slices/slices/DetailServices.slice";
import {
  deleteDvXnFail,
  editDvConKemTheoSlice,
  fecthDVkemTheoByDetail,
  fecthDVkemTheoByDetailSuccess,
  lockFail,
  lockSuccess,
  updateDvConKemTheoAvailable,
  updateDvConKemTheoAvailableSuccess,
} from "../../slices/slices/ListOfServicesSlice";
import { lockDvXnApi, postLayDsChiSoKemTheoDv, suaChiSoXN } from "../apis";
import { postDetailDVXetNghiem } from "../../../pages/ClinicManagement/Main/Catalog/GroupCatalogs/ServiceCatalogries/Features/Modals/TestingComponents/apiTests";
import i18n, { languageKeys } from "../../../i18n";

export function* watchDetailServices() {
  yield takeLatest(fecthDetailServices, getDetailServices);
  yield takeLatest(updateDetailServices, handleChangeLock);
  yield takeLatest(updateDvConKemTheoAvailable, handleUpdateDvConKemTheoAvailable);
  yield takeLatest(fecthDVkemTheoByDetail, handleFecthDVkemTheoByDetail);
}

function* getDetailServices({ payload }) {
  const dataHos = yield select(
    (state) => state.hospitalInformation.hospitalInfo
  );
  // console.log(`payload`, payload);
  // return
  try {
    let res = yield postDetailDVXetNghiem({
      NHANSU_ID: dataHos.NHANSU_ID,
      partner_code: dataHos.partner_code,
      BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
      DV_ID: payload.DV_ID,
    });
    if (res.status == "OK") {
      yield put(fecthDetailServicesSuccess({ data: res.result }));
    } else {
      yield put(fecthDetailServicesFail());
    }
  } catch (error) {
    yield put(fecthDetailServicesFail());
    console.log(error);
  }
}

function* handleChangeLock({ payload }) {
  const dataHos = yield select(
    (state) => state.hospitalInformation.hospitalInfo
  );
  try {
    let res = yield lockDvXnApi({
      NHANSU_ID: dataHos.NHANSU_ID,
      partner_code: dataHos.partner_code,
      BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
      arr_ID: [payload.ID],
      KHOA: payload?.KHOA == 0 ? 1 : 0,
    });
    if (res.status == "OK") {
      notification.success({
        message: "Cập nhật thành công",
        placement: "bottomLeft",
      });

      yield put(
        lockSuccess({ ID: payload.ID, KHOA: payload?.KHOA == 0 ? 1 : 0 })
      );
    } else {
      let error_msg = getErrorMessage(res?.error_code, "Cập nhật thất bại");
      notification.error({
        message: error_msg,
        placement: "bottomLeft",
      });

      yield put(lockFail());
    }
  } catch (error) {
    console.log(error);
  }
}
function* handleFecthDVkemTheoByDetail({ payload }) {
  const dataHos = yield select(
    (state) => state.hospitalInformation.hospitalInfo
  );
  // console.log("DV_CHA_ID",payload.DV_CHA_ID);
  // return
  try {
    let res = yield postLayDsChiSoKemTheoDv({
      NHANSU_ID: dataHos?.NHANSU_ID || dataHos?.NHAN_VIEN_MAC_DINH_VIEN_ID,
      partner_code: dataHos.partner_code,
      BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
      DV_CHA_ID: payload.DV_CHA_ID,
    });

    if (res.status == "OK") {
      yield put(fecthDVkemTheoByDetailSuccess({ data: res.result }));
    } else {
      yield put(fecthDVkemTheoByDetailSuccess({ data: [] }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* handleUpdateDvConKemTheoAvailable({ payload }) {
  const dataHos = yield select(
    (state) => state.hospitalInformation.hospitalInfo
  );
  const payloadUpdate = {
    NHANSU_ID: dataHos.NHANSU_ID,
    partner_code: dataHos.partner_code,
    BENH_VIEN_ID: dataHos.BENH_VIEN_ID,
    ...payload,
    DV_ID: payload.MA_DICHVU_CS,
    ID: payload.MA_DICHVU_CS,
  };

  try {
    let res = yield suaChiSoXN(payloadUpdate);
    if (res.status == "OK") {
      const KHOA = res?.result?.[0]?.KHOA || 0;
      if (payloadUpdate?.KEY) {
        yield put(editDvConKemTheoSlice({ ...payloadUpdate, KHOA }));
        
      } else {
        yield put(updateDvConKemTheoAvailableSuccess({ ...payloadUpdate, KHOA, isCheck: true }));
        
      }
    } else {
      // yield put(deleteDvXnFail());
      notification.error({
        message: i18n.t(languageKeys.noti_Xoa_that_bai),
        placement: "bottomLeft",
      });
    }
  } catch (error) {
    console.log("error",error);
    notification.error({
      message: i18n.t(languageKeys.noti_Xoa_that_bai),
      placement: "bottomLeft",
    });
    yield put(deleteDvXnFail());
    
  }
}
