import { takeLatest, select } from "@redux-saga/core/effects";
import {
    handleCommonAdd,
    handleCommonDelete,
    handleCommonEdit,
    handleCommonExport,
    handleCommonGet,
    handleCommonImport,
    handleCommonLock
} from "../commonSaga/commonSaga.js";

import {
    postEditThuoc,
    postExportThuoc,
    postImportThuoc,
    postKhoaThuoc,
    postLayDsThuoc,
    postThemMoiThuoc,
    postXoaThuoc,
} from "./apiDuoc";
import { addNewDrug, deleteDrug, editDrug, getDataSource, getDataSourceSuccess, lockDrug, updateLockDrug, exportDrug, importDrug } from "../../slices/slices/DmThuocSlice.js"
 ;

export function* watchDoDmThuoc() {
    yield takeLatest(getDataSource, handleGetDataThuoc)
    yield takeLatest(addNewDrug,handleAddDrug)
    yield takeLatest(editDrug, handleEditDrug)
    yield takeLatest(deleteDrug, handleDeleteDrug)
    yield takeLatest(lockDrug, handleLockDrug)
    yield takeLatest(exportDrug, handleExportDrug)
    yield takeLatest(importDrug, handleImportDrug)
}

function* handleGetDataThuoc(action) {
  const dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonGet(
        {
            BENH_VIEN_ID:dataHos.BENH_VIEN_ID,
            partner_code:dataHos.partner_code,
            ...action.payload
        },
        postLayDsThuoc,
        getDataSourceSuccess,
    )
}


function* handleAddDrug(action) {
  const dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
    yield handleCommonAdd(
        {
           ...action.payload,
        },
        postThemMoiThuoc,
        getDataSource

    )
}

function* handleEditDrug(action) {
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonEdit(
        {
            BENH_VIEN_ID:dataHos.BENH_VIEN_ID,
            partner_code:dataHos.partner_code,
            ...action.payload
        },
        postEditThuoc,
        getDataSource

    )
}

function* handleLockDrug(action) {
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonLock(
        {
            BENH_VIEN_ID:dataHos.BENH_VIEN_ID,
            partner_code:dataHos.partner_code,
            ...action.payload
        },
        postKhoaThuoc,
        () => { },
        updateLockDrug

    )
}

function* handleDeleteDrug(action) {
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonDelete(
        {
            BENH_VIEN_ID:dataHos.BENH_VIEN_ID,
            partner_code:dataHos.partner_code,
            ...action.payload
        },
        postXoaThuoc,
        getDataSource
    )
}

function* handleExportDrug(action) {
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  const info = action.payload
    yield handleCommonExport(
        {
            BENH_VIEN_ID:dataHos.BENH_VIEN_ID,
            partner_code:dataHos.partner_code,
            template: 0,
        },
        postExportThuoc,
        info.name,
    )
}

function* handleImportDrug(action) {
  let dataHos = yield select((state) => state.hospitalInformation.hospitalInfo);
  yield handleCommonImport(
        {
            BENH_VIEN_ID:dataHos.BENH_VIEN_ID,
            partner_code:dataHos.partner_code,
            ...action.payload
        },
        postImportThuoc,
        getDataSource,
    )
}