import React from "react";
import { Spin } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";
import { LoginBg } from "../../../assets/images";
import { useKeycloak } from "@react-keycloak/web";
import { paths } from "../../../utils/constant";
import { useEventListener } from "../../../hooks/useEventListener";
import { LogoNew } from "../../../assets/images/icons";
import { handleRedirect } from "../../../helpers";

const Login = () => {
  const { keycloak } = useKeycloak();
  const keyCloakWindow = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const renderContact = () => {
    return {
      WEBSITE: <span><a className={styles['txt-style']} href="https://deepcare.io">Deepcare.io</a></span>,
      HOTLINE: "1900 068 856",
      EMAIL: "contact@deepcare.io",
    };
  };
  const handleClickLogin = () => {
    if (keyCloakWindow.current && !keyCloakWindow.current.closed) {
      keyCloakWindow.current.focus();
      return;
    }
    const WIDTH = 800;
    const HEIGHT = 600;

    const TOP = window.top.outerHeight / 2 + window.top.screenY - HEIGHT / 2;
    const LEFT = window.top.outerWidth / 2 + window.top.screenX - WIDTH / 2;

    const loginUrl = keycloak.createLoginUrl({
      redirectUri: window.location.origin + paths.authenticated,
    });

    let url = loginUrl;

    // const userName = searchParams.get("username");

    if (!!keycloak.authenticated) {
      const logoutUrl = keycloak.createLogoutUrl({
        redirectUri: loginUrl,
        // loginHint: typeof userName === "string" ? userName : "null",
      });

      //   localStorage.removeItem(keys.REDIRECT);

      url = logoutUrl;
    }

    keyCloakWindow.current = window.open(
      url,
      "_blank",
      `width=${WIDTH}, height=${HEIGHT}, top=${TOP}, left=${LEFT}`
    );
  };
  const isDeepcare = true;

  const handleMessage = (e) => {
    if (e.data === "LOGIN SUCCESS") {
      keyCloakWindow.current?.close();
      //   clearLocalData();
      keycloak.login();
    }
  };

  useEventListener("message", handleMessage, window, true);
  return (
    <Spin spinning={loading}>
      <div
        style={{
          backgroundImage: `url(${LoginBg})`,
          visibility: loading ? "hidden" : "visible",
        }}
        className={styles["container"]}
      >
        <div className={styles["main"]}>
          <div className={styles["inner"]}>
            <div className={styles["logo-wrapper"]}>
              <LogoNew onClick={() => handleRedirect("https://deepcare.io/")} />
            </div>

            <p>
              Giải pháp thông minh quản lý phòng khám, chuỗi phòng khám đa khoa
              hiệu quả, tiện dụng, mọi lúc, mọi nơi trên mọi nền tảng
            </p>

            <div className={styles["btn-wrapper"]} onClick={handleClickLogin}>
              <button>Đăng nhập</button>
              <div className={styles["arrow-wrapper"]}>
                <ArrowRightOutlined />
              </div>
            </div>
            {/* <Typography.Link onClick={onOpenModalPassword} style={{marginTop:12}} underline>Quên mật khẩu?</Typography.Link> */}
          </div>
        </div>

        <div className={styles["footer"]}>
          <div>Địa chỉ: Số 35 Lê Văn Thiêm, Phường Thanh Xuân Trung, Quận Thanh Xuân, TP Hà Nội</div>

          {/* <div>Tel: 0983 858 100</div> */}
          {isDeepcare ? (
            <>
              <div>Website: {renderContact().WEBSITE}</div>

              <div>Hotline: {renderContact().HOTLINE}</div>

              <div>Email: {renderContact().EMAIL}</div>
            </>
          ) : (
            <>
              <div>{renderContact().WEBSITE}</div>

              <div>{renderContact().HOTLINE}</div>

              <div>{renderContact().EMAIL}</div>
            </>
          )}
        </div>
        {/* <QuenMatKhau ref={forgotPassRef}/> */}
      </div>
    </Spin>
  );
};

export default Login;
