import React, { useEffect } from "react";
import { Navigate, Outlet, Redirect } from "react-router-dom";
import { paths } from "../utils/constant";
import RegisterClinic from "../pages/RegisterClinic";
import { PageNotFound } from "../components/NotFound/PageNotFound";
import Login from "../pages/ClinicManagement/Auth";
import AdminLayout from "../layouts/AminLayout";
import Clinic from "../pages/ClinicManagement/Main/Clinic";
import Account from "../pages/ClinicManagement/Main/Account";
import "../assets/scss/styles.scss";
// import { useKeycloak } from "@react-keycloak/web";
import { LoginSuccess } from "../pages/ClinicManagement/Auth/LoginSucess";
import { AuthGuard, PrivatedGuard } from "./Guards";
import {
  Catalog,
  CommonConfig,
  GeneralCatalogries,
  MedCatalogries,
  PartnerManagement,
  ServiceCatalogries,
  SettingClinic,
} from "../pages/ClinicManagement/Main";
import PrivatedInitClinic from "../pages/RegisterClinic/PrivateClinic";
import { PermissionDeny } from "../components";

// xử lý dữ liệu | tác vụ cho global app
const Root = () => {
  // const navigate = useNavigate();
  // const { keycloak } = useKeycloak();

  return <Outlet />;
};

export const routes = () => [
  // Register
  {
    paths: "*",
    element: <Root />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: "/",
        element: <Navigate to={paths.LOGIN} />,
      },
      {
        path: "/public-register",
        element: <AuthGuard element={<RegisterClinic />} />,
      },
      {
        path: paths.authenticated,
        element: <LoginSuccess />,
      },
      {
        path: paths.LOGIN,
        element: <AuthGuard element={<Login />} />,
      },
      {
        path: paths.HOME,
        element: <PrivatedGuard element={<AdminLayout />} />,
        children: [
          {
            path: "",
            element: <Navigate to={paths.CUSTOMER} />,
          },
          {
            path: paths.CUSTOMER,
            element: <Clinic />,
          },
          {
            path: "customer/:id",
            element: <SettingClinic />,
          },
          { path: paths.ACCOUNT, element: <Account /> },
          {
            path: "account/:id",
            element: <div>ACCOUNT_THEO_ID</div>,
          },
          {
            path: `${paths.CATALOG}/:id`,
            element: <Catalog />,
            children: [
              {
                path: paths.DM_CHUNG,
                element: <GeneralCatalogries />,
              },
              {
                path: paths.DM_DICHVU,
                element: <ServiceCatalogries />,
              },
              {
                path: paths.DM_THUOC,
                element: <MedCatalogries />,
              },
              {
                path: paths.CAU_HINH,
                element: <CommonConfig />,
              },
            ],
          },
          { path: paths.PARTNER, element: <PartnerManagement/> },
        ],
      },
      {
        path: `${paths.REGISTER_MANAGERMENT}/:id`,
        element: <PrivatedGuard element={<PrivatedInitClinic/>}/>,
      },
      {
        path: paths.DENY,
        element: <PermissionDeny />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
];
