import {createSlice} from "@reduxjs/toolkit";

const initState = {
    data: {},
    reload:"",
  DsPhongTh: [],
};

const dmPhongSlice = createSlice({
    name: "dmPhong",
    initialState: initState,
    reducers: {
        doGetListDmPhong: () => {console.log("doGetListDmPhong");},
        doCreateDmPhong: () => {console.log("doCreateDmPhong");},
        doUpdateDmPhong: () =>{console.log("doUpdateDmPhong");},
        doDeleteDmPhong: () => {console.log("doDeleteDmPhong");},
        exportDmPhong: () => {},
        importDmPhong: () => {},
        getDsPhongTh: () => {},
        doSetListDmPhong: (state,action) => {
            state.data = action.payload;
        },
        
        lockDmPhong: () => {},
        updatePhong: (state, action) => {
            const updateLock = state.data.result.findIndex((id) => id.ID == action.payload.id[0].toString());
            state.data.result[updateLock].KHOA = action.payload.isLock;
        },

        getDsPhongThSuccess: (state, action) => {
            state.DsPhongTh = action.payload.result
          },
    },
});

export const {getDsPhongTh,getDsPhongThSuccess,doGetListDmPhong, doCreateDmPhong, doUpdateDmPhong, doSetListDmPhong, lockDmPhong, updatePhong, doDeleteDmPhong, importDmPhong,exportDmPhong} = dmPhongSlice.actions
export const DataDmPhong = (state) => state.DM_Chung.DmPhong
export default dmPhongSlice.reducer;