import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  specicalList: [],
};

const registerInfoSlice = createSlice({
  name: 'infomation',
  initialState,

  reducers: {
    fetchDataSpecialList: (state, action) => {
        state.specicalList = action.payload;
    },

    resetData: (state,action) => {
      state.specicalList = [];
    }
  },
});
const { reducer } = registerInfoSlice;
export const { fetchDataSpecialList,resetData } = registerInfoSlice.actions;

export default reducer;
