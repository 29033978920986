import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

const initState = {
    reload:null,
    isLoading: false,
    formLoading: false,
    selectLoading: false,
    siderLoading: false
};

const dmMainSlice = createSlice({
    name: "dmMain",
    initialState: initState,
    reducers: {
        setReloadList: (state, action) => {
            state.reload= moment()
        },
        setLoadingTable: (state, action) => {
            state.isLoading= action.payload
        },
        setFormLoading: (state, action) => {
            state.formLoading= action.payload
        },


    },
});

export const {setReloadList,setLoadingTable,setFormLoading} = dmMainSlice.actions
export const DataDmMain = (state) => state.DM_Chung.DmMain
export default dmMainSlice.reducer;