import { put, takeLatest,select } from "redux-saga/effects";
import { handleGetCommissionList, handleGetNhomNguonKhach } from "../apis";
import { notification } from "antd";
import {
  fetchCommissionList,
  fetchCommissionListSuccess,
  fetchNhomDoiTuong,
  fetchNhomDoiTuongSuccess,
} from "../../slices/slices/commission.slice";

export function* watchCommission() {
  yield takeLatest(fetchNhomDoiTuong, getNhomNguonKhach);
  yield takeLatest(fetchCommissionList, getCommissionList);
}

function* getNhomNguonKhach() {
  try {
    let { partner_code,BENH_VIEN_ID } = yield select(state => state.hospitalInformation.hospitalInfo)
    const res = yield handleGetNhomNguonKhach({ KHOA: 0,partner_code,BENH_VIEN_ID });
    if (!!res && res.status === "OK") {
      yield put(fetchNhomDoiTuongSuccess(res.result));
    } else {
      notification.error({
        placement: "bottomLeft",
        message: 'Thao tác thất bại',
      });
    }
  } catch (error) {
    notification.error({
      placement: "bottomLeft",
      message: 'Thao tác thất bại',
    });
  }
}

function* getCommissionList({ payload }) {
  const { nhomNguonKhachID } = payload;
  try {
    let { partner_code,BENH_VIEN_ID } = yield select(state => state.hospitalInformation.hospitalInfo)
    const res = yield handleGetCommissionList({
        partner_code,
        BENH_VIEN_ID,
      DM_NHOM_NGUON_KHACH_ID: nhomNguonKhachID,
      KHOA: 0,
      limit: 10000,
    });
    if (!!res && res.status === "OK") {
      yield put(fetchCommissionListSuccess(res.result));
    } else {
      notification.error({
        placement: "bottomLeft",
        message: 'Thao tác thất bại',
      });
    }
  } catch (error) {
    console.log(error);
    notification.error({
      placement: "bottomLeft",
      message: 'Thao tác thất bại',
    });
  }
}
