import Apis from "../../../../../../../../../constants/apis";
import { common_post } from "../../../../../../../../../services/apiInstances";

export const postUpdateDVXetNghiem = (dataRequest) =>
  common_post(Apis.suaDVXetNghiem, dataRequest);
export const postDetailDVXetNghiem = (dataRequest) =>
  common_post(Apis.laychitietDMDV_XN, dataRequest);
export const luuChiSoKemTheo = (dataRequest) =>
  common_post(Apis.luu_chi_so_kem_theo, dataRequest);
  export const postDVXetNghiem = (dataRequest) => common_post(Apis.themMoiDVXetNghiem, dataRequest);
  export const postGetDmLoaiDvXN = (dataRequest) => common_post(Apis.layDMLoaiDv, dataRequest);
