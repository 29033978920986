import Apis from "./apis"
export const PAGE_SIZE_OPTIONS = ['10', '20', '30']
export const DEFAULT_SIZE = 10
export const keys = {
    access_token:"access_token",
    refresh_token:"refresh_token"
}

export {
    Apis
}