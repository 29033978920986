import { createSlice, nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import _ from "lodash";

const initialState = {
  dataList :{
    filter:{
      limit:20,
      page: 1,
      search_string:''
    },
    total:0,
    data:[],
    loading: false,
    isReload: false
  },
  dataDvConKemTheo:[],
  keyCheckDvConKemTheo:[],
  isEdit:false,
  isClosePopUp:'',
  dataDvConKemTheoOriginal:[],
  isSetRows: false

};

const ListOfServicesSlice = createSlice({
  name: "listOfServicesSlice",
  initialState,
  reducers: {
    fecthListOfServices: (state) => {
      state.dataList.loading = true 
    },

    fecthListOfServicesSuccess: (state, { payload }) => {
      state.dataList = {
        data: payload?.result || [],
        total: payload?.total || 0,
        filter:payload?.filter || {},
        loading: false
      }
      state.isClosePopUp= dayjs()
      state.dataList.loading = false 
    },

    addDvConKemTheoSlice: (state, { payload }) => {
      const nanoids = nanoid(8)
      // console.log(`{...payload,ID:nanoids,KEY:nanoids ,is_them_moi: 1,}`,{...payload,ID:nanoids,KEY:nanoids ,is_them_moi: 1,});
      let dataDvConKemTheoCurrent = state.dataDvConKemTheo
      if (dataDvConKemTheoCurrent && _.isArray(dataDvConKemTheoCurrent)) {
      state.dataDvConKemTheo = [...state.dataDvConKemTheo,{...payload,ID:nanoids,KEY:nanoids ,is_them_moi: 1,}]
      } else {
        state.dataDvConKemTheo = [{...payload,ID:nanoids,KEY:nanoids ,is_them_moi: 1}]
      }
      state.keyCheckDvConKemTheo.push(nanoids)
    },

    editDvConKemTheoSlice: (state, { payload }) => {
      // return
      state.dataDvConKemTheo = state.dataDvConKemTheo.map((obj)=>{
        if (obj.KEY === payload.KEY) {
          return ({...payload})
        }
        return obj
      })

      state.isEdit = false

    },

    renoveDvConKemTheoSlice: (state, { payload }) => {
      state.dataDvConKemTheo = state.dataDvConKemTheo.filter(obj => obj.ID !== payload);
      state.isClosePopUp= dayjs()
    },

    changeLockDvConKemTheoSlice: (state, { payload }) => {
      state.dataDvConKemTheo = state.dataDvConKemTheo.map(obj => {
        if (obj.ID === payload.ID) {
          return({...obj, KHOA: !obj.KHOA})
        } else {
          return obj
        }
      });

      state.isClosePopUp= dayjs()
    },

    setCheckDvConKemTheo: (state, { payload }) => {
      state.keyCheckDvConKemTheo = payload
    },

    resetDvConKemTheoSlice: (state) => {
      state.dataDvConKemTheo = []
    },

    resetKeyCheck: (state)=>{
      state.keyCheckDvConKemTheo=[]
    },

    setEdit: (state, {payload}) => {
      state.isEdit = payload
    },

    lockDvXn: () => {},
    lockSuccess: (state, { payload }) => {
      const updateLock = state.dataList.data.findIndex((id) => id.ID === payload.ID.toString());
      state.dataList.data[updateLock].KHOA = payload.KHOA;
      state.isClosePopUp= dayjs()
    },
    lockFail: (state) => {
      state.isClosePopUp= dayjs()
    },

    deleteDvXn: () => {},
    deleteDvXnSuccess: (state) => {
      state.isClosePopUp= dayjs()
      state.dataList.isReload = true 
    },
    deleteDvXnFail: (state) => {
      state.isClosePopUp= dayjs()
    },

    updateDvConKemTheoAvailable: () => {},
    updateDvConKemTheoAvailableSuccess: (state, { payload }) => {

      const nanoids = nanoid(8)
      const index = state.dataDvConKemTheo.findIndex(object => {
        return object.DV_ID === payload.DV_ID;
      })
      if (index == -1) {
        state.keyCheckDvConKemTheo.push(nanoids);
        state.dataDvConKemTheo.push({ ...payload, ID: nanoids, KEY: nanoids, is_them_moi: 0 });
      } else {
        state.dataDvConKemTheo[index] = { ...payload, ID: nanoids, KEY: nanoids, is_them_moi: 0 };
      }

      state.isEdit = false

    },
    updateDvConKemTheoAvailableFail: () => {},

    setDataDvConKemTheo:  (state, { payload }) => {
      state.dataDvConKemTheo = payload
    },

    fecthDVkemTheoByDetail: () => {
    },

    fecthDVkemTheoByDetailSuccess: (state, { payload }) => {
      const data =  payload.data.map(e=>{
        const nanoids = nanoid(8)
        return({...e, KEY: nanoids ,is_them_moi: 0,NAME_DVT: e.TEN_DVT, MA_DICHVU_CS: e.ID})
      })
      state.isSetRows = true
      state.dataDvConKemTheo = data
      state.dataDvConKemTheoOriginal = data
    },

    updateDetailServicesSuccess: (state) => {
      state.isSetRows = false 
      state.dataDvConKemTheo = null
      state.dataList.isReload = true 
    },

    setIsSetRows: (state, { payload }) => {
      state.isSetRows = payload 
    },
    exportDVXN: () =>{},
    importDVXN: () =>{}
    
  },
});

export const {
  exportDVXN,
  importDVXN,
  fecthListOfServices,
  fecthListOfServicesSuccess,
  addDvConKemTheoSlice,
  resetDvConKemTheoSlice,
  renoveDvConKemTheoSlice,
  changeLockDvConKemTheoSlice,
  setCheckDvConKemTheo,
  resetKeyCheck,
  setEdit,
  editDvConKemTheoSlice,
  lockDvXn,
  lockSuccess,
  lockFail,
  deleteDvXn,
  deleteDvXnSuccess,
  deleteDvXnFail,
  updateDvConKemTheoAvailable,
  updateDvConKemTheoAvailableSuccess,
  updateDvConKemTheoAvailableFail,
  setDataDvConKemTheo,
  fecthDVkemTheoByDetail,
  fecthDVkemTheoByDetailSuccess,
  updateDetailServicesSuccess,
  setIsSetRows
} = ListOfServicesSlice.actions;
export default ListOfServicesSlice.reducer;
