import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

const initState = {
    data: {},
    reload:""
};

const dmKhoaSlice = createSlice({
    name: "dmKhoa",
    initialState: initState,
    reducers: {
        doGetListDmKhoa: () => {console.log("doGetListDmKhoa");},
        doCreateDmKhoa: () => {console.log("doCreateDmKhoa");},
        doUpdateDmKhoa: () =>{console.log("doUpdateDmKhoa");},
        doDeleteDmKhoa: () => {console.log("doDeleteDmKhoa");},
        doExportDmKhoa : () => {},
        doImportDmKhoa : () => {},

        doSetListDmKhoa: (state,action) => {
            state.data = action.payload;
        },
        
        lockDmKhoa: () => {},
        updateKhoa: (state, action) => {
            const updateLock = state.data.result.findIndex((id) => id.ID == action.payload.id[0].toString() )
            state.data.result[updateLock].KHOA = action.payload.isLock
        },


    },
});

export const {doImportDmKhoa, doGetListDmKhoa, doCreateDmKhoa, doUpdateDmKhoa, doSetListDmKhoa, lockDmKhoa, updateKhoa, doDeleteDmKhoa, doExportDmKhoa} = dmKhoaSlice.actions
export const DataDmKhoa = (state) => state.DM_Chung.DmKhoa
export default dmKhoaSlice.reducer;