import React from "react";
import {
  GeneralCatalogries,
  ServiceCatalogries,
  MedCatalogries,
  CommonConfig,
} from "./Catalog/GroupCatalogs";
const Catalog = React.lazy(() => import("./Catalog"));
const SettingClinic = React.lazy(() => import("./SettingClinic/SettingClinic"));
const PartnerManagement = React.lazy(() => import("./Partner"));

export {
  Catalog,
  SettingClinic,
  GeneralCatalogries,
  ServiceCatalogries,
  MedCatalogries,
  CommonConfig,
  PartnerManagement
};
