import React from 'react';
import { Navigate } from 'react-router-dom';
import webStorage from '../../utils/webStorage';
import { paths } from '../../utils/constant';
import { useKeycloak } from '@react-keycloak/web';

const AuthGuard = ({ element }) => {
  const { keycloak } = useKeycloak();

  if(!keycloak.authenticated) {
    return element;
  } else {
    webStorage.setToken(keycloak.token);
    return <Navigate to={"/" + paths.HOME}/>
  }
};

export default AuthGuard;