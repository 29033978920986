import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { TreeSelect as AntTreeSelect, Button, Input } from "antd";
import _ from "lodash";
const { SHOW_ALL, SHOW_PARENT, SHOW_CHILD } = AntTreeSelect;

export const TreeSelect = ({
  dataSource = [],
  placeholder = "Chọn",
  checkedStrategy = SHOW_CHILD,
  treeCheckable = true,
  treeDefaultExpandAll = true,
  showSearch = true,
  allowClear = true,
  isParentUncheckable = true,
  onClear,
  addItemProps = {
    visible: false,
    onAddItem: () => {},
    textAddItem: "",
  },
  onSelect = () => {},
  onDeselect = () => {},
  readOnly = false,
  popupClassName,
  isNoChildren = false,
  onDropdownVisibleChange = () => {},
  disabled = false,
  isCheckAll = false,
  ...props
}) => {
  const visibleAddItem = _.isObject(addItemProps) && addItemProps.visible;

  const handleSelect = (value, node, isDeselect = false) => {
    if (isDeselect) onDeselect(value, node?.fullValue);
    else onSelect(value, node.fullValue);
  };

  const getDataSource = () => {
    if (!isParentUncheckable) return dataSource;

    const formatItemHasChildren = (item) => {
      let item_has_children = { ...item, selectable: false, checkable: isCheckAll };

      const children = item_has_children.children;

      item_has_children.children = children.map((child) => {
        if (!child.children) return child;
        return formatItemHasChildren(child);
      });

      return item_has_children;
    };

    return dataSource.map((item) => {
      if (!item.children) return item;
      return formatItemHasChildren(item);
    });
  };

  const dropdownRender = (originNode) => {
    return (
      <>
        {originNode}

        {visibleAddItem && (
          <div >
            <Button type="link" icon={<PlusOutlined />} onClick={addItemProps?.onAddItem}>
              {addItemProps.textAddItem || "Thêm mới"}
            </Button>
          </div>
        )}
      </>
    );
  };

  if (readOnly) {
    return <Input placeholder={placeholder} readOnly />;
  }

  return (

        <AntTreeSelect
          showArrow
          treeData={getDataSource()}
          treeCheckable={treeCheckable}
          treeDefaultExpandAll={treeDefaultExpandAll}
          placeholder={placeholder}
          showSearch={showSearch}
          allowClear={allowClear}
          dropdownRender={dropdownRender}
          onSelect={(value, node) => handleSelect(value, node)}
          onDeselect={(value, node) => handleSelect(value, node, true)}
          onClear={onClear}
        //   popupClassName={cx(
        //     isParentUncheckable &&
        //       css({
        //         ".ant-select-tree-indent": {
        //           display: "none",
        //         },
        //       }),
        //     isNoChildren &&
        //       css({
        //         padding: "5px 10px",
        //         ".ant-select-tree-switcher": {
        //           display: "none",
        //         },
        //       }),
        //     popupClassName,
        //   )}
          disabled={disabled}
        //   css={cssTreeSelect}
          onDropdownVisibleChange={onDropdownVisibleChange}
          {...props}
        />
      )
};

TreeSelect.checkedStrategy = { SHOW_ALL, SHOW_PARENT, SHOW_CHILD };
