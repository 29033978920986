import React,{ useMemo } from "react";
import { Input as AntdInput } from "antd";
import input from './input.module.scss';
import cn from 'classnames';

// import i18n, { languageKeys } from "../i18n";

let Input = ({ placeholder = "", style = {}, readOnly = false,className="", inputRef, disabledDefaultClass, ...props }) => {
  const formatPlaceholder = useMemo(() => {
    if (readOnly) return placeholder;
    else if (!placeholder) return 'Nhập';
    else return placeholder;
  }, [placeholder, readOnly]);

  return (
    <div className={!disabledDefaultClass && input['wrapper-common-input']}>
          <AntdInput
      ref={inputRef}
      placeholder={formatPlaceholder}
      readOnly={readOnly}
      autoComplete="new-password"
      className={cn(!disabledDefaultClass && input['common-input'],className)}
      bordered={0}
      {...props}
    />
    </div>
  );
};

const Password = ({ placeholder = "Nhập", ...props }) => (
  <AntdInput.Password placeholder={placeholder} autoComplete="new-password" {...props} />
);

Input.Password = Password;

export { Input };
