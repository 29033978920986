import React, { useState } from 'react';
import { Radio, Space } from 'antd';
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';

export const CommonRadio = ({ classNameOption=null,dataOptions = [], valueKey = '', titleKey = '', onSelect = () => {}, value, className = "" }) => {
  const onChange = (e) => {
    onSelect(e.target.value);
  };
  return (
    <Radio.Group onChange={onChange} value={value} className={`custom__radio ${className}`}>
      <Space direction="vertical">
        {dataOptions.map((option, index) => {
          return (
            <Radio
              className={classNames(classNameOption)}
              value={option[valueKey] ? option[valueKey] : option.value}
              key={option?.key ? option.key : `${index}${nanoid()}`}
              onClick={(e) => onChange(e)}
            >
              {option[titleKey] ? option[titleKey] : option.title}
            </Radio>
          );
        })}
      </Space>
    </Radio.Group>
  );
};
