import React from "react";
import style from "./styles/style.module.scss";
import { Form, Row, Col, Input } from "antd";
import { CommonSelect } from "../../../../../components";
// import { Input } from "../../../../../components";
import TINH from "../../../../../models/tinh.json";
import HUYEN from "../../../../../models/huyen.json";
import XA from "../../../../../models/xa.json";
import { nanoid } from "@reduxjs/toolkit";
import {
  hospitalLines,
  moHinhCSKCBoptions,
} from "../../../../../utils/constant";
import { TIEN_ICH_OPTIONS } from "../../../../../constants/data";

/**
 *
 * @param {{form : import("antd").FormInstance<any>}} param0
 * @returns
 */
const ClinicInfomations = ({ form }) => {
  const [dsQuan, setDsQuan] = React.useState([]);
  const [dsPhuong, setDsPhuong] = React.useState([]);
  const fieldDataList = [
    {
      key: nanoid(),
      label: (
        <>
          <span>Tên phòng khám</span>
          <span className="red-txt">*</span>
        </>
      ),
      span: 12,
      component: "input",
      name: fieldDataPhongKham.TEN_CSKCB,
      rule: {},
      required: true,
    },
    {
      key: nanoid(),
      label: (
        <>
          <span>Tên viết tắt</span>
          <span className="red-txt">*</span>
        </>
      ),
      span: 12,
      component: "input",
      name: fieldDataPhongKham.partner_code,
      rule: {},
      required: true,
      props: {
        readOnly: true,
      },
    },
    {
      key: nanoid(),
      label: (
        <>
          <span>Tỉnh/ Thành phố</span>
          <span className="red-txt">*</span>
        </>
      ),
      span: 8,
      component: "select",
      name: fieldDataPhongKham.TEN_TINH_THANH,
      rule: {},

      /**
       * @type {typeof CommonSelect}
       */
      props: {
        dataSource: TINH,
        labelKey: "TEN_TINH_THANH",
        valueKey: "TEN_TINH_THANH",
        onChange: () => {
          form.resetFields([
            fieldDataPhongKham.TEN_PHUONG_XA,
            fieldDataPhongKham.TEN_QUAN_HUYEN,
          ]);
        },
      },
      required: true,
    },
    {
      key: nanoid(),
      label: (
        <>
          <span>Quận/ Huyện</span>
          <span className="red-txt">*</span>
        </>
      ),
      span: 8,
      component: "select",
      name: fieldDataPhongKham.TEN_QUAN_HUYEN,
      rule: {},
      props: {
        dataSource: dsQuan,
        labelKey: "TEN_QUAN_HUYEN",
        valueKey: "TEN_QUAN_HUYEN",
        onChange: () => {
          form.resetFields([fieldDataPhongKham.TEN_PHUONG_XA]);
        },
      },
      required: true,
    },
    {
      key: nanoid(),
      label: (
        <>
          <span>Phường/ Xã</span>
          <span className="red-txt">*</span>
        </>
      ),
      span: 8,
      component: "select",
      name: fieldDataPhongKham.TEN_PHUONG_XA,
      rule: {},
      props: {
        dataSource: dsPhuong,
        labelKey: "TEN_PHUONG_XA",
        valueKey: "TEN_PHUONG_XA",
      },
      required: true,
    },
    {
      key: nanoid(),
      label: "Địa chỉ chi tiết",
      span: 24,
      component: "input",
      name: fieldDataPhongKham.DIA_CHI_CHI_TIET,
      rule: {},
      required: false,
    },
    {
      key: nanoid(),
      label: (
        <>
          <span>Số điện thoại</span>
        </>
      ),
      span: 8,
      component: "input",
      name: fieldDataPhongKham.SO_DIEN_THOAI,
      rule: {},
    },
    {
      key: nanoid(),
      label: "Email",
      span: 8,
      component: "input",
      name: fieldDataPhongKham.EMAIL,
      rule: {},
    },
    {
      key: nanoid(),
      label: "Website",
      span: 8,
      component: "input",
      name: fieldDataPhongKham.WEBSITE,
      rule: {},
    },
    {
      key: nanoid(),
      label: (
        <>
          <span>Loại hình CSKCB</span>
          <span className="red-txt">*</span>
        </>
      ),
      span: 8,
      component: "select",
      name: fieldDataPhongKham.MO_HINH_CSKCB,
      rule: {},
      required: true,
      props: {
        dataSource: moHinhCSKCBoptions,
        labelKey: "TEN",
        valueKey: "ID",
      },
    },
    {
      key: nanoid(),
      label: "Tiện ích",
      span: 8,
      component: "select",
      name: fieldDataPhongKham.TIEN_ICH,
      rule: {},
      props: {
        dataSource: TIEN_ICH_OPTIONS,
        labelKey: "name",
        valueKey: "key",
      },
    },
    {
      key: nanoid(),
      label: "Giám đốc CSKCB",
      span: 8,
      component: "input",
      name: fieldDataPhongKham.GIAM_DOC,
      rule: {},
    },
    {
      key: nanoid(),
      label: "Mã CSKCB",
      span: 8,
      component: "input",
      name: fieldDataPhongKham.MA_CSKCB,
      rule: {},
    },
    {
      key: nanoid(),
      label: "Tuyến",
      span: 8,
      component: "select",
      name: fieldDataPhongKham.TUYEN_BV_ID,
      props: { dataSource: hospitalLines, labelKey: "TEN", valueKey: "ID" },
      rule: {},
    },
    {
      key: nanoid(),
      label: "Đơn vị quản lý",
      span: 8,
      component: "input",
      name: fieldDataPhongKham.DON_VI_QL,
      rule: {},
    },
    {
      key: nanoid(),
      label: "Giới thiệu chung",
      span: 24,
      component: "input",
      name: fieldDataPhongKham.GHI_CHU,
      rule: {},
    },
  ];
  const tinhThanh = Form.useWatch([fieldDataPhongKham.TEN_TINH_THANH], form);
  const maTinhThanh = Form.useWatch([fieldDataPhongKham.MA_TINH_THANH], form);
  const quanHuyen = Form.useWatch([fieldDataPhongKham.TEN_QUAN_HUYEN], form);
  const maQuanHuyen = Form.useWatch([fieldDataPhongKham.MA_QUAN_HUYEN], form);
  const phuongXa = Form.useWatch([fieldDataPhongKham.TEN_PHUONG_XA], form);

  React.useEffect(() => {
    let fields = [
      {
        name: fieldDataPhongKham.MA_TINH_THANH,
        value: TINH?.find((c) => c.TEN_TINH_THANH === tinhThanh)?.MA_TINH_THANH,
      },
    ];

    form.setFields(fields);
  }, [tinhThanh]); //

  React.useEffect(() => {
    let dsHuyen = HUYEN?.[maTinhThanh];
    setDsQuan(dsHuyen);
    let fields = [
      {
        name: fieldDataPhongKham.MA_QUAN_HUYEN,
        value: dsHuyen?.find((c) => c?.TEN_QUAN_HUYEN === quanHuyen)
          ?.MA_QUAN_HUYEN,
      },
    ];
    form.setFields(fields);
  }, [quanHuyen, maTinhThanh]); //

  React.useEffect(() => {
    let dsPhuong = XA?.[maQuanHuyen];
    setDsPhuong(dsPhuong);
    form.setFieldValue(
      fieldDataPhongKham.MA_PHUONG_XA,
      dsPhuong?.find((c) => c?.TEN_PHUONG_XA === phuongXa)?.MA_PHUONG_XA
    );
  }, [phuongXa, maQuanHuyen]); //

  /**
   *
   * @param {string} type
   * @param {import("antd").SelectProps | any} props
   */
  const renderItem = (type, props) => {
    if (type === "input") {
      return <Input placeholder="Nhập" {...props} />;
    } else if (type === "select") {
      return <CommonSelect className={style["select-clinic"]} {...props} placeholder="Chọn" />;
    } else {
      return <></>;
    }
  };
  return (
    <>
      <div className={style["container"]}>
        <div className={style["form-container"]}>
        <div style={{ marginBottom:16 }} className={style["center-header"]}>
        <b>Khởi tạo thông tin phòng khám</b>
      </div>
          <Row gutter={[10, 10]}>
            {fieldDataList.map((field) => {
              return (
                <Col span={field.span} key={field.key}>
                  <Form.Item
                    name={field.name}
                    label={field.label}
                    rules={[
                      { required: field.required, message: "Vui lòng nhập" },
                    ]}
                    className={style["form__item--clinic__info"]}
                  >
                    {renderItem(field.component, field.props)}
                  </Form.Item>
                </Col>
              );
            })}
            <Form.Item
              hidden
              name={fieldDataPhongKham.MA_TINH_THANH}
              label={fieldDataPhongKham.MA_TINH_THANH}
              className={style["form__item--clinic__info"]}
            >
              <input />
            </Form.Item>
            <Form.Item
              hidden
              name={fieldDataPhongKham.MA_QUAN_HUYEN}
              label={fieldDataPhongKham.MA_QUAN_HUYEN}
              className={style["form__item--clinic__info"]}
            >
              <input />
            </Form.Item>
            <Form.Item
              hidden
              name={fieldDataPhongKham.MA_PHUONG_XA}
              label={fieldDataPhongKham.MA_PHUONG_XA}
              className={style["form__item--clinic__info"]}
            >
              <input />
            </Form.Item>
          </Row>
        </div>
      </div>
    </>
  );
};

export const fieldDataPhongKham = {
  TEN_CSKCB: "TEN_CSKCB",
  MA_CSKCB: "MA_CSKCB",
  TEN_PHUONG_XA: "TEN_PHUONG_XA",
  TEN_QUAN_HUYEN: "TEN_QUAN_HUYEN",
  TEN_TINH_THANH: "TEN_TINH_THANH",

  DIA_CHI_CHI_TIET: "DIA_CHI_CHI_TIET",
  EMAIL: "EMAIL",
  WEBSITE: "WEBSITE",
  MO_HINH_CSKCB: "MO_HINH_CSKCB",
  SO_DIEN_THOAI: "SO_DIEN_THOAI",
  partner_code: "partner_code",
  LOAI_HINH_CSKCB: "LOAI_HINH_CSKCB",
  TUYEN_BV_ID: "TUYEN_BV_ID",
  DON_VI_QL: "DON_VI_QL",
  GHI_CHU: "GHI_CHU",
  GIAM_DOC: "GIAM_DOC",

  MA_PHUONG_XA: "MA_PHUONG_XA",
  MA_QUAN_HUYEN: "MA_QUAN_HUYEN",
  MA_TINH_THANH: "MA_TINH_THANH",
  TIEN_ICH: "TIEN_ICH",
};

export default ClinicInfomations;
