import React from "react";
import logo_text from "../../assets/images/logo_text.png";
import devices from "../../assets/images/devices.png";
export const RegisterContentLeft = () => {
  return (
    <div className="register__left">
      <div style={{ textAlign: "center",marginTop:20 }}>
        <img src={logo_text} alt={""} className="register__left__logo" />
      </div>
      <h3 className="register__left__title">
        <div style={{ margin: "auto" }}>
          Chuyển đổi số <br /> toàn diện <br />
          phòng khám 4.0
        </div>
      </h3>
      <p className="register__left__content">
        Trong thời đại 4.0, việc áp dụng công nghệ để tối ưu quy trình làm việc
        là không thể thiếu,<br/> đặc biệt là với ngành y tế.{" "}
      </p>
      <img src={devices} alt={""} className="register__left__devices" />
    </div>
  );
};
