import { createSlice } from "@reduxjs/toolkit";

const initState = {
    data: {},
    reload:""
};

const dmNhomDvSlice = createSlice({
    name: "dmNhomDv",
    initialState: initState,
    reducers: {
        doGetListDmNhomDv: () => {console.log("doGetListDmNhomDv");},
        doCreateDmNhomDv: () => {console.log("doCreateDmNhomDv");},
        doUpdateDmNhomDv: () =>{console.log("doUpdateDmNhomDv");},
        doDeleteDmNhomDv: () => {console.log("doDeleteDmNhomDv");},
        exportNhomDV: () => {},
        doImportNhomDv: () => {},
        doSetListDmNhomDv: (state,action) => {
            state.data = action.payload;
        },
        
        lockDmNhomDv: () => {},
        updateDmNhomDv: (state, action) => {
            const updateLock = state.data.result.findIndex((id) => id.ID == action.payload.id[0].toString() )
            state.data.result[updateLock].KHOA = action.payload.isLock
        },


    },
});

export const {doImportNhomDv,doGetListDmNhomDv,exportNhomDV, doCreateDmNhomDv, doUpdateDmNhomDv, doSetListDmNhomDv, lockDmNhomDv, updateDmNhomDv, doDeleteDmNhomDv} = dmNhomDvSlice.actions
export const DataDmNhomDv = (state) => state.DM_Chung.DmNhomDv
export default dmNhomDvSlice.reducer;