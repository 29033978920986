import { Space, Switch as SwitchAntd } from "antd";
import React, { useEffect, useState } from "react";

const AsyncSwitch = ({
  initValue = false, // giá trị khởi tạo
  onToggle = async () => {}, // Hàm xử lý toggle (api update data mới)
  onSuccess = () => {}, // Hàm xử lý khi toggle thành công
  onFail = () => {}, // Hàm xử lý khi toggle thất bại
  showText = true,
  disabled = false,
  ...props
}) => {
  const [checked, setChecked] = useState(false); // giá trị bật/tắt
  const [loading, setLoading] = useState(false); // loading cho công tắc

  useEffect(() => {
    // HLog("Swich useEffect initValue: " + initValue);
    setChecked(initValue);
  }, [initValue]);

  // Hàm xử lý bật/tắt
  const handleToggle = async (val) => {
    try {
      setChecked((val) => !val);

      setLoading(true);

      const res = await onToggle(val);

      if (res && res.status === "OK") onSuccess();
      else {
        setChecked((val) => !val);
        onFail(res);
      }

      setLoading(false);
    } catch (error) {
      // HLog("Switch error", error);

      setLoading(false);
    }
  };

  return (
    <Space {...props}>
      <SwitchAntd checked={checked} onChange={handleToggle} loading={loading} disabled={disabled} {...props} />
    </Space>
  );
};

export default AsyncSwitch;
