import { createSlice } from '@reduxjs/toolkit';
import {stepProgressList } from '../../utils/constant';

/**
 *  state sử dụng tại các màn :
 *        + index Register
 *
 *
 *
 * action sử dụng tại :
 *      +
 *
 *
 *
 *
 */

export const initialState = {
  typeService: null, // dịch vụ khách hàng lựa chọn
  typeClinic: null, // loại phòng khám
  typeProcess: stepProgressList.START_PROGRESS, // Bước render khảo sát
  serviceList: [],
  clientInfo: {
    TEN: '',
    SO_DIEN_THOAI: '',
    EMAIL: '',
    MA_TINH_THANH: '',
    TEN_TINH_THANH: '',
    MA_QUAN_HUYEN: '',
    TEN_QUAN_HUYEN: '',
    MA_PHUONG_XA: '',
    TEN_PHUONG_XA: '',
    DIA_CHI_CHI_TIET: '',
  },

  serviceProvide: {
    specialist:[],
    subclinical:[],
    isSellingMed: 1,
  },
};

const register = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServiceListProvide: (state, action) => {
      if (Array.isArray(action.payload)) state.serviceList = action.payload;
      else console.log('Lỗi kĩ thuật, không phải array');
    },
    changeTypeService: (state, action) => {
      if (typeof action.payload !== 'string') {
        console.error('ĐÉO PHẢI STRING ĐÂU');
        return;
      }
      state.typeService = action.payload;
    },
    changeStep: (state, action) => {
      state.typeProcess = action.payload;
    },
    changeTypeClinic: (state, action) => {
      state.typeClinic = action.payload;
    },
    setClientInfo: (state, action) => {
      state.clientInfo = action.payload;
    },
    setServiceProvide: (state, action) => {
      state.serviceProvide = action.payload;
    },
    
  },
  extraReducers: (builder) => {},
});

const { reducer } = register;
export const { reset, setServiceListProvide, changeTypeService, changeStep, changeTypeClinic, setClientInfo,setSpecialList ,setServiceProvide } =
  register.actions;
export default reducer;
