import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Col,Modal, Row } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import style from './commonModal.module.scss';

// reload gọi tới ref close chạy tới hàm onVisible close truyền vào.

const CommonModal = (
  {
    onVisibleOpen = () => {},
    onVisibleClose = () => {},
    onSubmit = () => {},
    loadingBtn = false,
    title = 'Modal',
    width = '698px',
    isCreateNew = true,
    footerProps = {},
    okText,
    children,
    disabledStyle,
    ...props
  },
  ref
) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      visible:open,
      open: handleOpen,
      close: handleCancel,
    };
  });

  const handleOpen = () => {
    onVisibleOpen();
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    onVisibleClose(); // không được trùng với ref
  };

  const handleSubmit = async () => {
    onSubmit();
  };

  return (
    <Modal
    
      title={
        title &&
        <Row align="middle" className={style['modal-header']}>
          {isCreateNew && (
            <Col>
              <PlusCircleFilled className={style['modal-icon']} />
            </Col>
          )}
          <Col className={!disabledStyle ? style['modal-title'] : ''}>
            {isCreateNew ? `Thêm mới ${title.toLowerCase()}` : `Chỉnh sửa ${title.toLowerCase()}`}
          </Col>
        </Row>
      }
      open={open}
      width={width}
      centered
      onCancel={handleCancel}
      {...props}
      footer={
        <Row gutter={10} align="middle" justify="end" {...footerProps} >
          {/* {!!addOnActions && <Col>{addOnActions}</Col>} */}

          <Col>
            {/* =================== Nút Cancel =================== */}
            <Button type="primary" ghost onClick={handleCancel} className={style['button']}>
              Hủy
            </Button>
          </Col>

          <Col>
            {/* =================== Nút OK =================== */}
            <Button type="primary" onClick={handleSubmit} loading={loadingBtn} className={style['button']}>
              {<>{okText ? okText : isCreateNew ? 'Tạo mới' : 'Chỉnh sửa'}</>}
            </Button>
          </Col>
        </Row>
      }
    >
      <div className="container-modal">
        <div className={style['wrapper-modal-content']}>{children}</div>
      </div>
      <div className="modal-footer-custom"></div>
    </Modal>
  );
};

export default forwardRef(CommonModal);
