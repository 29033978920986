import React from 'react'
import {Result, Button} from "antd"
import { useLogout } from '../../hooks/useLogout';
import { paths } from '../../utils/constant';
import { useNavigate } from "react-router-dom"
const PermissionDeny = () => {
    const navigate = useNavigate()
  let { handleLogout } = useLogout();
  return (
    <Result
    status="403"
    title="403"
    subTitle="Xin lỗi, bạn không có thẩm quyền cho chức năng này."
    extra={<Button type="primary" onClick={() => {
        handleLogout();
    }}>Đăng xuất</Button>}
    />
  )
}

export default PermissionDeny