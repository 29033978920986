import { useRoutes } from 'react-router-dom';
import useLoading from '../hooks/useLoading';
import { routes } from './routes';

const Router = () => {
  useLoading();
  return useRoutes(routes());
};

export default Router;
