import { notification } from "antd";
import { put, takeLatest } from "redux-saga/effects";
import { checkResponse, } from "../../../helpers.js";
import { common_post } from "../../../services/apiInstances.js";
import Apis from "../../../constants/apis.js";
import { doUpdateHospital, setHospitalInfo } from "../../slices/hospitalInformation.slice.js";

export function* watchHospital() {
    yield takeLatest(doUpdateHospital, handleUpdateHospitalDetail);
  }

export function* handleUpdateHospitalDetail(action) {
    try {
        let {payload } = action;

        let rs = yield common_post(Apis.layChiTietKhachHang,payload);
        if(checkResponse(rs)) {
            yield put(setHospitalInfo(rs.result));
        } else {
            throw new Error(rs.message)
        }
    } catch (e) {
        notification.error({
            message:"Lấy danh sách thất bại",
            placement: "bottomLeft",
        })
    } finally {
    }
}
