import React, { useMemo } from "react";
import { Spin, Row, Col, Form } from "antd";
import { Input as CustomInput } from "../../../../../components";
import { formPattern } from "../../../../../utils/constant";
import { locdau } from "../../../../../helpers";
import style from "./styles/style.module.scss";
let Input = ({ ...props }) => {
  return <CustomInput className={style["input"]} {...props} />;
};
const ClinicAccount = ({ form, loading }) => {

  const partnerCode = (() => {
    try {
      const { partner_code } = form.getFieldsValue()
      return partner_code
    } catch (error) {
      return "";
    }
  })()

  return (
    <Spin spinning={loading}>
      <div className={style["center-header"]}>
        <b>Thông tin tài khoản</b>
      </div>{" "}
      <Row justify={"center"}>
        <Col flex={"60%"}>
          <Row gutter={10} style={{ marginTop: 20 }}>
          <Col span={24}>
              <Form.Item
                label={
                  <span>
                    Họ <span className="red-txt">*</span>
                  </span>
                }
                name={fieldClinicAccount.HO}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập họ của bạn",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <span>
                    Tên <span className="red-txt">*</span>
                  </span>
                }
                name={fieldClinicAccount.TEN}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên của bạn",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Form.Item
                label={
                  <span>
                    Số điện thoại <span className="red-txt">*</span>
                  </span>
                }
                name={fieldClinicAccount.SO_DIEN_THOAI}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số điện thoại",
                  },
                  {
                    pattern: formPattern.numberOnly,
                    message: "Vui lòng nhập đúng định dạng số điện thoại",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
              <Form.Item
                label={
                  <span>
                    Email <span className="red-txt">*</span>
                  </span>
                }
                name={fieldClinicAccount.EMAIL}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập email",
                  },
                  {
                    pattern: formPattern.email,
                    message: "Vui lòng nhập đúng định dạng email",
                  },
                ]}
              >
                <Input
                  onBlur={() => {
                    const EMAIL = form.getFieldValue(fieldClinicAccount.EMAIL);
                    // apiKiemtraEmail(EMAIL);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
              <Form.Item
                label={
                  <span>
                    Tên đăng nhập <span className="red-txt">*</span>
                  </span>
                }
                name={fieldClinicAccount.USERNAME}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: (rule, value) => {
                      if (typeof value === "string") {
                        let checkValue = locdau(value).replaceAll(" ", "");
                        if (checkValue === value.toLowerCase()) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `Tên đăng nhập không đúng định dạng, vui lòng viết liền, không dấu`
                          );
                        }
                      } else {
                        return Promise.reject(`Vui lòng nhập tên đăng nhập`);
                      }
                    },
                  },
                ]}
              >
                <Input
                prefix={<div style={{paddingRight:4}}>{partnerCode}</div>}
                  onBlur={() => {
                    const USERNAME = form.getFieldValue(
                      fieldClinicAccount.USERNAME
                    );
                    // apiKiemTraUsername(USERNAME);
                  }}
                />
              </Form.Item>
            </Col>

            {/* <Col span={24} style={{ marginTop: 10 }}>
          <Form.Item
            label="Mật khẩu"
            name={fieldClinicAccount.PASSWORD}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập mật khẩu đăng nhập",
              },
            ]}
          >
            <AntInput.Password
              className={style["input"]}
              placeholder="Mật khẩu gồm ký tự và chữ số"
            />
          </Form.Item>
        </Col>
        <Col span={24} style={{ marginTop: 10 }}>
          <Form.Item
            label="Xác nhận mật khẩu"
            name={"CONFIRM_PASSWORD"}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: (rule, value) => {
                  let pass = form.getFieldValue(fieldClinicAccount.PASSWORD);
                  if (value === "") {
                    return Promise.reject(`Vui lòng nhập xác nhận mật khẩu`);
                  } else if (value !== pass) {
                    return Promise.reject(`Xác nhận mật khẩu không đúng`);
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <AntInput.Password
              className={style["input"]}
              placeholder="Nhập lại mật khẩu"
            />
          </Form.Item>
        </Col> */}
            <div style={{ height: "50px", width: "100%" }} />
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export const fieldClinicAccount = {
  HO: "THONG_TIN_TAI_KHOAN.HO",
  TEN: "THONG_TIN_TAI_KHOAN.TEN",
  SO_DIEN_THOAI: "THONG_TIN_TAI_KHOAN.SO_DIEN_THOAI",
  EMAIL: "THONG_TIN_TAI_KHOAN.EMAIL",
  USERNAME: "THONG_TIN_TAI_KHOAN.USERNAME",
  // PASSWORD: "THONG_TIN_TAI_KHOAN.PASSWORD",
};

export default ClinicAccount;
