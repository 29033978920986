export const ENDPOINT = {
    // register
    layDSChuyenKhoa:"api/public/dangky/layDSChuyenKhoa",
    luuKhoiTaoPhongKham: "api/public/dangky/luuDangKyMoPhongKham",
    sendOTPEmail:"api/public/dangky/sendOTPEmail",
    verifyEmail:"api/public/dangky/verifyEmail",
    kiemTraPartnerCode:"api/public/dangky/kiemTraTonTaiPartnerCode",
    kiemtraEmail:"api/public/dangky/kiemTraTonTaiEmail",
    luuDangKyMoPhongKham:"api/public/dangky/luuDangKyMoPhongKham",
    layDsQuocGia: "api/public/diachihanhchinh/layDSQuocGia",
    layDSTinhThanh:"api/public/diachihanhchinh/layDSTinhThanh",
    layDSDichVuCLS:"api/public/dangky/layDSDichVuCLS",
    layDSQuanHuyen:"api/public/diachihanhchinh/layDSQuanHuyen",
    layDSPhuongXa:"api/public/diachihanhchinh/layDSPhuongXa",
    layDSDichVuCungCap:"api/public/dangky/layDSDichVuCungCap",
    kiemTraTonTaiUsername:"/api/public/dangky/kiemTraTonTaiUsername",
    layCauHinhBenhVien:"api/partner/public/quyen/layCauHinhBenhVien",
    

    // Web Kinh doanh
    layDsPhongKham : "api/public/benhvien/layDsBenhVien",
    layDsKhachHang : 'api/khachHang/layDsKhachHang',
    themKhachHang : "api/khachHang/themKhachHang",
    suaKhachHang : "api/khachHang/suaKhachHang",
    layDmDichVu:"api/partner/public/dichvu/layDMDichVu"
}