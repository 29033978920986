import { createSlice } from "@reduxjs/toolkit";

const initState = {
  user: undefined,
  loading: undefined,
  showSider:false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    doLogin: () => {},
    doLogout: () => {},
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    changeSider: (state,action) => {
      state.showSider = action.payload;
    }
  },
});

export const { doLogin, doLogout, updateUser, changeSider } = authSlice.actions;
export const getUser = (state) => state.auth.user

export default authSlice.reducer;
