import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Button, Checkbox, Col, Form, Radio, Row, Spin } from "antd";
import {
  Input as CustomInput,
  CommonRadio,
  CommonSelect,
  TreeSelect,
} from "../../../components";
import {
  chuyenKhoaBoXN,
  clinicType,
  dataDiagnose,
  dataXetNghiem,
  thamDoChucNang,
} from "../../../utils/constant";
import style from "./style.module.scss";
import { CloseCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { useDsDichVu } from "../hook";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { kiemTraPartnerCode } from "../../../services/register";
import { setUser } from "../../../duck/slices/registerClinic.slice";
const { Item, List } = Form;

export const fieldInitClinic = {
  TEN_PHONG_KHAM: "TEN_PHONG_KHAM",
  TEN_PK_VIET_TAT: "TEN_PK_VIET_TAT",
  LOAI_PK: "LOAI_PK",
  CHUYEN_KHOA: "CHUYEN_KHOA",
  XET_NGHIEM: "XET_NGHIEM",
  CHAN_DOAN: "CHAN_DOAN",
  THAM_DO_CHUC_NANG: "THAM_DO_CHUC_NANG",
  // KE_DON: 'KE_DON',
  KE_THUOC: "KE_THUOC",
  CO_TIEU_PHAU: "CO_TIEU_PHAU",
};

const Input = ({ ...props }) => {
  return <CustomInput className={style["input"]} {...props} />;
};

const clinicTypes = [
  {
    title: "Chuyên khoa",
    key: clinicType.CHUYEN_KHOA,
    value: clinicType.CHUYEN_KHOA,
  },
  {
    title: "Đa khoa",
    key: clinicType.DA_KHOA,
    value: clinicType.DA_KHOA,
  },
];

export const InitClinic = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { userInfo } = useSelector((state) => state.registerClinic);
  const { dataChuyenKhoa, loading: hookLoading } = useDsDichVu({});

  // const selectedSpecialist = Form.useWatch("CHUYEN_KHOA", form);

  const [typeSpecialist, setTypeSpecialist] = useState(clinicType.CHUYEN_KHOA);

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(),
  }));
  const handleSubmit = async () => {
    try {
      let errorField = await form.getFieldsError();
      let check = false;
      for (let i = 0; i < errorField.length; i++) {
        const error = errorField[i];
          if(!!error.errors.length) check = true;
      }

      if(check) return false;

      let values = await form.validateFields();
      let body = {
        ...userInfo,
        ...values,
        [fieldInitClinic.LOAI_PK]: typeSpecialist,
        [fieldInitClinic.CHAN_DOAN]: values[fieldInitClinic.CHAN_DOAN] || [],
        [fieldInitClinic.XET_NGHIEM]: values[fieldInitClinic.XET_NGHIEM] || [],
        [fieldInitClinic.THAM_DO_CHUC_NANG]: values[fieldInitClinic.THAM_DO_CHUC_NANG] || [],
      };
  
      dispatch(setUser(body));
      return true; // return status
    } catch (error) {
      console.log(error)
      
    }
  };

  useEffect(() => {
    let fieldData = [];
    if (userInfo) {

      Object.keys(fieldInitClinic).forEach((f) => {
        if (f === fieldInitClinic.CHUYEN_KHOA) {
          fieldData.push({
            name: f,
            value: userInfo[f]?.length ? userInfo[f] : [undefined],
          });
        } else if(f === fieldInitClinic.LOAI_PK){
          fieldData.push({
            name:f,
            value:userInfo[f]
          })
          setTypeSpecialist(userInfo[f]);
        }else {
          fieldData.push({
            name: f,
            value: userInfo[f],
          });
        }
      });
    }
    form.setFields(fieldData);
  }, [userInfo]);

  const formattedDsChuyenKhoa = useMemo(
    () =>{
      let data = [];
      dataChuyenKhoa.forEach((d,i) => {
          if(d?.LOAI_KHOA?.includes('DA_KHOA')) {
          data.push({
            title: d.TEN_CHUYEN_KHOA,
            value: d.MA_CHUYEN_KHOA,
            fullValue: d,
          });
        } else {
        }
      })
      return data
    },
    [dataChuyenKhoa]
  );

  // const formattedDsChuyenKhoa = useMemo(
  //   () =>
  //     dataChuyenKhoa.map((chuyenKhoa) => ({
  //       title: chuyenKhoa.TEN_CHUYEN_KHOA,
  //       value: chuyenKhoa.MA_CHUYEN_KHOA,
  //       fullValue: chuyenKhoa,
  //     })),
  //   [dataChuyenKhoa]
  // );

  const formatDataTypeChuyenKhoa = useMemo(() => {
    let data_khoa = [];
      dataChuyenKhoa.forEach((d,i) => {
        if(d?.LOAI_KHOA?.includes('CHUYEN_KHOA')) {
          data_khoa.push({
            title: d.TEN_CHUYEN_KHOA,
            value: d.MA_CHUYEN_KHOA,
            fullValue: d,
          });
        }
      })

      return data_khoa

  },[dataChuyenKhoa])

  const ruleValidate = ({ getFieldValue }) => ({
    validator(name, value) {
      let list = getFieldValue(fieldInitClinic.CHUYEN_KHOA);
      if (!value) return Promise.reject(new Error("Vui lòng chọn chuyên khoa"));
      let check = list.filter((i) => i === value);
      if (check.length > 1) {
        return Promise.reject(new Error("Khoa đã được chọn"));
      } else {
        return Promise.resolve();
      }
    },
  });

  // useEffect(() => {
  //   if (typeSpecialist === clinicType.CHUYEN_KHOA) {
  //       form.setFieldValue(fieldInitClinic.CHUYEN_KHOA, [undefined]);
  //   }
  // }, [typeSpecialist]);

  // const checkShowTestdiagnose = () => {
  //   let check = true;
  //   let arr_check_code = Object.values(chuyenKhoaBoXN);
  //   for (let i = 0; i < +selectedSpecialist?.length; i++) {
  //     const specialist_code = selectedSpecialist[i];
  //     if (arr_check_code.includes(specialist_code)) check = false;
  //     if (!arr_check_code.includes(specialist_code)) check = true;
  //   }

  //   if (selectedSpecialist && Array.isArray(selectedSpecialist)) {
  //     selectedSpecialist.forEach((s) => {
  //       if (!arr_check_code.includes(s)) check = true;
  //     });
  //   }

  //   return check;
  // };
  //api kiểm tra partner code đã tồn tại trong hệ thống hay chưa
  let apiKiemtraPartner = async (PARTNER_CODE = "") => {
    try {
      if (PARTNER_CODE === "") {
        return;
      }
      let body = { PARTNER_CODE };
      let res = await kiemTraPartnerCode(body);
      if (res && res.status === "OK") {
        form.setFields([
          {
            name: fieldInitClinic.TEN_PK_VIET_TAT,
            value: PARTNER_CODE,
            errors: null,
          },
        ]);
      }
    } catch (error) {
      console.log(error);
      form.setFields([
        {
          name: fieldInitClinic.TEN_PK_VIET_TAT,
          errors: [
            "Tên viết tắt đã tồn tại trong hệ thống, vui lòng nhập tên khác",
          ],
        },
      ]);
    }
  };


  return (
    <Spin spinning={hookLoading}>
      <div className={`${style["wrapper"]}`}>
        <Form
          layout="vertical"
          className={style["init-clinic"]}
          // initialValues={{
          //   [fieldInitClinic.CHUYEN_KHOA]: [undefined],
          // }}
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Item
                label="Tên phòng khám"
                name={fieldInitClinic.TEN_PHONG_KHAM}
                rules={[{
                  required:true,
                  message:"Vui lòng nhập tên phòng khám"
                }]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Tên viết tắt" name={fieldInitClinic.TEN_PK_VIET_TAT}
                rules={[
                  {
                    required:true,
                    message:"Vui lòng nhập tên viết tắt"
                  },
                  {
                    max:15,
                    message:"Tên viết tắt tối đa 15 ký tự",
                  }
                ]}
              >
                <Input
                  onBlur={() => {
                    const PARTNER_CODE = form.getFieldValue(
                      fieldInitClinic.TEN_PK_VIET_TAT
                    );
                    apiKiemtraPartner(PARTNER_CODE);
                  }}
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Loại phòng khám">
                <CommonRadio
                  dataOptions={clinicTypes}
                  className={style["radio-specialist"]}
                  value={typeSpecialist}
                  onSelect={(type) => {
                    form.setFields([
                    {
                      name:fieldInitClinic.CHUYEN_KHOA,
                      value:[],
                    }
                  ]); setTypeSpecialist(type)}}
                />
              </Item>
            </Col>
            {/* <Col span={24}>
              Bạn có thể khởi tạo nhiều hơn 1 chuyên khoa cho phòng khám của
              bạn. Hãy cho chúng tôi biết các chuyên khoa của bạn nhé!
            </Col> */}
                <span style={{marginLeft:12}}>
                   Hãy chọn loại dịch vụ phòng khám của bạn đang cung cấp
                </span>
            <Col
              span={24}
              className={classNames(
                style["wrapper-specialist"],
                // typeSpecialist === clinicType.DA_KHOA &&
                  style["wrapper-specialist--mode1"]
              )}
            >
              {/* {typeSpecialist === clinicType.CHUYEN_KHOA ? (
                <React.Fragment> 
                <List name={fieldInitClinic.CHUYEN_KHOA}>
                  {(fields, { add, remove }) => (
                    <Row gutter={[10, 10]}>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <React.Fragment key={key}>
                          <Col span={fields.length === 1 ? 24 : 22}>
                            <Item
                              name={[name]}
                              label="Dịch vụ cung cấp"
                              rules={[ruleValidate]}
                              >
                              <CommonSelect
                                placeholder="chọn"
                                className={style["common-select"]}
                                dataSource={formatDataTypeChuyenKhoa}
                                valueKey="MA_CHUYEN_KHOA"
                                labelKey="TEN_CHUYEN_KHOA"
                                // onClear={() => {remove(name)}}
                                // allowClear
                                />
                            </Item>
                          </Col>
                          <Col
                            span={fields.length === 1 ? 0 : 2}
                            onClick={() => remove(name)}
                            >
                            <div className={style["list-hide"]}>
                              <CloseCircleFilled
                                className={style["list-hide-icon"]}
                                />
                            </div>
                          </Col>
                        </React.Fragment>
                      ))}
                      <Col span={24}>
                        <Button
                          type="link"
                          icon={
                            <PlusCircleFilled
                              style={{ transform: "translateY(15%)" }}
                            />
                          }
                          onClick={() => add()}
                          >
                          Thêm dịch vụ
                        </Button>
                      </Col>
                    </Row>
                  )}
                </List>
                </React.Fragment>
               ) : ( */}
                <Item name={fieldInitClinic.CHUYEN_KHOA}>
                  <TreeSelect
                    className={style["tree-select"]}
                    style={{ width: 200 }}
                    mode="multiple"
                    placeholder="Tìm kiếm dịch vụ"
                    dataSource={typeSpecialist === clinicType.DA_KHOA ? formattedDsChuyenKhoa : formatDataTypeChuyenKhoa}
                    filterTreeNode={(input, option) =>
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    maxTagCount={2}
                    showArrow
                    isNoChildren
                  />
                </Item>
               {/* )} */}
            </Col>

            {/* {checkShowTestdiagnose() && ( */}
              <>
                <Col span={24}>
                  <span className={style["sub-title"]}>Thăm dò chức năng</span>
                </Col>
                <Col span={24} className={style["wrapp-list"]}>
                  <Item name={fieldInitClinic.THAM_DO_CHUC_NANG}>
                    <Checkbox.Group>
                      {thamDoChucNang.map((i, index) => {
                        return (
                          <div className={style["wrap-checkbox"]} key={i.value}>
                            <Checkbox value={i.value}>{i.title}</Checkbox>
                          </div>
                        );
                      })}
                    </Checkbox.Group>
                  </Item>
                </Col>
                <Col span={24}>
                  <span className={style["sub-title"]}>Xét nghiệm</span>
                </Col>
                <Col span={24} className={style["wrapp-list"]}>
                  <Item name={fieldInitClinic.XET_NGHIEM}>
                    <Checkbox.Group>
                      {dataXetNghiem.map((i) => (
                        <div className={style["wrap-checkbox"]} key={i.value}>
                          <Checkbox value={i.value}>{i.title}</Checkbox>
                        </div>
                      ))}
                    </Checkbox.Group>
                  </Item>
                </Col>
                <Col span={24}>
                  <span className={style["sub-title"]}>Chẩn đoán hình ảnh</span>
                </Col>
                <Col span={24} className={style["wrapp-list"]}>
                  <Item name={fieldInitClinic.CHAN_DOAN}>
                    <Checkbox.Group>
                      {dataDiagnose.map((i) => (
                        <div className={style["wrap-checkbox"]} key={i.value}>
                          <Checkbox value={i.value}>{i.title}</Checkbox>
                        </div>
                      ))}
                    </Checkbox.Group>
                  </Item>
                </Col>
              </>
            {/* )} */}

            <Col span={24}>
              <span className={style["sub-title"]}>
                Phòng khám của bạn có Thủ thuật, phẫu thuật không?{" "}
              </span>
              <Item
                name={fieldInitClinic.CO_TIEU_PHAU}
                initialValue={0}
              >
                <Radio.Group className={style["radio-wrapper"]}>
                  <Radio value={1}>Có</Radio>
                  <Radio value={0}>không</Radio>
                </Radio.Group>
              </Item>
            </Col>
            <Col span={24}>
              <span className={style["sub-title"]}>
                Phòng khám của bạn có bán thuốc không?
              </span>
              <Item name={fieldInitClinic.KE_THUOC} initialValue={1}>
                <Radio.Group className={style["radio-wrapper"]}>
                  <Radio value={1}>Có</Radio>
                  <Radio value={0}>không</Radio>
                </Radio.Group>
              </Item>
            </Col>
          </Row>
        </Form>
        <div style={{ height: 50 }} />
      </div>
    </Spin>
  );
});
const specialistFilterTesting = {
  CK_DINH_DUONG: "K45",
  CK_THAM_MY: "K52",
  CK_TAM_THAN: "K14",
  CK_TAI_MUI_HONG: "K28",
  CK_MAT: "K30",
  CK_RANG_HAM_MAT: "K29",
};
