import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Spin } from "antd";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { keys } from "../constants";
import keycloak from "../lib/Keycloak";
import jwtDecode from "jwt-decode";
import webStorage from "../utils/webStorage";
export const KeycloakProvider = ({ children }) => {
  // const dispatch = useDispatch();
 // config logout các module ở đây nhé các thầy
//   useEffect(() => {
//     const { searchParams } = new URL(window.location.href);
//     const action = searchParams.get(actionUrls.redirect)
//     if(action === actionUrls.logout) {
//         localStorage.setItem(keys.REDIRECT,action); 
//     }
//   }, []);
/**
 * @param {AuthClientTokens} tokens 
 */
  const handleTokens = (tokens) => {
    console.log(tokens);
    if (!!tokens.token) {
      localStorage.setItem(keys.access_token, tokens.token);
      localStorage.setItem(keys.refresh_token, tokens.refreshToken);
      webStorage.setToken(tokens.token)
      tokens.info = jwtDecode(tokens.token);
    }
  };

  const handleEvent = (e) => {
    console.log("event keycloak", e);
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<LoadingComponent />}
      onTokens={handleTokens}
      initOptions={{
        checkLoginIframe: false,
        // onLoad:'login-required'
      }}
      onEvent={handleEvent}
      autoRefreshToken={false}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

const LoadingComponent = () => (
  <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
    <Spin spinning />
    <h3 className="blue-txt">Hệ thống đang xác thực...</h3>
  </div>
);



/**
 * @name AuthClientTokens
 * @typedef {Object} AuthClientTokens
 * @property {string | undefined} idToken
 * @property {string | undefined} refreshToken
 * @property {string | undefined} token
 */